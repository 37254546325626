
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';

//<input {...getCVCProps()} />

 function PaymentInputs(props) {

  const ERROR_MESSAGES = {
  emptyCardNumber: 'El número de la tarjeta es inválido',
  invalidCardNumber: 'El número de la tarjeta es inválido',
  emptyExpiryDate: 'La fecha de expiración es inválida',
  monthOutOfRange: 'El mes de expiración debe estar entre 01 y 12',
  yearOutOfRange: 'El año de expiración no puede estar en el pasado',
  dateOutOfRange: 'La fecha de expiración no puede estar en el pasado',
  invalidExpiryDate: 'La fecha de expiración es inválida',
  emptyCVC: 'El código de seguridad es inválido',
  invalidCVC: 'El código de seguridad es inválido'
}

  const {
    wrapperProps,
    meta,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps
  } = usePaymentInputs({
    errorMessages: ERROR_MESSAGES
  });

  const { erroredInputs, touchedInputs } = meta;

// Test Credit Card: 4242 4242 4242 4242

 function viewCard(){
   console.log('wrapperProps:', wrapperProps);
   console.log('touchedInputs:', touchedInputs);
  if (wrapperProps.error==undefined && touchedInputs.cardNumber){
      console.log("Paymenst Credit Card Done");
      props.carddone();
  }
 }
 
  return (
        <Grid container >
        <PaymentInputsWrapper {...wrapperProps} >
      <svg {...getCardImageProps({ images })} />
      <input {...getCardNumberProps({onChange:viewCard()})} />
      <input {...getExpiryDateProps({onChange:viewCard()})} />
    </PaymentInputsWrapper>
        </Grid>
     
  );
}

export default PaymentInputs;