import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
//import PeopleIcon from '@mui/icons-material/People';
//<MenuItem onClick={set1adults1nino}>1 adulto y un nino  </MenuItem>

function DropdownAndDropup(props) {

  const [dropdown, setDropdown] = useState(null);
  const [people, setPeople] = useState('none');
  const [dropup, setDropup] = useState(null);

  const openDropdown = ({ currentTarget }) => {setDropdown(currentTarget);
                                              };
  const closeDropdown = () => {setDropdown(null);
                               };

  const set2adults = () => {
                            const value='2 adultos';
                            closeDropdown();
                            setPeople(value);
                            props.setpeople(value);
            };

  const set1adults = () => {
                            const value='1 adulto';
                            closeDropdown();
                            setPeople(value);
                            props.setpeople(value);
            };    
  const set3adults = () => {
                            const value='3 adultos';
                            closeDropdown();
                            setPeople(value);
                            props.setpeople(value);
            };   

  const set1adults1nino = () => {
                            const value='1 adulto y 1 nino';
                            closeDropdown();
                            setPeople(value);
                            props.setpeople(value);
            };  

  const set2adults1nino = () => {
                            const value='2 adulto y 1 nino';
                            closeDropdown();
                            setPeople(value);
                            props.setpeople(value);
            }; 

  const set2adults2nino = () => {
                            const value='2 adulto y 2 nino';
                            closeDropdown();
                            setPeople(value);
                            props.setpeople(value);
            };                               

  const openDropup = ({ currentTarget }) => setDropup(currentTarget);
  const closeDropup = () => setDropup(null);

  // Styles
  const iconStyles = {
    ml: 1,
    fontWeight: "bold",
    transition: "transform 200ms ease-in-out",
  };

  const dropdownIconStyles = {
    transform: dropdown ? "rotate(180deg)" : "rotate(0)",
    ...iconStyles,
  };

  const dropupIconStyles = {
    transform: dropup ? "rotate(180deg)" : "rotate(0)",
    ...iconStyles,
  };
  
  return (
    
        <Grid container >
          <Grid item xs={12} lg={12} textAlign="center">
            <MKButton variant='text' color="info" onClick={openDropdown}>
              {(people!='none')? (`${people}`):(`Seleccione huespedes`)} <Icon sx={dropdownIconStyles}>expand_more</Icon>
            </MKButton>
            <Menu anchorEl={dropdown} open={Boolean(dropdown)} onClose={closeDropdown}>
               <MenuItem onClick={set2adults}>2 adultos </MenuItem>
               <MenuItem onClick={set3adults}>3 adultos </MenuItem>
               <MenuItem onClick={set1adults}>1 adulto  </MenuItem>
               <MenuItem onClick={set2adults1nino}>2 adultos y un nino </MenuItem>
               <MenuItem onClick={set2adults2nino}>2 adultos y dos ninos </MenuItem>
            </Menu>
          </Grid>
        </Grid>
     
  );
}

export default DropdownAndDropup;