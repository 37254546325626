/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useState } from "react";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKDatePicker from "components/MKDatePicker";
import MKAlert from "components/MKAlert";
import MKInput from "components/MKInput";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/admin";
import Footer from "examples/Footers/CenteredFooter";
import BookingCardAdmin from "examples/Cards/BookingCardAdmin";


// Images
//import bgImage from "assets/images/shapes/waves-white.svg";
const bgImage= process.env.REACT_APP_PUBLIC_URL+"imgs/"+process.env.REACT_APP_HOTELSANCHO_HEADER;

const comparativeImage= process.env.REACT_APP_COMPARATIVE;


// DATABASE LIBS
const { convertDate, getMonth, getDay, getAvailability, getPrices, changeAvailabilityRoom} = require('../../../libs/availability')
const {findBookingBillAdmin, findBookingAdmin,  cancelBookingbyConfirmationNumber, saveBookingAdmin} = require('../../../libs/bookings')
const {  getAuth, handleAuthentication } = require('../../../libs/authentication');
const {  getImage } = require('../../../libs/imageCloudDownload');


// ICONS:
import SearchIcon from '@mui/icons-material/Search';
import BedIcon from '@mui/icons-material/Bed';
import PeopleIcon from '@mui/icons-material/People';
import DateRangeIcon from '@mui/icons-material/DateRange';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import EuroIcon from '@mui/icons-material/Euro';
import CheckIcon from '@mui/icons-material/Check';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import HomeIcon from '@mui/icons-material/Home';
import SaveIcon from '@mui/icons-material/Save';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import CircleIcon from '@mui/icons-material/Circle';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

//Scrool to different sections of the page 
  const PageRef0 = null;
  const PageRef1 = null;
  const PageRef2 = null;
  const PageRef3 = null;
  const PageRef4 = null;
  const PageRef5 = null;


function Comparative() {


 let [isAuthenticated, setIsAuthenticated]= useState(getAuth());
 let [searchingBooking, setSearchingBooking] = useState(false);
 let [menuSearchBooking, setMenuSearchBooking] = useState(true);
 let [showBooking, setShowBooking] = useState(false);
 let [errorConfirmationNumber, setErrorConfirmationNumber]= useState(false);
 let [cancelingBooking, setCancelingBooking] = useState(false);
 let [savingBooking, setSavingBooking] = useState(false);
 let [doneSavingBooking, setDoneSavingBooking] = useState(false);
 let [showAskCancelBooking, setShowAskCancelBooking] = useState(false);
 let [showBookingList,setShowBookingList]=useState(false);
 let [errorDates,setErrorDates]=useState(false);
 let [downloadImg,setDownloadImg]=useState(false);
 let [showImg,setShowImg]=useState(false);
 let [askImg,setAskImg]=useState(true);
 let [imgUrl,setImgUrl]=useState(true);


const fee=Number(process.env.REACT_APP_FEE);


const refreshPage = ()=>{
     window.location.reload();
}

  const reset = () => {
    setDownloadImg(false);
    setShowImg(false);
    setAskImg(false);
  }

  const doneGettingImg = (url) => {
    reset();
    setShowImg(true);
    setImgUrl(url);
    console.log("doneGettingImg: ", url);
  }

  const gettingImg = () => {
    setDownloadImg(true);
    setAskImg(false);
    getImage(doneGettingImg,'comparativeHotel');
  }

  var t=new Date();  
  var m=t.getMonth()+1;
  var today=t.getFullYear()+"-"+m+"-"+t.getDate();

  return (
    <>
      <DefaultNavbar 
        brand= "Best Hotels Cheap"
        routes={routes} 
        transparent={false}
        light={false}
        sticky={false} 
        relative={false} 
        center={false} 
        pageref0={PageRef0}
        pageref1={PageRef1}
        pageref2={PageRef2}
        pageref3={PageRef3}
        pageref4={PageRef4}
        pageref5={PageRef5}
        landingpage="admin"
        isauthenticated={getAuth()}
        setDone={handleAuthentication}
      />
      
      {(getAuth())&&<>
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.1),
              rgba(gradients.dark.state, 0.1)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>

        
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Comparativa Precios Hoteles
            </MKTypography>
             <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
                  
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>


      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

      {(downloadImg)&&
          <MKAlert justifyContent="center"  color="light" > <HourglassTopIcon variant="filled"  fontSize="medium"/>&nbsp; Por favor, espere a que se obtenga la comparative ...  </MKAlert>
      }


      {(askImg==true)&&
     <MKBox component="section" pt={3} pb={8}>
       <Container>
          <Grid container justifyContent="center" sx={{ textAlign: "center", pt:5}}>
             <Grid item xs={12} md={3} lg={3}  >
                <MKButton variant="gradient" color="info" onClick={gettingImg}>
                    <QueryStatsIcon/>&nbsp;
                     Comparar
                </MKButton>
            </Grid>         
          </Grid>
         </Container>
        </MKBox>
      }


    {(showImg==true)&&
     <MKBox component="section" pt={3} pb={8}>
       <Container>

          <Grid container justifyContent="center" sx={{ textAlign: "center", pt:5}}>
           <MKBox
              minHeight="110vh"
              width="100%"
                sx={{
                  backgroundImage: `url(${imgUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  display: "grid",
                  placeItems: "center",
                }}
          >
          </MKBox>
          </Grid>
         
         </Container>
        </MKBox>
    }




      </Card>
    </>}

      <MKBox pt={6} px={1} mt={6}>
         <Footer company={footerRoutes.company} links={footerRoutes.links} socials={footerRoutes.socials} light={footerRoutes.light}  />
      </MKBox>
    </>
  );
}

export default Comparative;
