/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useState } from "react";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKDatePicker from "components/MKDatePicker";
import MKAlert from "components/MKAlert";
import MKInput from "components/MKInput";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/main";
import Footer from "examples/Footers/CenteredFooter";
import BookingCard from "examples/Cards/BookingCards";

// CAPTCHA:


// Images
//import bgImage from "assets/images/shapes/waves-white.svg";
//import bgImage from "assets/hotelsancho/header.jpg";
//const bgImage= process.env.REACT_APP_PUBLIC_URL+"imgs/"+process.env.REACT_APP_HOTELSANCHO_HEADER;
const bgImage= process.env.REACT_APP_PUBLIC_URL+"imgs/"+process.env.REACT_APP_MAIN_BACKGROUND;

// DATABASE LIBS
const { getAvailability, getPrices, incrementAvailabilityRoom} = require('../../../libs/availability')
const { addBooking, findBookingbyConfirmationNumber, cancelBookingbyConfirmationNumber, calculateNdays } = require('../../../libs/bookings')

// ICONS:
import SearchIcon from '@mui/icons-material/Search';
import BedIcon from '@mui/icons-material/Bed';
import PeopleIcon from '@mui/icons-material/People';
import DateRangeIcon from '@mui/icons-material/DateRange';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import EuroIcon from '@mui/icons-material/Euro';
import CheckIcon from '@mui/icons-material/Check';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';


// Routes:
import routes from "routes";
import footerRoutes from "footer.routes";

//EMAIL:
import sendCancelation from "pages/LandingPages/Booking/sections/SendCancelation"
// TESTING EMAIL
//sendCancelation('jose','Sancho','jcsanchop@gmail.com','11-08-2022', '12-08-2022','Habitacion Doble', 'solo Habitacion', '345', '12312312321');


//Scrool to different sections of the page 
  const PageRef0 = null;
  const PageRef1 = null;
  const PageRef2 = null;
  const PageRef3 = null;
  const PageRef4 = null;
  const PageRef5 = null;



function ViewBooking() {

 let [serchingBooking, setSearchingBooking] = useState(false);
 let [menuSearchBooking, setMenuSearchBooking] = useState(true);
 let [showBooking, setShowBooking] = useState(false);
 let [errorConfirmationNumber, setErrorConfirmationNumber]= useState(false);
 let [cancelingBooking, setCancelingBooking] = useState(false);
 let [showAskCancelBooking, setShowAskCancelBooking] = useState(false);

 let [bookingRoom, setBookingRoom] = useState(null);
 let [bookingYear, setBookingYear] = useState(null);
 let [bookingRoomName, setBookingRoomName] = useState(null);
 let [bookingCost, setBookingCost] = useState(null);
 let [bookingMeal, setBookingMeal] = useState(null);
 let [bookingRestaurant, setBookingRestaurant] = useState(null);
 let [bookingNumber, setBookingNumber] = useState(null);
 let [bookingDate, setBookingDate] = useState(null);
 let [bookingEmail, setBookingEmail] = useState(null);
 let [bookingFirstName, setBookingFirstName] = useState(null);
 let [bookingLastName, setBookingLastName] = useState(null);
 let [bookingPhone, setBookingPhone] = useState(null);
 let [bookingCoupon, setBookingCoupon] = useState(null);
 let [bookingCouponSavings, setBookingCouponSavings] = useState(null);
 let [bookingCheckin, setBookingCheckin] = useState(null);
 let [bookingCheckout, setBookingCheckout] = useState(null);
 let [bookingName, setBookingName] = useState(null);
 let [bookingPeople, setBookingPeople] = useState(null);
 let [bookingRequests, setBookingRequests] = useState(null);
 let [bookingCanceled, setBookingCanceled] = useState(null);
 let [bookingDateCanceled, setBookingDateCanceled] = useState(null);
 let [bookingTransferOfiHotel, setBookingTransferOfiHotel] = useState(null);
 let [validEmail, setValidEmail] = useState(null);
 let [errorBooking, setErrorBooking] = useState(null);
 let [bookingCostPerDay, setBookingCostPerDay] = useState(null);


const doneSearchingBookingNumber = (booking) => {
                          let value;
                          console.log("Booking obtained: ", booking);
                          setBookingDate(booking.dateBooking);
                          setBookingYear(booking.bookingYear);
                          setBookingCheckin(booking.checkin);
                          setBookingCheckout(booking.checkout);
                          setBookingLastName(booking.lastName);
                          setBookingFirstName(booking.firstName);
                          value=booking.firstName + " " + booking.lastName;
                          setBookingName(value);
                          setBookingRoom(booking.room);
                          setBookingMeal(booking.regime);
                          setBookingPeople(booking.people);
                          setBookingRequests(booking.requests);
                          setBookingPhone(booking.phone);
                          setBookingEmail(booking.email);
                          setBookingCost(booking.cost);
                          setBookingCoupon(booking.coupon);
                          setBookingCouponSavings(booking.couponSavings);
                          setBookingCanceled(booking.canceled);
                          setBookingDateCanceled(booking.dateCanceled);
                          setBookingTransferOfiHotel(booking.transferOfiHotel);

                          setSearchingBooking(false);
                          setMenuSearchBooking(false);
                          setShowBooking(true);
                          if (booking.regime=='Media Pension'){
                                            setBookingRestaurant(process.env.REACT_APP_MSG_PENSION)
                                }else{
                                            setBookingRestaurant('')
                          }
};


const searchingBookingNumber = () => {
                          if (bookingNumber==null){
                              setErrorConfirmationNumber(true);
                          }else{
                              setBookingCanceled(false);
                              setSearchingBooking(true);
                              setErrorConfirmationNumber(false);
                              //const value='38296994';
                              console.log("Searching Confirmation Number:", bookingNumber);
                            //  findBookingbyConfirmationNumber(doneSearchingBookingNumber,bookingNumber);
                              findBookingbyConfirmationNumber(doneSearchingBookingNumber,bookingNumber);
                          }
};

const backViewBooking= () => {
                          setSearchingBooking(false);
                          setShowBooking(false);
                          setSearchingBooking(false);
                          setShowAskCancelBooking(false);
                          setMenuSearchBooking(true);
                          setBookingNumber('');
};

const cancelBooking= () => {
                          console.log("Cancel Booking  Number:", bookingNumber);
                          setSearchingBooking(false);
                          setShowBooking(false);
                          setSearchingBooking(false);
                          setShowAskCancelBooking(false);
                          setCancelingBooking(true);

                          cancelBookingbyConfirmationNumber(doneCancelingBooking, bookingNumber);
                          
};

const askingCancelBooking= () => {
                          console.log("Asking Canceling Booking  Number:", bookingNumber);
                          setSearchingBooking(false);
                          setShowBooking(false);
                          setShowAskCancelBooking(true);
};

const doneBookingAvail = () => {
                            console.log("Done change Availability ", );
                            
 };

const doneCancelingBooking= () => {
                          console.log("Done Cancel Booking Number:", bookingNumber);
                          setSearchingBooking(false);
                          setShowBooking(false);
                          setSearchingBooking(false);
                          setCancelingBooking(false);
                          setBookingCanceled(true);
                          setShowBooking(true);

                          if (process.env.REACT_APP_EMAIL_ENABLE=='true'){
                                    const bookingRestaurant ='';
                                    let subject=process.env.REACT_APP_EMAIL_CANCELATION_SUBJECT;
                                    sendCancelation (
                                                 subject,
                                                 bookingName,
                                                 bookingLastName,
                                                 bookingEmail,
                                                 checkin,
                                                 checkout,
                                                 bookingRoomName,
                                                 bookingMeal,
                                                 bookingCost,
                                                 bookingNumber,
                                                 bookingRestaurant
                                                );
                          }
                          incrementAvailabilityRoom(
                                      doneBookingAvail,
                                      checkin,
                                      checkout,
                                      bookingRoom,
                                      bookingMeal
                          );

};

/*
action={{
          type: "internal",
          route: "/booking/hotelsancho",
          label: "Reservar",
          color: "info",
        }}

*/

  return (
    <>
      <DefaultNavbar 
        brand= "Best Hotels Cheap"
        routes={routes} 
        transparent={true}
        light={true}
        sticky={false} 
        relative={false} 
        center={false} 
        pageref0={PageRef0}
        pageref1={PageRef1}
        pageref2={PageRef2}
        pageref3={PageRef3}
        pageref4={PageRef4}
        pageref5={PageRef5}
        landingpage="viewbooking"
        action=''
      />
    
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.1),
              rgba(gradients.dark.state, 0.1)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Acceda a sus Reservas 
            </MKTypography>

            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
                
            </MKTypography>
           
          </Grid>
        </Container>
      </MKBox>


      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

     <MKBox component="section" pt={3} pb={8}>
       <Container>

        {(cancelingBooking)&&
          <MKAlert justifyContent="center"  color="light" > <HourglassTopIcon/>&nbsp; Por favor, espere a que se cancele la reserva...  </MKAlert>
        }

        {(errorConfirmationNumber)&&
          <MKAlert justifyContent="center"  color="error" > <SentimentDissatisfiedIcon/>&nbsp; Por favor, introduzca un numero de confirmacion correcto  </MKAlert>
        }


        {(menuSearchBooking)&&
        <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={12} md={3} lg={3}>

           <MKInput type="text" label="Numero de Confirmacion" onChange={(e)=> setBookingNumber(e.target.value)} />
             
          </Grid>
           <Grid item xs={12} md={3} lg={3}>
             <MKButton variant="gradient" color="info" onClick={searchingBookingNumber}>
                    <SearchIcon/>&nbsp;
                    Buscar
            </MKButton>
          </Grid>
        </Grid>
       }

       {(showBooking)&&
        <MKBox component="section" pt={3} pb={8}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={3} lg={6} >
                <BookingCard
                  color={bookingCanceled?"light":"light"}
                  title={bookingCanceled?"Reserva Cancelada":"Reserva Confirmada"}
                  checkin={bookingCheckin}
                  checkout={bookingCheckout}
                  name={bookingName}
                  room={bookingRoom}
                  meal={bookingMeal+' '+ bookingRestaurant}
                  email={bookingEmail}
                  cost={bookingCost}
                  number={bookingNumber}
                  people={bookingPeople}
                  requests={bookingRequests}
                />
            </Grid>
          </Grid>

          <Grid container justifyContent="center" sx={{ textAlign: "center" }} pt={3} >
            
            {(bookingCanceled==false)&&
              <Grid item xs={12} md={3} lg={3}>
              </Grid>
            }
             <Grid item xs={12} md={3} lg={3}  >
                <MKButton variant="gradient" color="info" onClick={backViewBooking}>
                    <ArrowBackIcon/>&nbsp;
                    Volver
                </MKButton>
            </Grid>

              {(bookingCanceled==false)&&
                <Grid item xs={12} md={3} lg={3} >
                <MKButton variant="gradient" color="error" onClick={askingCancelBooking}>
                    <CancelIcon/>&nbsp;
                    Cancelar Reserva
                </MKButton>
              </Grid>
            }
             {(bookingCanceled==false)&& 
              <Grid item xs={12} md={3} lg={3}>
              </Grid>
            }
          </Grid>
        </MKBox>
       }


       {(showAskCancelBooking)&&
         <MKBox component="section" pt={3} pb={8}>
         <Card sx={{ mt: 6 , mb:6 }}  >
        <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={12} md={3} lg={3}>
            <MKTypography
              variant="h5"
              my={4}
              >
              Estas seguro que quiere cancelar la reserva? 
             </MKTypography>
          </Grid>
        </Grid>
        </Card>
        <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
            <Grid item xs={12} md={3} lg={3}>
            </Grid>
            <Grid item xs={12} md={3} lg={3}  >
                <MKButton variant="gradient" color="info" onClick={backViewBooking}>
                    <ArrowBackIcon/>&nbsp;
                    Volver
                </MKButton>
            </Grid>         

            <Grid item xs={12} md={3} lg={3}>
                <MKButton variant="gradient" color="error" onClick={cancelBooking}>
                    <CancelIcon/>&nbsp;
                    Cancelar Reserva
                </MKButton>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
            </Grid>
         </Grid>

        </MKBox>
       }
         
        
        </Container>
        </MKBox>
      </Card>



      <MKBox pt={6} px={1} mt={6}>
         <Footer company={footerRoutes.company} links={footerRoutes.links} socials={footerRoutes.socials} light={footerRoutes.light}  />
      </MKBox>
    </>
  );
}

export default ViewBooking;
