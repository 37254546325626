/* eslint-disable no-param-reassign */
/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Fragment, useState, useEffect } from "react";
import {useRef} from 'react';

const { LogInButton}  = require('../../../pages/LandingPages/Admin/components/LogIn')
const { LogOutButton} = require('../../../pages/LandingPages/Admin/components/LogOut')

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React example components
import DefaultNavbarDropdown from "examples/Navbars/admin/DefaultNavbarDropdown";
import DefaultNavbarMobile from "examples/Navbars/admin/DefaultNavbarMobile";

// Material Kit 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

//Images
const logoImage= process.env.REACT_APP_PUBLIC_URL+"imgs/"+process.env.REACT_APP_LOGO;

/* Name brand

 <MKBox
            component={Link}
            to="/"
            lineHeight={1}
            py={transparent ? 1.5 : 0.75}
            pl={relative || transparent ? 0 : { xs: 0, lg: 1 }}
             sx={{
                backgroundImage: `url(${logoImage})`,
                backgroundSize: "cover",
              }}
          >
*/

function DefaultNavbar({ brand, routes, transparent, light, action, sticky, relative, center, pageref0, pageref1, pageref2, pageref3, pageref4, pageref5, landingpage, isauthenticated, setDone}) {
  const [dropdown, setDropdown] = useState("");
  const [dropdownEl, setDropdownEl] = useState("");
  const [dropdownName, setDropdownName] = useState("");
  const [nestedDropdown, setNestedDropdown] = useState("");
  const [nestedDropdownEl, setNestedDropdownEl] = useState("");
  const [nestedDropdownName, setNestedDropdownName] = useState("");
  const [arrowRef, setArrowRef] = useState(null);

  //console.log("DefaultNavbar ", isauthenticated);

  const renderNavbarItems = routes.map(({ name, icon, href, route, pagerefindex,showpage}) => (
  
    <DefaultNavbarDropdown
      name={name}
      icon={icon}
      href={href}
      route={route}
      pageref={pagerefindex=="1"?pageref1:pagerefindex=="2"?pageref2:pagerefindex=="3"?pageref3:pagerefindex=="4"?pageref4:pagerefindex=="5"?pageref5:pageref0}
      show={(showpage==landingpage)?true:false}
      light={light}
    />

  ));

      // Render the dropdown menu that should be display as list items

  return (
    <Container sx={sticky ? { position: "sticky", top: 0, zIndex: 10 } : null}>
    
      <MKBox
        py={1}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 1 }}
        my={relative ? 0 : 2}
        mx={relative ? 0 : 3}
        width={relative ? "100%" : "calc(100% - 28px)"}
        borderRadius="xl"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        position={relative ? "relative" : "absolute"}
        left={0}
        zIndex={3}
        sx={({ palette: { transparent: transparentColor, white }, functions: { rgba } }) => ({
          backgroundColor: transparent ? transparentColor.main : rgba(white.main, 0.8),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >
        <MKBox display="flex" justifyContent="space-between" alignItems="center" >
          <MKBox
            component={Link}
            to="/"
            minHeight="10vh"
            width="10vh"
        sx={{
          backgroundImage: `url(${logoImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
          >

          {/*
            <MKTypography variant="button" fontWeight="bold" color={light ? "white" : "dark"}>
              {brand}
            </MKTypography>
          */}

          </MKBox>

          {(isauthenticated)&& 
          <MKBox
            color="inherit"
            display={{ xs: "none", lg: "flex" }}
            ml="auto"
            mr={center ? "auto" : 0}
          >
              {renderNavbarItems} 
          </MKBox>
          }

          <MKBox ml={{ xs: "auto", lg: 0 }}>
            
             <LogInButton   setDone={setDone} isAuthenticated={isauthenticated}/> 
             <LogOutButton  setDone={setDone} isAuthenticated={isauthenticated}/>
         
          </MKBox>
      </MKBox>
      </MKBox>
    </Container>
  );
}

// Setting default values for the props of DefaultNavbar
// HOME PAGE NAVBAR LEFT 
DefaultNavbar.defaultProps = {
  brand: "MetaBull World",
  routes: "",
  transparent: true,
  light: true,
  action: false,
  sticky: false,
  relative: false,
  center: false,
  pageref0:null,
  pageref1:  null,
  pageref2: null,
  pageref3: null,
  pageref4: null,
  pageref5: null,
  landingpage:""
};

// Typechecking props for the DefaultNavbar

DefaultNavbar.propTypes = {
  brand: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]),
      route: PropTypes.string,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
        "default",
        "white",
      ]),
      label: PropTypes.string,
    }),
  ]),
  sticky: PropTypes.bool,
  relative: PropTypes.bool,
  center: PropTypes.bool,
  pageref0: PropTypes.object,
  pageref1: PropTypes.object,
  pageref2: PropTypes.object,
  pageref3: PropTypes.object,
  pageref4: PropTypes.object,
  pageref5: PropTypes.object,
  landingpage: PropTypes.string,
  isauthenticated:PropTypes.bool
};

export default DefaultNavbar;
