/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/main";
import Footer from "examples/Footers/CenteredFooter";


// Images
//import bgImage from "assets/images/shapes/waves-white.svg";
const bgImage= process.env.REACT_APP_PUBLIC_URL+"imgs/"+process.env.REACT_APP_HOTELSANCHO_HEADER;



// Routes
import routes from "routes";
import footerRoutes from "footer.routes";


//Scrool to different sections of the page 
  const PageRef0 = null;
  const PageRef1 = null;
  const PageRef2 = null;
  const PageRef3 = null;
  const PageRef4 = null;
  const PageRef5 = null;

function Terms() {
  return (
    <>
      <DefaultNavbar 
        brand= "Best Hotels Cheap"
        routes={routes} 
        transparent={true}
        light={true}
        sticky={false} 
        relative={false} 
        center={false} 
        pageref0={PageRef0}
        pageref1={PageRef1}
        pageref2={PageRef2}
        pageref3={PageRef3}
        pageref4={PageRef4}
        pageref5={PageRef5}
        landingpage="terms"
        action={{
          type: "internal",
          route: "/viewbooking",
          label: "Ver Reservas",
          color: "info",
        }}
      />
    
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Terms of Use
            </MKTypography>
           
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
      <Container>
      <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
       <MKTypography
              variant="h4"
              color="black"
              verticalAlign="middle"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              About this agreement
        </MKTypography>
       </Grid>
       <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="left"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
       <MKTypography
              variant="h5"
              color="black"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
          <br></br>
          MetaBull World is an non-fungible token (NFT) collection of 8,888 pieces on the Ethereum network. 
          It combines the state of the blockchain and decentralized finance technology into one experience. 
          Before you use the website, however, you will need to review these Terms of Use and any 
          terms and conditions incorporated herein by reference (collectively, these “Terms”). 
          Please also review the MetaBull World.
          <br></br>
          <br></br>
          PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE WEBSITE. 
          THESE TERMS GOVERN YOUR USE OF THE WEBSITE, 
          AND ANY PURCHASES YOU MAKE, UNLESS WE HAVE EXECUTED A SEPARATE WRITTEN 
          AGREEMENT WITH YOU FOR THAT PURPOSE. WE ARE ONLY WILLING TO MAKE THE 
          WEBSITE AVAILABLE TO YOU IF YOU ACCEPT ALL OF THESE TERMS. 
          BY USING THE WEBSITE, OR ANY PART OF IT, YOU ARE CONFIRMING YOU 
          UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE TERMS. 
          IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, 
          YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO ACCEPT THESE TERMS 
          ON THAT ENTITY’S BEHALF, IN WHICH CASE “YOU” WILL MEAN THAT ENTITY. 
          IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT ACCEPT ALL OF THESE TERMS, 
          THEN WE ARE UNWILLING TO MAKE THE WEBSITE AVAILABLE TO YOU. 
          IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR USE THE WEBSITE. 
          PLEASE NOTE THAT THIS AGREEMENT IS SUBJECT TO CHANGE BY US 
          IN OUR SOLE DISCRETION AT ANY TIME.
          <br></br>
          <br></br>
          If you elect to purchase, trade, or interact with the nfts on the website or with or from other platforms, 
          ny financial transactions you engage in will be conducted solely through the MetaMask wallet. 
          We will have no insight into or control over these payments or transactions, 
          nor do we have the ability to reverse any transactions. 
          You agree we have no liability to you or to any third party for any claims or 
          damages that may arise as a result of any transactions that you engage in via 
          the website or any other transactions that you conduct via the Ethereum or 
          other blockchain networks.
          <br></br>
          <br></br>
          Use of this website is at the user’s own risk and MetaBull World is not liable for 
          any incorrect or failed transactions.

        </MKTypography>
         </Grid>
        </Container>

      </Card>
      <MKBox pt={6} px={1} mt={6}>
         <Footer company={footerRoutes.company} links={footerRoutes.links} socials={footerRoutes.socials} light={footerRoutes.light}  />
      </MKBox>
    </>
  );
}

export default Terms;
