// Show Input to enter password, email
// Show Error if password does not match
// Show Welcome Message once is signup sucessfully

import { useAuth0 } from '@auth0/auth0-react';
import { useState } from "react";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKDatePicker from "components/MKDatePicker";
import MKAlert from "components/MKAlert";
import MKInput from "components/MKInput";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// ICONS:
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
const { checkCredentials, setAuth, getAuth, handleAuthentication,  getAuthError, getAuthLogin } = require('../../../../libs/authentication');


export const SignIn= (props) => {

	//console.log("SignIn ", props);

	let [email, setEmail] = useState(null);
	let [password, setPassword] = useState(null);
	

	const handleResult = async (passed) => {
       // console.log('handleResult ...' );
        if (!passed){
        	props.setDone('error');
        }else{
        	props.setDone('valid');
        	 
        }

	}
	
	const handleClick = async () => {
       // console.log('handleClick ...' );
        checkCredentials(email, password, handleResult);
       
	}

	return (
		<>
		{(getAuth()==false && getAuthLogin()==true)&& <>

		  {(getAuthError())&&
              <MKAlert justifyContent="center"  color="error" > <SentimentDissatisfiedIcon/>&nbsp; Lo sentimos, email o password incorrectos </MKAlert>
      	  }

					  <Card
					        sx={{
					          p: 2,
					          mx: { xs: 2, lg: 1 },
					          mt: 2,
					          mb: 4,
					          boxShadow: ({ boxShadows: { xxl } }) => xxl,
					        }}
					      >

					     <MKBox component="section" pt={3} pb={8}>
					         <Container>
					         
					            <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
					            <Grid item xs={12} md={3} lg={3}>
					                 
					               <MKTypography variant="h5" my={4} >
					                 Sign In
					               </MKTypography>

					               	<MKInput sx={{mt: 1 }} type="text" label="Email" autocomplete="on" onChange={(e)=> setEmail(e.target.value)} />
					                <MKInput type="password" sx={{mt: 2 }} type="text" autocomplete="on" label="Password" onChange={(e)=> setPassword(e.target.value)} />
					            	 <MKButton sx={{mt: 2 }} variant="gradient" color="info" onClick={handleClick}>
                    					<LoginIcon variant="filled"  fontSize="medium" />&nbsp;
                    							Entrar
           							 </MKButton> 

					          </Grid>
					          </Grid>
					        
					        </Container>
					        </MKBox>
					      </Card>
			</>}

			{(getAuth()==true && props.welcome==true)&& <>

			 <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
              <Grid item xs={12} md={3} lg={3}>
                  <MKTypography
                variant="h2"
                my={4}
                >
                   Welcome! 
               </MKTypography>
                <MKTypography
                variant="h2"
                my={4}
                >
                  Hotel Sancho III  
               </MKTypography>
            </Grid>
            </Grid>

		    </>}

			</>	
		)
}
