// @mui icons
// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import Icon from "@mui/material/Icon";

const date = new Date().getFullYear();

const twitter=process.env.REACT_APP_TWITTER;


export default {
  company: {
    name: "Created by Best Hotels Cheap",
    href: process.env.REACT_APP_PUBLIC_URL,
  },
  links: [
        { href: "/terms", name: "Terms of Use"  },
  ],
  socials: [
           {icon: <TwitterIcon fontSize="small" />,link: twitter},
  ],
  light:false,
};
