/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";

import { useState } from "react";

// Material Kit 2 React components
import MKInput from "components/MKInput";

function MKDatePicker(props) {

 var t=new Date();  
 var m=t.getMonth()+1;
 var today=t.getFullYear()+"-"+m+"-"+t.getDate();
  
  
  const [date, setDate] = useState(null);
  

  const setFecha = (value) => {

                             var m=value[0].getMonth()+1;
                             var checkin=value[0].getFullYear()+"-"+m+"-"+value[0].getDate()
                             m=value[1].getMonth()+1;
                             var checkout=value[1].getFullYear()+"-"+m+"-"+value[1].getDate()
                             //console.log("func checkin: ", checkin);
                            // console.log("func checkout: ", checkout);
                             props.setcheckin(checkin);
                             props.setcheckout(checkout);
            };

  return (
    <Flatpickr
        options={{
              minDate: props.mindate,
              mode:"range",
              maxDate: props.maxdate,
              altInput: "true",
              altFormat: "F j, Y",
              value:date,
              "locale": {
              "firstDayOfWeek": 1, // start week on Monday
                weekdays: {
                shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],         
                }, 
                months: {
                  shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
                  longhand: ['Enero', 'Febreo', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                }
              }
            }}
              onClose={(date)=> setFecha(date)}
              render={({ defaultValue }, ref) => (
        <MKInput placeholder="Seleccione fechas" defaultValue={defaultValue} inputRef={ref} />
      )}
    />
  );
}

// Setting default values for the props of MKDatePicker

export default MKDatePicker;
