/* 
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useState } from "react";


// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/main";
import Footer from "examples/Footers/CenteredFooter";
import MKInput from "components/MKInput";
import RoomCard from "examples/Cards/RoomCards";


import DropdownAndDropup from "pages/LandingPages/Booking/sections/Drop";
import PromotionCode from "pages/LandingPages/Booking/sections/PromotionCode";
import MKDatePicker from "components/MKDatePicker";
import MKAlert from "components/MKAlert";
import PaymentInputs from "pages/LandingPages/Booking/sections/Payments";

// ICONS:
import SearchIcon from '@mui/icons-material/Search';
import BedIcon from '@mui/icons-material/Bed';
import PeopleIcon from '@mui/icons-material/People';
import DateRangeIcon from '@mui/icons-material/DateRange';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import EuroIcon from '@mui/icons-material/Euro';
import CheckIcon from '@mui/icons-material/Check';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// DATABASE LIBS
const { getRestaurantAvailability, getAvailability, getPrices, decrementAvailabilityRoom, convertDate} = require('../../../libs/availability')
const { addBooking } = require('../../../libs/bookings')

var randomString = require('random-string');


// EMAIL
import sendConfirmation from "pages/LandingPages/Booking/sections/SendConfirmation"

// Images
const bgImage= process.env.REACT_APP_PUBLIC_URL+"imgs/"+process.env.REACT_APP_HOTELSANCHO_HEADER;
const singleRoomImage= process.env.REACT_APP_PUBLIC_URL+"imgs/"+process.env.REACT_APP_HOTELSANCHO_HAB_SINGLE;
const doubleRoomImage= process.env.REACT_APP_PUBLIC_URL+"imgs/"+process.env.REACT_APP_HOTELSANCHO_HAB_DOUBLE;
const tripleRoomImage= process.env.REACT_APP_PUBLIC_URL+"imgs/"+process.env.REACT_APP_HOTELSANCHO_HAB_TRIPLE;
const quadrupleRoomImage= process.env.REACT_APP_PUBLIC_URL+"imgs/"+process.env.REACT_APP_HOTELSANCHO_HAB_QUADRUPLE;

//Scrool to different sections of the page 
  const PageRef0 = null;
  const PageRef1 = null;
  const PageRef2 = null;
  const PageRef3 = null;
  const PageRef4 = null;
  const PageRef5 = null;

// defaultValue={today}

  var t=new Date();  
  var m=t.getMonth()+1;
  var today=t.getFullYear()+"-"+m+"-"+t.getDate();


function Booking() {

 let [searching, setSearching] = useState(false);
 let [doneSearching, setDoneSearching] = useState(false); // Change to True to debug
 let [makeBooking, setMakeBooking] = useState(false); // Change to True to debug
 let [confirmedBooking, setConfirmedBooking] = useState(false); // Change to True to debug
 

 let [people, setPeople] = useState(null);
 let [ndays, setNdays] = useState(0);
 let [checkin, setCheckin] = useState(null);
 let [checkout, setCheckout] = useState(null);
 let [code, setCode] = useState(0);
 let [codeDiscount, setCodeDiscount] = useState(0);
 let [codevalid, setCodevalid] = useState(false);
 let [errorDates,setErrorDates]=useState(false);

 
 let [bookingRoom, setBookingRoom] = useState(null);
 let [bookingRoomName, setBookingRoomName] = useState(null);
 let [bookingCost, setBookingCost] = useState(null);
 let [bookingMeal, setBookingMeal] = useState(null);
 let [bookingNumber, setBookingNumber] = useState(null);
 let [bookingDate, setBookingDate] = useState(null);
 let [bookingEmail, setBookingEmail] = useState(null);
 let [bookingName, setBookingName] = useState(null);
 let [bookingLastName, setBookingLastName] = useState(null);
 let [bookingPhone, setBookingPhone] = useState(null);
 let [bookingRequests, setBookingRequests] = useState(null);
 let [validEmail, setValidEmail] = useState(null);
 let [validCard, setValidCard] = useState(false);
 let [errorBooking, setErrorBooking] = useState(null);
 let [confirmingBooking, setConfirmingBooking] = useState(false);
 let [confirmingBookingAdd, setConfirmingBookingAdd] = useState(false);
 let [confirmingBookingAvail, setConfirmingBookingAvail] = useState(false);

 let [noAvail, setNoAvail] = useState(false); // No Rooms available 

 let [singleOneBedShow,setSingleOneBedShow]=useState(false);
 let [singleOneBed,setSingleOneBed]=useState(0);
 let [singleOneBedBreakfast,setSingleOneBedBreakfast]=useState(0);
 let [singleOneBedHalfboard,setSingleOneBedHalfboard]=useState(0);
 let [singleOneBedFullboard,setSingleOneBedFullboard]=useState(0);

 let [singleTwoBedShow,setSingleTwoBedShow]=useState(false);
 let [singleTwoBed,setSingleTwoBed]=useState(0);
 let [singleTwoBedBreakfast,setSingleTwoBedBreakfast]=useState(0);
 let [singleTwoBedHalfboard,setSingleTwoBedHalfboard]=useState(0);
 let [singleTwoBedFullboard,setSingleTwoBedFullboard]=useState(0);

 let [doubleOneBedShow,setDoubleOneBedShow]=useState(false);
 let [doubleOneBed,setDoubleOneBed]=useState(0);
 let [doubleOneBedBreakfast,setDoubleOneBedBreakfast]=useState(0);
 let [doubleOneBedHalfboard,setDoubleOneBedHalfboard]=useState(0);
 let [doubleOneBedFullboard,setDoubleOneBedFullboard]=useState(0);

 let [doubleTwoBedShow,setDoubleTwoBedShow]=useState(false);
 let [doubleTwoBed,setDoubleTwoBed]=useState(0);
 let [doubleTwoBedBreakfast,setDoubleTwoBedBreakfast]=useState(0);
 let [doubleTwoBedHalfboard,setDoubleTwoBedHalfboard]=useState(0);
 let [doubleTwoBedFullboard,setDoubleTwoBedFullboard]=useState(0);

 let [tripleChildrenShow,setTripleChildrenShow]=useState(false);
 let [tripleChildren,setTripleChildren]=useState(0);
 let [tripleChildrenBreakfast,setTripleChildrenBreakfast]=useState(0);
 let [tripleChildrenHalfboard,setTripleChildrenHalfboard]=useState(0);
 let [tripleChildrenFullboard,setTripleChildrenFullboard]=useState(0);

 let [tripleAdultShow,setTripleAdultShow]=useState(false);
 let [tripleAdult,setTripleAdult]=useState(0);
 let [tripleAdultBreakfast,setTripleAdultBreakfast]=useState(0);
 let [tripleAdultHalfboard,setTripleAdultHalfboard]=useState(0);
 let [tripleAdultFullboard,setTripleAdultFullboard]=useState(0);

 let [quadrupleShow,setQuadrupleShow]=useState(false);
 let [quadruple,setQuadruple]=useState(0);
 let [quadrupleBreakfast,setQuadrupleBreakfast]=useState(0);
 let [quadrupleHalfboard,setQuadrupleHalfboard]=useState(0);
 let [quadrupleFullboard,setQuadrupleFullboard]=useState(0);
 

 const [value, onChange] = useState(new Date());
 var date=new Date();  
 var m=date.getMonth()+1;
 var today=date.getFullYear()+"-"+m+"-"+date.getDate()
 //today="2022-5-1";
 //console.log(today);
 const maxDateBooking=process.env.REACT_APP_MAXDATE;

 
 
const showCreditCard=(process.env.REACT_APP_ENABLE_CREDITCARD=='true')?true:false;

// TESTING EMAIL:
const TESTsubject='Hola';
const TESTbookingName='Test';
const TESTbookingLastName='Sancho';
const TESTbookingEmail='jcsanchop@gmail.com';
const TESTcheckin='1-1-2022';
const TESTcheckout='1-1-2022';
const TESTbookingRoom='Double';
const TESTbookingMeal='Pension';
const TESTbookingCost='200';
const TESTbookingNumber='1222';
const TESTbookingRestaurant='Solo comidas';
//sendConfirmation(TESTsubject, TESTbookingName, TESTbookingLastName, TESTbookingEmail, TESTcheckin, TESTcheckout, TESTbookingRoom, TESTbookingMeal, TESTbookingCost, TESTbookingNumber, TESTbookingRestaurant);


const capsBookingLastName= (value) => {
    const name=value.toUpperCase();
    setBookingLastName(name);
}

const capsBookingName= (value) => {
    const name=value.toUpperCase();
    setBookingName(name);
}

const saveEmail = (value) => {
    const email=value.toLowerCase();
    setBookingEmail(value);
};


const selectedCheckin = (date) => {
        //console.log('selected Checkin:', date);
        setCodeDiscount(0);
        setCodevalid(false);
        setPeople(null);
        setCheckin(convertDate(date));
}
const selectedCheckout = (date) => {
        setCodeDiscount(0);
        setCodevalid(false);
        setPeople(null);
        setCheckout(convertDate(date));
}

const doneGetAvailability = (sr, sb, sh, sf, str, stb, sth, stf, dr, db, dh, df, dtr, dtb, dth, dtf, tr, tb, th, tf, tar, tab, tah, taf, qr, qb, qh, qf, ndays) => {
                            
                            console.log("Searching rooms Done! ");
                            setSearching(false); 

                            if (codevalid && codeDiscount>0 && codeDiscount<100){
                                let x=(100-codeDiscount)/100
                               // console.log("Code Discount: ", code, codeDiscount, x)
                                sr=(sr*x).toFixed(0);
                                sb=(sb*x).toFixed(0);
                                sh=(sh*x).toFixed(0);
                                sf=(sf*x).toFixed(0);
                                str=(str*x).toFixed(0);
                                stb=(stb*x).toFixed(0);
                                sth=(sth*x).toFixed(0);
                                stf=(stf*x).toFixed(0); 
                                dr=(dr*x).toFixed(0);
                                db=(db*x).toFixed(0);
                                dh=(dh*x).toFixed(0);
                                df=(df*x).toFixed(0);
                                dtr=(dtr*x).toFixed(0);
                                dtb=(dtb*x).toFixed(0);
                                dth=(dth*x).toFixed(0);
                                dtf=(dtf*x).toFixed(0);
                                tr=(tr*x).toFixed(0);
                                tb=(tb*x).toFixed(0);
                                th=(th*x).toFixed(0);
                                tf=(tf*x).toFixed(0);
                                tar=(tar*x).toFixed(0);
                                tab=(tab*x).toFixed(0);
                                tah=(tah*x).toFixed(0);
                                taf=(taf*x).toFixed(0);
                                qr=(qr*x).toFixed(0);
                                qb=(qb*x).toFixed(0);
                                qh=(qh*x).toFixed(0);
                                qf=(qf*x).toFixed(0);
                            }

                            if (sr==0 && str==0 && dr==0 && dtr==0 && tr==0 && tar==0 && qr==0){
                               console.log("No rooms available ! ");
                               setNoAvail(true)

                            }else{
                               setDoneSearching(true); 
                               setNdays(ndays);

                            // NOTE: 
                            // any price will be larger than zero >0 
                          //  console.log(sr, sb, sh, sf, str, stb, sth, stf, dr, db, dh, df, dtr, dtb, dth, dtf, tr, tb, th, tf, tar, tab, tah, taf, qr, qb, qh, qf, ndays);
                            console.log("Double prices: ", dr, " Breakfast: ", db, " halfboard: ", dh,  " fullboard: ", df);

                            if (people=='1 adulto' && sr>0){
                                setSingleOneBedShow(true);
                            }
                            setSingleOneBed(sr);
                            setSingleOneBedBreakfast(sb);
                            setSingleOneBedHalfboard(sh);
                            setSingleOneBedFullboard(sf);

                            if (people=='1 adulto' && str>0){
                                setSingleTwoBedShow(true);
                            }
                            setSingleTwoBed(str);
                            setSingleTwoBedBreakfast(stb);
                            setSingleTwoBedHalfboard(sth);
                            setSingleTwoBedFullboard(stf);

                            if (people=='2 adultos' && dr>0){
                                setDoubleOneBedShow(true);
                            }
                            setDoubleOneBed(dr);
                            setDoubleOneBedBreakfast(db);
                            setDoubleOneBedHalfboard(dh);
                            setDoubleOneBedFullboard(df);

                            if (people=='2 adultos' && dtr>0){
                                setDoubleTwoBedShow(true);
                            }
                            setDoubleTwoBed(dtr);
                            setDoubleTwoBedBreakfast(dtb);
                            setDoubleTwoBedHalfboard(dth);
                            setDoubleTwoBedFullboard(dtf);
                            
                            if (people=='2 adulto y 1 nino' && tr>0){
                                setTripleChildrenShow(true);
                            } 
                            setTripleChildren(tr);
                            setTripleChildrenBreakfast(tb);
                            setTripleChildrenHalfboard(th);
                            setTripleChildrenFullboard(tf);

                            if (people=='3 adultos' && tar>0){
                                  setTripleAdultShow(true);
                            }
                            setTripleAdult(tar);
                            setTripleAdultBreakfast(tab);
                            setTripleAdultHalfboard(tah);
                            setTripleAdultFullboard(taf);

                            if (people=='2 adulto y 2 nino' && qr>0){
                                setQuadrupleShow(true);
                            }
                            setQuadruple(qr);
                            setQuadrupleBreakfast(qb);
                            setQuadrupleHalfboard(qh);
                            setQuadrupleFullboard(qf);
                          }

 };

 const getBookingAvailability = () => {

                          if ((checkin!=null)&&(checkout!=null)&&(people!=null)){
                            setSearching(true);
                            setNoAvail(false);
                            setErrorDates(false);
                            setDoneSearching(false); 
                            setSingleTwoBedShow(false);
                            setSingleTwoBedShow(false);
                            setDoubleOneBedShow(false);
                            setDoubleTwoBedShow(false);
                            setTripleChildrenShow(false);
                            setTripleAdultShow(false);
                            setQuadrupleShow(false);
                            console.log("Searching rooms:");
                            getAvailability(doneGetAvailability,checkin,checkout);
                           }else{

                              setErrorDates(true);
                           } 
 };

 const initBooking = (room,roomname,cost,meal) => {
                            setSearching(false);
                            setDoneSearching(false); 
                            setMakeBooking(true);
                            setBookingRoom(room);
                            setBookingRoomName(roomname);
                            setBookingCost(cost);
                            setBookingMeal(meal);
                            var number = randomString({
                                length: 8,
                                numeric: true,
                                letters: false,
                                special: false,

                            });
                            setBookingNumber(number);
                            var date=new Date();  
                            var m=date.getMonth()+1;
                            var today=date.getDate()+"-"+m+"-"+date.getFullYear()
                            setBookingDate(today);
                            console.log("Selected Room:", room, roomname, cost, meal, number, today);
                           
 };

 const doneInputDataBooking = () => {
                            setSearching(false);
                            setDoneSearching(false); 
                            
                            var inputcorrect=true;
                           // console.log(bookingName);
                            if (bookingName==null){
                                inputcorrect=false;
                                setErrorBooking("Por favor, introduzca nombre");
                               // console.log("Error: Name", );
                            }else{
                              if (bookingLastName==null){
                                inputcorrect=false;
                                setErrorBooking("Por favor, introduzca Apellidos");
                              //  console.log("Error: LastName", );
                              }else{

                                  if (bookingPhone==null){
                                        inputcorrect=false;
                                        setErrorBooking("Por favor, introduzca telefono");
                                    //    console.log("Error: Phone", );
                                  }else{
                                     if ((bookingEmail==null) || (validEmail==false) || (validEmail==null)) {
                                        inputcorrect=false;
                                        setErrorBooking("Por favor, introduzca correo electronico correctamente");
                                     //   console.log("Error: Email", validEmail);
                                      }else{
                                        if ((validCard==false)&&(showCreditCard)) {
                                            inputcorrect=false;
                                            setErrorBooking("Por favor, introduzca tarjeta de credito valida");
                                         //   console.log("Error: Credit Card", );
                                          }
                                      }
                                  }
                              }
                            }

                            if (inputcorrect){
                                console.log("Done Input Data Booking:", );
                                setConfirmedBooking(false);
                                setMakeBooking(false);
                                setErrorBooking(false);
                                setConfirmingBookingAdd(true);
                                setConfirmingBookingAvail(true);
                                setConfirmingBooking(true);
                                let bookingRestaurant;
                                if (bookingMeal=='Media Pension'){
                                            bookingRestaurant=process.env.REACT_APP_MSG_PENSION
                                }else{
                                            bookingRestaurant=''
                                }

                                // Send confirmation to client
                                if (process.env.REACT_APP_EMAIL_ENABLE=='true'){
                                    console.log("Sending email...", bookingEmail);
                                    let subject=process.env.REACT_APP_EMAIL_CONFIRMATION_SUBJECT;
                                    sendConfirmation(
                                                 subject,
                                                 bookingName,
                                                 bookingLastName,
                                                 bookingEmail,
                                                 checkin,
                                                 checkout,
                                                 bookingRoomName,
                                                 bookingMeal,
                                                 bookingCost,
                                                 bookingNumber,
                                                 bookingRestaurant,
                                                 bookingRequests
                                                );
                                }

                                // Send confirmation to hotel 
                                if (process.env.REACT_APP_EMAIL_ENABLE=='true'){
                                    console.log("Sending email2...", process.env.REACT_APP_EMAIL_HOTEL);
                                    let subject=process.env.REACT_APP_EMAIL_CONFIRMATION_SUBJECT;
                                    sendConfirmation(
                                                 subject,
                                                 bookingName,
                                                 bookingLastName,
                                                 process.env.REACT_APP_EMAIL_HOTEL,
                                                 checkin,
                                                 checkout,
                                                 bookingRoomName,
                                                 bookingMeal,
                                                 bookingCost,
                                                 bookingNumber,
                                                 bookingRestaurant
                                                );
                                }
                                console.log("Adding booking...", bookingName);
                                addBooking(
                                                 doneConfirmingBookingAdd,
                                                 bookingName,
                                                 bookingLastName,
                                                 bookingEmail,
                                                 checkin,
                                                 checkout,
                                                 bookingRoomName,
                                                 bookingMeal,
                                                 bookingCost,
                                                 bookingNumber,
                                                 bookingDate, 
                                                 bookingPhone, 
                                                 code, 
                                                 codeDiscount,
                                                 bookingRequests,
                                                 people
                                  );
                                  console.log("decrement availability...");
                                  decrementAvailabilityRoom(
                                      doneBookingAvail,
                                      checkin,
                                      checkout,
                                      bookingRoom,
                                      bookingMeal
                                  );
                            }
                           
 };

 const doneConfirmingBookingAdd = () => {
                            console.log("Done Adding Booking:", );
                            setConfirmingBookingAdd(false);
                            if (confirmingBookingAvail==false){
                                setConfirmingBooking(false);
                                setSearching(false);
                                setDoneSearching(false); 
                                setMakeBooking(false);
                                setConfirmingBooking(false);
                                setConfirmedBooking(true);
                            }
 };

 const doneBookingAvail = (sendWarning) => {
                            console.log("Done changing Availability Booking: Sending email");
                            setConfirmingBookingAvail(false);
                            if (confirmingBookingAdd==false){
                                setConfirmingBooking(false);
                                setSearching(false);
                                setDoneSearching(false); 
                                setMakeBooking(false);
                                setConfirmingBooking(false);
                                setConfirmedBooking(true);
                            }
                            if (sendWarning){
                               console.log("Sending Warning email: ", checkin,checkout,bookingRoomName, process.env.REACT_APP_EMAIL_HOTEL);
                               // Using the Confirmation template, we should use another template cost 4 euros/month
                               let subject=' WARNING Availability Zero!'+" "+checkin+" "+checkout+" "+bookingRoomName
                               sendConfirmation(subject,
                                                 '',
                                                 '',
                                                 process.env.REACT_APP_EMAIL_HOTEL,
                                                 checkin,
                                                 checkout,
                                                 bookingRoomName,
                                                 '',
                                                 '',
                                                 '',
                                                 ''
                                                  );
                            }
 };


 const backConfirmedBooking = () => {
                            setSearching(false);
                            setDoneSearching(false); 
                            console.log("Back Confirmed Booking:", );
                            setConfirmedBooking(false);
                            setMakeBooking(false);

 };



 const checkEmail = (value) => {
                            if (bookingEmail==value){
                                setValidEmail(true);
                            }else{
                                setValidEmail(false);
                            }
                           // console.log("Email set:", value );
                           
 };

const viewCredit = (value) => {
                            
                            console.log("Credit Card:", value );
                           
 };

 const cardDone = () => {
                            setValidCard(true);
                            console.log("Booking Credit Card Valid" );
                           
 };
 const closeError = () => {
                            setErrorBooking(false);
                            console.log("Closing Error Alert" );
                           
 };

// TESTING EMAIL:
const Tsubject='Hola';
const TbookingName='Test';
const TbookingLastName='Sancho';
const TbookingEmail='jcsanchop@gmail.com';
const Tcheckin='1-1-2022';
const Tcheckout='1-1-2022';
const TbookingRoom='Double';
const TbookingMeal='Pension';
const TbookingCost='200';
const TbookingNumber='1222';
const TbookingRestaurant='Solo comidas';

//sendConfirmation(Tsubject, TbookingName, TbookingLastName, TbookingEmail, Tcheckin, Tcheckout, TbookingRoom, TbookingMeal, TbookingCost, TbookingNumber, TbookingRestaurant);



  return (
    <>
      <DefaultNavbar 
        brand= "Best Hotels Cheap"
        routes={routes} 
        transparent={true}
        light={true}
        sticky={false} 
        relative={false} 
        center={false} 
        pageref0={PageRef0}
        pageref1={PageRef1}
        pageref2={PageRef2}
        pageref3={PageRef3}
        pageref4={PageRef4}
        pageref5={PageRef5}
        landingpage="terms"
        action={{
          type: "internal",
          route: "/viewbooking",
          label: "Ver Reservas",
          color: "info",
        }}
      />
    
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.1),
              rgba(gradients.dark.state, 0.1)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Hotel Sancho III   
            </MKTypography>
           
          </Grid>
        </Container>
      </MKBox>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
       <MKBox component="section" pt={3} pb={8}>
       <Container>
       {(errorBooking)&&
              <MKAlert color="error" dismissible > {errorBooking} </MKAlert>
        }

       {(searching)&&
        <MKAlert justifyContent="center"  color='light'> <SearchIcon/>&nbsp; Por favor espere,  buscando habitaciones ...  </MKAlert>
       }
       {(confirmingBooking)&&
        <MKAlert justifyContent="center"  color='light'> <SearchIcon/>&nbsp; Por favor espere,  confirmando reserva ...  </MKAlert>
       }
       {(noAvail)&&
        <MKAlert justifyContent="center"  color="error" > <SentimentDissatisfiedIcon/>&nbsp; No habitaciones disponibles para esta fechas. Por favor, seleccione otras fechas o vuelva a intertarlo en otro momento...  </MKAlert>
       }

        {(errorDates)&&
          <MKAlert justifyContent="center"  color="error" > <SentimentDissatisfiedIcon/>&nbsp; Por favor, selecione el rango de fechas e indique el numero de personas  </MKAlert>
        }

       {(searching==false)&&(makeBooking==false)&&(confirmedBooking==false)&&(confirmingBooking==false)&&
        <>
        <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={12} md={3} lg={3}>
             <MKDatePicker  setcheckin={selectedCheckin} setcheckout={selectedCheckout} mindate={today} maxdate={maxDateBooking}/>
          </Grid>

          <Grid item xs={12} md={3} lg={3}>
             <DropdownAndDropup setpeople={setPeople}/> 
          </Grid>

          <Grid item xs={12} md={3} lg={3}>
             <PromotionCode setcode={setCode} setcodediscount={setCodeDiscount} setcodevalid={setCodevalid}/>
          </Grid>

           <Grid item xs={12} md={3} lg={3}>
             <MKButton variant="gradient" color="info" onClick={getBookingAvailability}>
                    <SearchIcon/>&nbsp;
                    Buscar
            </MKButton>
          </Grid>
        </Grid>

         <Grid container justifyContent="center" sx={{ textAlign: "center", mt: 10}}>
         <MKTypography variant="h6" color="secondary" > Niños de 2 a 12 años. Menores de 2 años hay disponibilidad de cuna a {process.env.REACT_APP_PRECIO_CUNA} euros al dia </MKTypography>
         </Grid>
          <Grid container justifyContent="center" sx={{ textAlign: "center"}}>
         <MKTypography variant="h6" color="secondary" > Reserve ahora y page en el Hotel. Puede cancelar gratis la reserva hasta dos dias antes de la llegada. </MKTypography>
         </Grid>
         </>
       }
      </Container>
    </MKBox>

    {(doneSearching)&&
     <MKBox component="section" pt={3} pb={8}>
        
       <Container>
       <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
             <MKTypography
              variant="h4"
              sx={{ mb:5 }}
            >
              <u> Dia entrada &nbsp;&nbsp; {checkin} &nbsp; - &nbsp; Dia salida  &nbsp;&nbsp;  {checkout} </u> 
        </MKTypography>

          {(singleOneBedShow)&&
              <MKBox mb={1}>
              <RoomCard
                initbooking={initBooking}
                image={doubleRoomImage}
                name="Habitacion Doble Uso Individual"
                room='singleOneBed'
                bed="Una cama grande"
                description="16m²•No fumadores•Escaleras•Escritorio•Televisión•Secador de pelo•Baño•Teléfono•Balcón•Ropa de cama y toallas•Cuarto de baño privado•Artículos de aseo gratuitos•Conexión inalámbrica a internet•Aire acondicionado•Acceso a ascensor•Calefacción•Servicio de limpieza"
                people={people}
                ndays={ndays}
                priceRoom     ={singleOneBed}
                priceBreakfast={singleOneBedBreakfast}
                priceHalfboard={singleOneBedHalfboard} 
                priceFullboard={singleOneBedFullboard}
              />
            </MKBox>
          }
           {(singleTwoBedShow)&&
              <MKBox mb={1}>
              <RoomCard
                initbooking={initBooking}
                image={singleRoomImage}
                name="Habitacion Doble Uso Individual"
                room='singleTwoBed'
                bed="Dos camas"
                description="16m²•No fumadores•Escaleras•Escritorio•Televisión•Secador de pelo•Baño•Teléfono•Balcón•Ropa de cama y toallas•Cuarto de baño privado•Artículos de aseo gratuitos•Conexión inalámbrica a internet•Aire acondicionado•Acceso a ascensor•Calefacción•Servicio de limpieza"
                people={people}
                ndays={ndays}
                priceRoom     ={singleTwoBed}
                priceBreakfast={singleTwoBedBreakfast}
                priceHalfboard={singleTwoBedHalfboard} 
                priceFullboard={singleTwoBedFullboard}
              />
            </MKBox>
          }
          {(doubleOneBedShow)&&
              <MKBox mb={1}>
              <RoomCard
                initbooking={initBooking}
                image={doubleRoomImage}
                name="Habitacion Doble "
                room='doubleOneBed'
                bed="Una cama grande"
                description="16m²•No fumadores•Escaleras•Escritorio•Televisión•Secador de pelo•Baño•Teléfono•Balcón•Ropa de cama y toallas•Cuarto de baño privado•Artículos de aseo gratuitos•Conexión inalámbrica a internet•Aire acondicionado•Acceso a ascensor•Calefacción•Servicio de limpieza"
                people={people}
                ndays={ndays}
                priceRoom     ={doubleOneBed}
                priceBreakfast={doubleOneBedBreakfast}
                priceHalfboard={doubleOneBedHalfboard} 
                priceFullboard={doubleOneBedFullboard}
              />
            </MKBox>
          }
          {(doubleTwoBedShow)&&
              <MKBox mb={1}>
              <RoomCard
                initbooking={initBooking}
                image={singleRoomImage}
                name="Habitacion Doble Dos Camas"
                room='doubleTwoBed'
                bed="Dos camas"
                description="16m²•No fumadores•Escaleras•Escritorio•Televisión•Secador de pelo•Baño•Teléfono•Balcón•Ropa de cama y toallas•Cuarto de baño privado•Artículos de aseo gratuitos•Conexión inalámbrica a internet•Aire acondicionado•Acceso a ascensor•Calefacción•Servicio de limpieza"
                people={people}
                ndays={ndays}
                priceRoom     ={doubleTwoBed}
                priceBreakfast={doubleTwoBedBreakfast}
                priceHalfboard={doubleTwoBedHalfboard} 
                priceFullboard={doubleTwoBedFullboard}
              />
            </MKBox>
          }
          {(tripleChildrenShow)&&
              <MKBox mb={1}>
              <RoomCard
                initbooking={initBooking}
                image={tripleRoomImage}
                name="Habitacion Triple"
                room='tripleChildren'
                bed="Una cama grande y cama adicional"
                description="16m²•No fumadores•Escaleras•Escritorio•Televisión•Secador de pelo•Baño•Teléfono•Balcón•Ropa de cama y toallas•Cuarto de baño privado•Artículos de aseo gratuitos•Conexión inalámbrica a internet•Aire acondicionado•Acceso a ascensor•Calefacción•Servicio de limpieza"
                people={people}
                ndays={ndays}
                priceRoom     ={tripleChildren}
                priceBreakfast={tripleChildrenBreakfast}
                priceHalfboard={tripleChildrenHalfboard} 
                priceFullboard={tripleChildrenFullboard}
              />
            </MKBox>
          }
          {(tripleAdultShow)&&
              <MKBox mb={1}>
              <RoomCard
                initbooking={initBooking}
                image={tripleRoomImage}
                name="Habitacion Triple"
                room='tripleAdult'
                bed="Una cama grande y cama adicional"
                description="16m²•No fumadores•Escaleras•Escritorio•Televisión•Secador de pelo•Baño•Teléfono•Balcón•Ropa de cama y toallas•Cuarto de baño privado•Artículos de aseo gratuitos•Conexión inalámbrica a internet•Aire acondicionado•Acceso a ascensor•Calefacción•Servicio de limpieza"
                people={people}
                ndays={ndays}
                priceRoom     ={tripleAdult}
                priceBreakfast={tripleAdultBreakfast}
                priceHalfboard={tripleAdultHalfboard} 
                priceFullboard={tripleAdultFullboard}
              />
            </MKBox>
          }
          {(quadrupleShow)&&
              <MKBox mb={1}>
              <RoomCard
                initbooking={initBooking}
                image={quadrupleRoomImage}
                name="Habitacion Cuatruple"
                room='quadruple'
                bed="Una cama grande y dos camas adicionales"
                description="16m²•No fumadores•Escaleras•Escritorio•Televisión•Secador de pelo•Baño•Teléfono•Balcón•Ropa de cama y toallas•Cuarto de baño privado•Artículos de aseo gratuitos•Conexión inalámbrica a internet•Aire acondicionado•Acceso a ascensor•Calefacción•Servicio de limpieza"
                people={people}
                ndays={ndays}
                priceRoom     ={quadruple}
                priceBreakfast={quadrupleBreakfast}
                priceHalfboard={quadrupleHalfboard} 
                priceFullboard={quadrupleFullboard}
              />
            </MKBox>
          }
        </Grid>
       </Container>
    </MKBox>
    }
    
    {(makeBooking)&&
     <MKBox component="section" pt={3} pb={8}>

       <Container>
        <Card sx={{ mt: 6}}  >
        <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h2"
            >
              Sus Datos
            </MKTypography>
           
          </Grid>
          <Grid container justifyContent="center" sx={{ pb:5, textAlign: "center" }}>
          <Grid item xs={12} md={3} lg={12} sx={{pt:3, pl:5,pr:5}}>
          <MKAlert 
      sx={{ textAlign: "center" }}  
      color='light'> 
      &nbsp;&nbsp;&nbsp;&nbsp;<b>Reserva</b> &nbsp;&nbsp; <BedIcon variant="filled"  fontSize="medium"/>&nbsp;  {bookingRoomName}  &nbsp; &nbsp;  
      <PeopleIcon variant="filled"  fontSize="medium"/>&nbsp; {people} Personas &nbsp; &nbsp;  
      <RestaurantIcon variant="filled"  fontSize="medium"/>&nbsp; {bookingMeal} &nbsp; &nbsp;  
      <DateRangeIcon variant="filled"  fontSize="medium"/> &nbsp; Fechas {checkin}  -  {checkout} &nbsp; &nbsp;  
      <EuroIcon variant="filled"  fontSize="small"/>&nbsp; Precio {bookingCost}  &nbsp; &nbsp;  
      </MKAlert>
      </Grid>
              <Grid item xs={12} md={3} lg={4} sx={{pt:3, pl:5}}>
                <MKInput type="text" label="Nombre" onChange={(e)=> capsBookingName(e.target.value)} />
              </Grid>
              <Grid item xs={12} md={3} lg={4} sx={{pt:3, pl:5}}>
                <MKInput type="text" label="Apellidos"  onChange={(e)=> capsBookingLastName(e.target.value)}/>
              </Grid>
              <Grid item xs={12} md={3} lg={4} sx={{pt:3, pl:5}}>
                <MKInput type="tel" label="Telefono" onChange={(e)=> setBookingPhone(e.target.value)} />
              </Grid>
              <Grid item xs={12} md={3} lg={4} sx={{pt:3, pl:5}}>
                <MKInput type="email" onChange={(e)=> saveEmail(e.target.value)} type="email" label="Correo electronico"  />
              </Grid>
              <Grid item xs={12} md={3} lg={4} sx={{pt:3, pl:5}}>
                <MKInput 
                type="email" 
                onChange={(e)=> checkEmail(e.target.value)}
                label={(validEmail)?"Email correcto":(validEmail==false)?"Email no coincide":"Confirme correo electronico"}
                />
              </Grid>
             
              <Grid item xs={12} md={3} lg={4} sx={{pt:3, pl:5}}>
                <MKInput type="text" label="Tiene alguna peticion ?"   multiline rows={5}  onChange={(e)=> setBookingRequests(e.target.value)}/>
              </Grid>
    
              {(showCreditCard)&&
                <Card sx={{ mt: 6}}>
                  <MKTypography variant="h6">
                      Introduzca Tarjeta de Credito
                  </MKTypography>
                  <Grid container >
                    <PaymentInputs carddone={cardDone}/>
                  </Grid>
               </Card>
             }
               
      </Grid>
      <Grid container justifyContent="center" sx={{ pb:5, textAlign: "center" }}>

            <MKButton variant="gradient" color="info" onClick={doneInputDataBooking}>
                    <CheckIcon/>&nbsp;
                    Confirmar
            </MKButton>
        </Grid>

    </Card>
       </Container>
    </MKBox>
    }
    
{(confirmedBooking)&&
   <MKBox component="section" pt={3} pb={8}>
   <Grid container justifyContent="center" sx={{ pb:5, textAlign: "center" }}>
    
       <Card sx={{ mt: 6}}  >
        <Grid item xs={12} md={3} lg={12} sx={{pt:3, pl:5}}>
            <MKTypography
              variant="h3"
            >
               Reserva confirmada  
            </MKTypography>
        </Grid>
        <Grid item xs={12} md={3} lg={12} sx={{pt:3, pl:5}}>
            <MKTypography
              variant="h6"
            >
              Numero de reserva: {bookingNumber}
            </MKTypography>
        </Grid>
        <Grid item xs={12} md={3} lg={12} sx={{pt:3, pl:5}}>
            <MKTypography
              variant="h6"
            >
              Se ha enviado un correo electronico de confirmacion a su cuenta
            </MKTypography>
        </Grid>
        <Grid item xs={12} md={3} lg={12} sx={{pt:3, pl:5}}>
            <MKTypography
              variant="h6"
            >
              Puede cancelar gratuitamente la reserva hasta 48 horas del dia de entrada
             
            </MKTypography>
        </Grid>
        <Grid item xs={12} md={3} lg={12} sx={{pt:3, pl:5}}>
            <MKTypography
              variant="h6"
            >
              &nbsp; &nbsp;<InsertEmoticonIcon variant="filled"  fontSize="medium"/>&nbsp; &nbsp;El Hotel les desea una Feliz estancia !! 
            </MKTypography>
        </Grid>
        <Grid container justifyContent="center" sx={{ pt:5, pb:5, textAlign: "center" }}>

            <MKButton variant="gradient" color="info" onClick={backConfirmedBooking}>
                    <CheckIcon/>&nbsp;
                    Volver
            </MKButton>
        </Grid>

        </Card>
     
  </Grid>
  </MKBox>
}


      </Card>
      <MKBox pt={6} px={1} mt={6}>
         <Footer company={footerRoutes.company} links={footerRoutes.links} socials={footerRoutes.socials} light={footerRoutes.light}  />
      </MKBox>
    </>
  );
}

export default Booking;
