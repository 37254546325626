// Basic Functions Availability Table

// AWS: https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/dynamodb-example-document-client.html 

var AWS = require('aws-sdk');

const {getIndex, getValidDay, convertDate, getMonth, getDay, getIndexDay, getIndexMonth, getLastDay} = require('./availability')

var configuration={
    region: process.env.REACT_APP_AWS_REGION,
    secretAccessKey: process.env.REACT_APP_AWS_KEY,
    accessKeyId: process.env.REACT_APP_AWS_SECRET
};



AWS.config.update(configuration);

const docClient = new AWS.DynamoDB.DocumentClient();

// **********************************************
// CALCULATIONS
// **********************************************

const month_name=[0,'enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre'];

// **********************************************
// DATABASE 
// **********************************************

 const deleteData = (tableName,data) => {
    var params = {
        TableName: tableName,
        Key: data
    }


    docClient.delete(params, function (err, data) {
        if (!err) {
            console.log(data)
        }
        else{
            console.log('Error',err)
        }
    })
};

function calculateTomorrow(dateString) {
  // Split the date string into day, month, and year components
  var dateComponents = dateString.split('-');
  var day = parseInt(dateComponents[0]);
  var month = parseInt(dateComponents[1]);
  var year = parseInt(dateComponents[2]);

  // Create a Date object using the provided date components
  var date = new Date(year, month - 1, day);

  // Calculate tomorrow's date
  date.setDate(date.getDate() + 1);

  // Extract the day, month, and year from the new date
  var tomorrowDay = date.getDate();
  var tomorrowMonth = date.getMonth() + 1;
  var tomorrowYear = date.getFullYear();

  // Format the date components as strings with leading zeros if necessary
  var formattedDay = tomorrowDay < 10 ? '0' + tomorrowDay : tomorrowDay;
  var formattedMonth = tomorrowMonth < 10 ? '0' + tomorrowMonth : tomorrowMonth;
  var formattedYear = tomorrowYear;

  // Return the formatted tomorrow's date string
  return formattedDay + '-' + formattedMonth + '-' + formattedYear;
};



export const calculateNdays = (checkin,checkout) => {

            let ndays=0;
            let start_month=getMonth(checkin);
            let start_day=getDay(checkin);
            let end_month=getMonth(checkout);
            let end_day=getDay(checkout);
            let start= getIndex(start_month,start_day);
            let end= getIndex(end_month,end_day);
           
            for (let i = start; i < end; i++) {

                        let m1=getIndexMonth(i);
                        let d1=getIndexDay(i);

                        if (getValidDay(m1,d1)){

                            ndays=ndays+1
                        }
            }
            return ndays;
};


// find all bookings that are between checkin and checkout dates and have a bill Number
// And they are not canceled
 const findBookingBillAdminDB = (setDone,  checkin, checkout, year) => {
    var params = {
        TableName: 'bookings',
        KeyConditionExpression: 'bookingYear = :y',
        ExpressionAttributeValues: {
            ':y':year
        }
    };
    let  n=0, booking_date=0, start=0, end=0, total=0, booking_start, booking_end;
   // console.log("findBookingBillAdminDB: ", checkin, checkout);

    docClient.query(params, function (err, data) {
        if (!err) {
            let list=[];
           
            let start_month=getMonth(checkin);
            let start_day=getDay(checkin);
            let end_month=getMonth(checkout);
            let end_day=getDay(checkout);
            start= getIndex(start_month,start_day);
            end= getIndex(end_month,end_day);
           
            for (let i = start; i <= end; i++) {

                    data.Items.forEach(function(element, index, array) {
                        
                        let found=false;
                        
                        let booking_start_month=getMonth(element.checkin);
                        let booking_start_day=getDay(element.checkin);
                        booking_start=getIndex(booking_start_month,booking_start_day);
                        let booking_end_month=getMonth(element.checkout);
                        let booking_end_day=getDay(element.checkout);
                        booking_end=getIndex(booking_end_month,booking_end_day);
                       

                  //      console.log("Booking : ", start, end, booking_start, booking_end, element.hotelBill  );

                        if (booking_start==i  && booking_end<=end &&  element.canceled==false && element.hotelBill!=null && Number.isNaN(Number(element.cost))==false) {
                            found=true;
                          //  console.log("found by selected date range");
                        };
                        
                        if (found){
                           //  console.log("Booking found : ", element);
                                var Data = {
                                    bookingYear:element.bookingYear,
                                    confirmationNumber:element.confirmationNumber,
                                    firstName: element.firstName,
                                    lastName: element.lastName,
                                    checkin:element.checkin,
                                    checkout:element.checkout,
                                    regime:element.regime,
                                    room:element.room,
                                    people:element.people,
                                    phone:element.phone,
                                    email:element.email,
                                    couponSavings:element.couponSavings,
                                    coupon:element.coupon,
                                    cost:element.cost,
                                    transferOfiHotel:element.transferOfiHotel,
                                    canceled:element.canceled,
                                    dateCanceled:element.dateCanceled,
                                    dateBooking:element.dateBooking,
                                    requests:element.requests,
                                    hotelBill:element.hotelBill
                                };
                          //  console.log(n, ' Booking: ', Data );
                            list.push(Data);
                            total=total+Number(element.cost);
                            n=n+1;
                         };
                    });
            };//for

            setDone(list, n, total);
        }
        else{

            console.log('Error',err)
        }
    })
 };

  const getPredictionAdminDB = (setDone, year) => {
    var params = {
        TableName: 'bookings',
        KeyConditionExpression: 'bookingYear = :y',
        ExpressionAttributeValues: {
            ':y':year
        }
    };
    let  n=0, total=0 ;
   // console.log("findBookingBillAdminDB: ", checkin, checkout);

    docClient.query(params, function (err, data) {

        if (!err) {
                    data.Items.forEach(function(element, index, array) {
                        if (element.canceled==false && Number.isNaN(Number(element.cost))==false) {
                            //console.log("prediction: ", n, " cost:", Number(element.cost),  " total: ", Number(total.toFixed(2)))
                            total=total+Number(element.cost);
                            n=n+1;
                         };
                        if (Number.isNaN(Number(element.cost))==true) {
                             console.log("prediction none cost: ", " cost:", Number(element.cost),  " bookingNumber: ", element.confirmationNumber)
                        }

                    });

                    setDone( n, total);
        }
        else{

            console.log('Error',err)
        }
    })
 };

 

 const findBookingbyRestaurantAdminDB = (setDone,  checkin, checkout,  year, checkBreakfast, checkMeal) => {
    var params = {
        TableName: 'bookings',
        KeyConditionExpression: 'bookingYear = :y',
        ExpressionAttributeValues: {
            ':y':year
        }
    };

    let  n=0, booking_date=0, start=0, end=0, start_month, start_day, end_month, end_day;
    let dayelement;

    console.log("findBookingbyRestaurantAdminDB Searching: ",  checkin, checkout);

     docClient.query(params, function (err, data) {

        if (!err) {
            let list=[];
            if (checkin!=null && checkout!=null ){
                    start_month=getMonth(checkin);
                    start_day=getDay(checkin);
                    end_month=getMonth(checkout);
                    end_day=getDay(checkout);
                    start= getIndex(start_month,start_day);
                    end= getIndex(end_month,end_day);
            }
            
            for (let i = start; i <= end; i++) {

                        let ncount=0
                        let n=0;

                        let m1=getIndexMonth(i);
                        let d1=getIndexDay(i);

                        if (getValidDay(m1,d1)){

                            data.Items.forEach(function(element, index, array) {
                                
                                let found=false;
                                let lastmonth,lastday;

                                // Looking for a Booking that it is still on the hotel:
                                
                                if (element.regime==process.env.REACT_APP_REGIME_HALFBOARD){
                                    let checkout_month=getMonth(element.checkout);
                                    let checkout_day=getDay(element.checkout);
                                    [lastmonth,lastday]=getLastDay(checkout_month, checkout_day);
                                    let checkout_date=getIndex(lastmonth,lastday);
                                    let checkin_month=getMonth(element.checkin);
                                    let checkin_day=getDay(element.checkin);
                                    let checkin_date=getIndex(checkin_month,checkin_day);
                                    if (i>=checkin_date  &&  i<=checkout_date &&  element.canceled==false){
                                        found=true;
                                        console.log(i, " ", n, " Booking found regime: ", element.regime, element.confirmationNumber);
                                        n=n+1;
                                    };
                                }

                                if (element.regime==process.env.REACT_APP_REGIME_BREAKFAST){
                                    let checkout_month=getMonth(element.checkout);
                                    let checkout_day=getDay(element.checkout);
                                    let checkout_date=getIndex(checkout_month,checkout_day);
                                    let checkin_month=getMonth(element.checkin);
                                    let checkin_day=getDay(element.checkin);
                                    let checkin_date=getIndex(checkin_month,checkin_day);
                                    if (i>checkin_date  &&  i<=checkout_date &&  element.canceled==false){
                                        found=true;
                                        console.log(i, " ", n, " Booking found regime: ", element.regime, element.confirmationNumber);
                                        n=n+1;
                                    };
                                }

                                if (found){
                                                                                      
                                    if (checkBreakfast && element.regime==process.env.REACT_APP_REGIME_HALFBOARD){
                                        ncount=ncount+1;
                                    }
                                    if (checkBreakfast && element.regime==process.env.REACT_APP_REGIME_BREAKFAST){
                                        ncount=ncount+1;
                                    }
                                     if (checkMeal && element.regime==process.env.REACT_APP_REGIME_HALFBOARD){
                                        ncount=ncount+1;
                                    }
                                };
                                 
                            });
                            let indexday=getIndexDay(i);
                            let indexmonth=getIndexMonth(i);
                            dayelement=indexday + " " + month_name[indexmonth];
                            list.push({'date':dayelement, 'value': ncount, 'index':i});

                        };//valid day
            };//for

            setDone(list);
        }

        else{

            console.log('Error',err)
        }
    })
 };



 const findBookingbyDateAdminDB = (setDone,  checkin, checkout,  year) => {
    var params = {
        TableName: 'bookings',
        KeyConditionExpression: 'bookingYear = :y',
        ExpressionAttributeValues: {
            ':y':year
        }
    };

    let  n=0, booking_date=0, start=0, end=0, start_month, start_day, end_month, end_day;
    let booking_month, booking_day;

    console.log("findBookingbyDateAdminDB Searching: ",  " checkin:", checkin, " checkout:" ,checkout);

     docClient.query(params, function (err, data) {

        if (!err) {

            let list=[];
            if (checkin!=null){
                    start_month=getMonth(checkin);
                    start_day=getDay(checkin);
                    start= getIndex(start_month,start_day);
            }
            if (checkout!=null){
                    end_month=getMonth(checkout);
                    end_day=getDay(checkout);
                    end= getIndex(end_month,end_day);
            }


            if (end==0){
                end=start;
            }


            if(start==0){
                start=end;
            }

            for (let i = start; i <= end; i++) {

                        data.Items.forEach(function(element, index, array) {
                            
                            let found=false;
                            
                            /* CASE Showing bookings that checkin and checkout are between start and end
                            if (checkin!=null){
                                booking_month=getMonth(element.checkin);
                                booking_day=getDay(element.checkin);
                                booking_date=getIndex(booking_month,booking_day);
                            }else{
                                booking_month=getMonth(element.checkout);
                                booking_day=getDay(element.checkout);
                                booking_date=getIndex(booking_month,booking_day);
                            }
                            */

                            /* CASE Showing bookings that checkout are between between start and end */ 
                            booking_month=getMonth(element.checkout);
                            booking_day=getDay(element.checkout);
                            booking_date=getIndex(booking_month,booking_day);
                            //console.log("Booking : ", checkin, checkout, start, end, booking_date);


                            if (booking_date==i && booking_date<=end && element.canceled==false){
                                found=true;
                               // console.log("found by selected date range");
                            };

                            if (found){
                               //  console.log("Booking found : ", element);
                                    var Data = {
                                        bookingYear:element.bookingYear,
                                        confirmationNumber:element.confirmationNumber,
                                        firstName: element.firstName,
                                        lastName: element.lastName,
                                        checkin:element.checkin,
                                        checkout:element.checkout,
                                        regime:element.regime,
                                        room:element.room,
                                        people:element.people,
                                        phone:element.phone,
                                        email:element.email,
                                        couponSavings:element.couponSavings,
                                        coupon:element.coupon,
                                        cost:element.cost,
                                        transferOfiHotel:element.transferOfiHotel,
                                        canceled:element.canceled,
                                        dateCanceled:element.dateCanceled,
                                        dateBooking:element.dateBooking,
                                        requests:element.requests,
                                        hotelBill:element.hotelBill
                                    };
                              //  console.log(n, ' Booking: ', Data );
                                list.push(Data);
                                n=n+1;
                             };
                        });

            };//for

             setDone(list);
        }
        else{
            console.log('Error',err)
        }
    })
 };


 const findBookingbyTodayAdminDB = (setDone,  checkin, checkout,  year) => {
    var params = {
        TableName: 'bookings',
        KeyConditionExpression: 'bookingYear = :y',
        ExpressionAttributeValues: {
            ':y':year
        }
    };

    let  n=0, booking_date=0, start=0, end=0, start_month, start_day, end_month, end_day;
    let booking_month, booking_day;

    console.log("findBookingbyDateAdminDB Searching: ",  " checkin:", checkin, " checkout:" ,checkout);

     docClient.query(params, function (err, data) {

        if (!err) {

            let list=[];
            if (checkin!=null){
                    start_month=getMonth(checkin);
                    start_day=getDay(checkin);
                    start= getIndex(start_month,start_day);
            }
            if (checkout!=null){
                    end_month=getMonth(checkout);
                    end_day=getDay(checkout);
                    end= getIndex(end_month,end_day);
            }


            if (end==0){
                end=start;
            }


            if(start==0){
                start=end;
            }

            for (let i = start; i <= end; i++) {

                        data.Items.forEach(function(element, index, array) {
                            
                            let found=false;
                            
                           

                            /* CASE Showing bookings that checkout are between between start and end */ 
                            booking_month=getMonth(element.checkin);
                            booking_day=getDay(element.checkin);
                            booking_date=getIndex(booking_month,booking_day);
                            //console.log("Booking : ", checkin, checkout, start, end, booking_date);


                            if (booking_date==i && booking_date<=end && element.canceled==false){
                                found=true;
                               // console.log("found by selected date range");
                            };

                            if (found){
                               //  console.log("Booking found : ", element);
                                    var Data = {
                                        bookingYear:element.bookingYear,
                                        confirmationNumber:element.confirmationNumber,
                                        firstName: element.firstName,
                                        lastName: element.lastName,
                                        checkin:element.checkin,
                                        checkout:element.checkout,
                                        regime:element.regime,
                                        room:element.room,
                                        people:element.people,
                                        phone:element.phone,
                                        email:element.email,
                                        couponSavings:element.couponSavings,
                                        coupon:element.coupon,
                                        cost:element.cost,
                                        transferOfiHotel:element.transferOfiHotel,
                                        canceled:element.canceled,
                                        dateCanceled:element.dateCanceled,
                                        dateBooking:element.dateBooking,
                                        requests:element.requests,
                                        hotelBill:element.hotelBill
                                    };
                              //  console.log(n, ' Booking: ', Data );
                                list.push(Data);
                                n=n+1;
                             };
                        });

            };//for

             setDone(list);
        }
        else{
            console.log('Error',err)
        }
    })
 };
const findBookingAdminDB = (setDone, bookingName, bookingNumber, ofihotel, year) => {
    var params = {
        TableName: 'bookings',
        KeyConditionExpression: 'bookingYear = :y',
        ExpressionAttributeValues: {
            ':y':year
        }
    };

    let  n=0, booking_date=0, start=0, end=0, start_month, start_day, end_month, end_day;

    console.log("findBookingAdminDB Searching: ", bookingName, bookingNumber, ofihotel);

    docClient.query(params, function (err, data) {
        if (!err) {
            let list=[];
                        data.Items.forEach(function(element, index, array) {
                            
                            let found=false;
                            
                            //console.log("Booking : ", checkin, checkout, start, end, booking_date);
                           
                            if (bookingName!=null) {
                                let name1 = bookingName.toLowerCase();
                                let name2 = element.firstName.toLowerCase();
                                if (name1.includes(name2)){
                                        found=true;
                                        console.log("found by first name");
                                }
                            };
                            
                            if (bookingName!=null){
                                let name1 = bookingName.toLowerCase();
                                let name2 = element.lastName.toLowerCase();
                                if (name1.includes(name2)){
                                        found=true;
                                        console.log("found by last name");
                                }
                            }
                            if (bookingNumber!=null && Number(element.confirmationNumber)==Number(bookingNumber)){
                                found=true;
                                console.log("found by number");
                            }
                            if (ofihotel==true && element.transferOfiHotel==false){
                                found=true;
                              //  console.log("found by ofihotel");
                            }

                            if (found){
                               //  console.log("Booking found : ", element);
                                    var Data = {
                                        bookingYear:element.bookingYear,
                                        confirmationNumber:element.confirmationNumber,
                                        firstName: element.firstName,
                                        lastName: element.lastName,
                                        checkin:element.checkin,
                                        checkout:element.checkout,
                                        regime:element.regime,
                                        room:element.room,
                                        people:element.people,
                                        phone:element.phone,
                                        email:element.email,
                                        couponSavings:element.couponSavings,
                                        coupon:element.coupon,
                                        cost:element.cost,
                                        transferOfiHotel:element.transferOfiHotel,
                                        canceled:element.canceled,
                                        dateCanceled:element.dateCanceled,
                                        dateBooking:element.dateBooking,
                                        requests:element.requests,
                                        hotelBill:element.hotelBill
                                    };
                              //  console.log(n, ' Booking: ', Data );
                                list.push(Data);
                                n=n+1;
                             };
                        });


             setDone(list);
        }
        else{
            console.log('Error',err)
        }
    })
 };

export const FindConfirmation = (setDone,confirmation, year) => {
    var params = {
        TableName: 'bookings',
        KeyConditionExpression: 'bookingYear = :y',
        ExpressionAttributeValues: {
            ':y':year
        }
    };

    docClient.query(params, function (err, data) {
        if (!err) {
            data.Items.forEach(function(element, index, array) {
                if (element.confirmationNumber==confirmation){
                //console.log('FindConfirmation Found Booking',element.dateBooking);
                    var Data = {
                            bookingYear:element.bookingYear,
                            confirmationNumber:element.confirmationNumber,
                            firstName: element.firstName,
                            lastName: element.lastName,
                            checkin:element.checkin,
                            checkout:element.checkout,
                            regime:element.regime,
                            room:element.room,
                            people:element.people,
                            phone:element.phone,
                            email:element.email,
                            couponSavings:element.couponSavings,
                            coupon:element.coupon,
                            cost:element.cost,
                            transferOfiHotel:element.transferOfiHotel,
                            canceled:element.canceled,
                            dateCanceled:element.dateCanceled,
                            dateBooking:element.dateBooking,
                            requests:element.requests
                        };

                    setDone(Data);
                }
            })
        }else{
            console.log('Error retrieving  Booking ',err)
        }
    })
 };


 export const CancelConfirmation = (setDone,confirmation, year) => {
    var params = {
        TableName: 'bookings',
        KeyConditionExpression: 'bookingYear = :y',
        ExpressionAttributeValues: {
            ':y':year
        }
    };

    docClient.query(params, function (err, data) {
        if (!err) {
            data.Items.forEach(function(element, index, array) {
                if (element.confirmationNumber==confirmation){
                //console.log('CancelConfirmation  Found Booking',element.dateBooking);
                 var date=new Date();  
                 var m=date.getMonth()+1;
                 var today=date.getDate()+"-"+m+"-"+date.getFullYear()
                 var Data = {
                            bookingYear:element.bookingYear,
                            confirmationNumber:element.confirmationNumber,
                            firstName: element.firstName,
                            lastName: element.lastName,
                            checkin:element.checkin,
                            checkout:element.checkout,
                            regime:element.regime,
                            room:element.room,
                            people:element.people,
                            phone:element.phone,
                            email:element.email,
                            couponSavings:element.couponSavings,
                            coupon:element.coupon,
                            cost:element.cost,
                            transferOfiHotel:false,
                            canceled:true,
                            dateCanceled:today,
                            dateBooking:element.dateBooking,
                            requests:element.requests
                };

                var params = {
                    TableName: 'bookings',
                    Item: Data
                }
    
                docClient.put(params, function (err, data) {
                    if (err) {
                        console.log('Error', err)
                    } else {
                        console.log('Success', data)
                        setDone();
                    }
                })
            }
            })
        }else{
            console.log('Error retrieving  Booking ',err)
        }
    })
 };

 export const saveBooking = (setDone, booking, year) => {
    var params = {
        TableName: 'bookings',
        KeyConditionExpression: 'bookingYear = :y',
        ExpressionAttributeValues: {
            ':y':year
        }
    };
    docClient.query(params, function (err, data) {
        if (!err) {
            data.Items.forEach(function(element, index, array) {
                if (element.confirmationNumber==booking.confirmationNumber){
                    console.log("saveBooking Saving this booking: ", booking);
                 var values = {
                            bookingYear:booking.bookingYear,
                            confirmationNumber:booking.confirmationNumber,
                            firstName: booking.firstName,
                            lastName: booking.lastName,
                            checkin:booking.checkin,
                            checkout:booking.checkout,
                            regime:booking.regime,
                            room:booking.room,
                            people:booking.people,
                            phone:booking.phone,
                            email:booking.email,
                            couponSavings:booking.couponSavings,
                            coupon:booking.coupon,
                            cost:booking.cost,
                            transferOfiHotel:booking.transferOfiHotel,
                            canceled:booking.canceled,
                            dateCanceled:booking.dateCanceled,
                            dateBooking:booking.dateBooking,
                            requests:booking.requests,
                            hotelBill:booking.hotelBill
                };

                var params = {
                    TableName: 'bookings',
                    Item: values
                }
                docClient.put(params, function (err, data) {
                    if (err) {
                        console.log('Error', err)
                    } else {
                        console.log('Success', data)
                        setDone();
                    }
                })
            }
            })
        }else{
            console.log('Error retrieving  Booking ',err)
        }
    })  


 };

export const findBookingbyRestaurantAdmin = async  (setDone, checkin, checkout, checkBreakfast, checkMeal) => {
    console.log('findBookingbyRestaurantAdmin ...',  checkin,  checkout);
    const date = new Date();
    let year=date.getFullYear();
    await findBookingbyRestaurantAdminDB(setDone, checkin, checkout, year, checkBreakfast, checkMeal);
}

export const findBookingAdmin = async  (setDone, bookingName, bookingNumber, checkin, checkout, ofihotel) => {
    console.log('findBookingAdmin  Find bookings ...', bookingName, bookingNumber, checkin,  checkout, ofihotel);
    const date = new Date();
    let year=date.getFullYear();
    if (bookingName!=null || bookingNumber!=null || ofihotel!=null){
        await findBookingAdminDB(setDone, bookingName, bookingNumber, ofihotel, year);
    
    }else{
        if (checkin!=null || checkout!=null){
            await findBookingbyDateAdminDB(setDone, checkin, checkout, year);
        };
    };
};

export const findBookingTodayAdmin = async  (setDone, bookingName, bookingNumber, checkin, checkout, ofihotel) => {
    console.log('findBookingAdmin  Find bookings ...', bookingName, bookingNumber, checkin,  checkout, ofihotel);
    const date = new Date();
    let year=date.getFullYear();
    
    if (checkin!=null){
            await findBookingbyTodayAdminDB(setDone, checkin, checkout, year);
    };
};

export const findBookingCheckinTomorrowAdmin = async  (setDone, bookingName, bookingNumber, checkin, checkout, ofihotel) => {
    
    const date = new Date();
    let year=date.getFullYear();
    let tomorrowCheckin=calculateTomorrow(checkin);
    console.log("findBooking CheckinTomorrowAdmin: ", checkin, tomorrowCheckin);
    // change the checkin for tomorrow to get the checkin for tomorrow: 

    if (checkin!=null){
            await findBookingbyTodayAdminDB(setDone, tomorrowCheckin, checkout, year);
    };
};


export const findBookingBillAdmin = async  (setDone, checkin, checkout) => {
    //console.log('findBookingBillAdmin Find bookings ...',  checkin,  checkout);
    const date = new Date();
    let year=date.getFullYear();
    await findBookingBillAdminDB(setDone,  checkin, checkout, year);
}

export const getPredictionAdmin = async  (setDone) => {
    const date = new Date();
    let year=date.getFullYear();
    await getPredictionAdminDB(setDone, year);
}


export const saveBookingAdmin = async  (setDone, booking) => {
    console.log('saveBookingAdmin save booking ...', booking);
    const date = new Date();
    let year=date.getFullYear();
    await saveBooking(setDone, booking, year);
}

export const findBookingbyConfirmationNumber = async  (setDone,confirmationNumber) => {
    console.log('findBookingbyConfirmationNumber Find bookings ...',confirmationNumber);
    const date = new Date();
    let year=date.getFullYear();
    if (confirmationNumber!=''){
        await FindConfirmation(setDone,Number(confirmationNumber), year);
    }else{
        console.log('Error: Missing confirmation number ');
    }
}

export const cancelBookingbyConfirmationNumber= async  (setDone,confirmationNumber) => {
    console.log('cancelBookingbyConfirmationNumber Cancel booking ...',confirmationNumber);
    const date = new Date();
    let year=date.getFullYear();
    if (confirmationNumber!=''){
        await CancelConfirmation(setDone,Number(confirmationNumber), year);
    }else{
        console.log('Error: Missing confirmation number ');
    }
}

export const addBooking = async (setDone, bookingName, bookingLastName, bookingEmail, checkin, checkout, bookingRoom, bookingMeal, bookingCost, bookingNumber, bookingDate, bookingPhone, code, codeDiscount, requests, people) => {
    const date = new Date();
    const  year  = date.getFullYear();
    var Data = {
                bookingYear:year,
                confirmationNumber:Number(bookingNumber),
                firstName: bookingName,
                lastName: bookingLastName,
                checkin:checkin,
                checkout:checkout,
                people:people,
                room:bookingRoom,
                regime:bookingMeal,
                phone:bookingPhone,
                email:bookingEmail,
                couponSavings:codeDiscount,
                coupon:code,
                cost:bookingCost,
                transferOfiHotel:false,
                canceled:false,
                dateCanceled:null,
                dateBooking:bookingDate,
                requests: requests,
                hotelBill:null
        };

        var params = {
        TableName: 'bookings',
        Item: Data
        }
    
        docClient.put(params, function (err, data) {
            if (err) {
                console.log('Error', err)
            } else {
                setDone();
            }
        })
  }

// **********************************************

  