/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useState } from "react";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKDatePicker from "components/MKDatePicker";
import MKAlert from "components/MKAlert";
import MKInput from "components/MKInput";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/admin";
import Footer from "examples/Footers/CenteredFooter";
import BookingCard from "examples/Cards/BookingCards";
import TablePrice from "examples/TablePrice";

// TABLES
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


// CHECKBOXES
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


// Images
//import bgImage from "assets/images/shapes/waves-white.svg";
const bgImage= process.env.REACT_APP_PUBLIC_URL+"imgs/"+process.env.REACT_APP_HOTELSANCHO_HEADER;

// DATABASE LIBS
const { getAvailability, getPrices, changeAvailabilityRoom, getAdminPrice, setAdminPrice, convertDate} = require('../../../libs/availability')
const { addBooking, findBookingbyConfirmationNumber, cancelBookingbyConfirmationNumber } = require('../../../libs/bookings')
const {  getAuth, handleAuthentication } = require('../../../libs/authentication');



// ICONS:
import SearchIcon from '@mui/icons-material/Search';
import BedIcon from '@mui/icons-material/Bed';
import PeopleIcon from '@mui/icons-material/People';
import DateRangeIcon from '@mui/icons-material/DateRange';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import EuroIcon from '@mui/icons-material/Euro';
import CheckIcon from '@mui/icons-material/Check';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import SaveIcon from '@mui/icons-material/Save';


// Routes
import routes from "routes";
import footerRoutes from "footer.routes";



//Scrool to different sections of the page 
  const PageRef0 = null;
  const PageRef1 = null;
  const PageRef2 = null;
  const PageRef3 = null;
  const PageRef4 = null;
  const PageRef5 = null;



  const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
     width: "100px"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    width: "100px"
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(
    valid:boolean,
    index:number,
    date: string,
    room: number,
    breakfast: number, 
    meal: number
    ) {
            return { valid, index, date, room, breakfast, meal };
    }

let rows = [];



function Price() {

 let [searchingData, setSearchingData] = useState(false);
 let [changingData, setChangingData] = useState(false);
 let [doneChangingData, setDoneChangingData] = useState(false);
 let [checkin, setCheckin] = useState(null);
 let [checkout, setCheckout] = useState(null);
 let [checkRoom, setCheckRoom] = useState(true);
 let [checkBreakfast, setCheckBreakfast] = useState(false);
 let [checkMeal, setCheckMeal] = useState(false);
 let [showPrices,setShowPrices]=useState(false);
 let [errorDates,setErrorDates]=useState(false);

 let [date0,setDate0]=useState(null);
 let [value0,setValue0]=useState(null);
 let [changed0,setChanged0]=useState(false);
 let [valid0,setValid0]=useState(false);
 let [index0,setIndex0]=useState(null);

 let [date1,setDate1]=useState(null);
 let [value1,setValue1]=useState(null);
 let [changed1,setChanged1]=useState(false);
 let [valid1,setValid1]=useState(false);
 let [index1,setIndex1]=useState(null);

 let [date2,setDate2]=useState(null);
 let [value2,setValue2]=useState(null);
 let [changed2,setChanged2]=useState(false);
 let [valid2,setValid2]=useState(false);
 let [index2,setIndex2]=useState(null);

 let [date3,setDate3]=useState(null);
 let [value3,setValue3]=useState(null);
 let [changed3,setChanged3]=useState(false);
 let [valid3,setValid3]=useState(false);
 let [index3,setIndex3]=useState(null);

 let [date4,setDate4]=useState(null);
 let [value4,setValue4]=useState(null);
 let [changed4,setChanged4]=useState(false);
 let [valid4,setValid4]=useState(false);
 let [index4,setIndex4]=useState(null);

 let [date5,setDate5]=useState(null);
 let [value5,setValue5]=useState(null);
 let [changed5,setChanged5]=useState(false);
 let [valid5,setValid5]=useState(false);
 let [index5,setIndex5]=useState(null);

 let [date6,setDate6]=useState(null);
 let [value6,setValue6]=useState(null);
 let [changed6,setChanged6]=useState(false);
 let [valid6,setValid6]=useState(false);
 let [index6,setIndex6]=useState(null);

 let [date7,setDate7]=useState(null);
 let [value7,setValue7]=useState(null);
 let [changed7,setChanged7]=useState(false);
 let [valid7,setValid7]=useState(false);
 let [index7,setIndex7]=useState(null);

 let [date8,setDate8]=useState(null);
 let [value8,setValue8]=useState(null);
 let [changed8,setChanged8]=useState(false);
 let [valid8,setValid8]=useState(false);
 let [index8,setIndex8]=useState(null);

 let [date9,setDate9]=useState(null);
 let [value9,setValue9]=useState(null);
 let [changed9,setChanged9]=useState(false);
 let [valid9,setValid9]=useState(false);
 let [index9,setIndex9]=useState(null);

 let [date10,setDate10]=useState(null);
 let [value10,setValue10]=useState(null);
 let [changed10,setChanged10]=useState(false);
 let [valid10,setValid10]=useState(false);
 let [index10,setIndex10]=useState(null);

 let [date11,setDate11]=useState(null);
 let [value11,setValue11]=useState(null);
 let [changed11,setChanged11]=useState(false);
 let [valid11,setValid11]=useState(false);
 let [index11,setIndex11]=useState(null);

 let [date12,setDate12]=useState(null);
 let [value12,setValue12]=useState(null);
 let [changed12,setChanged12]=useState(false);
 let [valid12,setValid12]=useState(false);
 let [index12,setIndex12]=useState(null);

 let [date13,setDate13]=useState(null);
 let [value13,setValue13]=useState(null);
 let [changed13,setChanged13]=useState(false);
 let [valid13,setValid13]=useState(false);
 let [index13,setIndex13]=useState(null);

 let [date14,setDate14]=useState(null);
 let [value14,setValue14]=useState(null);
 let [changed14,setChanged14]=useState(false);
 let [valid14,setValid14]=useState(false);
 let [index14,setIndex14]=useState(null);

 let [date15,setDate15]=useState(null);
 let [value15,setValue15]=useState(null);
 let [changed15,setChanged15]=useState(false);
 let [valid15,setValid15]=useState(false);
 let [index15,setIndex15]=useState(null);

 
 let [menuSearchDate, setMenuSearchDate] = useState(true);
 let [showBooking, setShowBooking] = useState(false);
 let [errorConfirmationNumber, setErrorConfirmationNumber]= useState(false);
 let [cancelingBooking, setCancelingBooking] = useState(false);
 let [showAskCancelBooking, setShowAskCancelBooking] = useState(false);

const selectedCheckin = (date) => {
        //console.log('selected Checkin:', date);
        setCheckin(convertDate(date));
}
const selectedCheckout = (date) => {
        setCheckout(convertDate(date));
}

const resetValues = () => {
      setDate0(null);setValue0(null);setChanged0(false);setValid0(false);setIndex0(null);
      setDate1(null);setValue1(null);setChanged1(false);setValid1(false);setIndex1(null);
      setDate2(null);setValue2(null);setChanged2(false);setValid2(false);setIndex2(null);
      setDate3(null);setValue3(null);setChanged3(false);setValid3(false);setIndex3(null);
      setDate4(null);setValue4(null);setChanged4(false);setValid4(false);setIndex4(null);
      setDate5(null);setValue5(null);setChanged5(false);setValid5(false);setIndex5(null);
      setDate6(null);setValue6(null);setChanged6(false);setValid6(false);setIndex6(null);
      setDate7(null);setValue7(null);setChanged7(false);setValid7(false);setIndex7(null);
      setDate8(null);setValue8(null);setChanged8(false);setValid8(false);setIndex8(null);
      setDate9(null);setValue9(null);setChanged9(false);setValid9(false);setIndex9(null);
      setDate10(null);setValue10(null);setChanged10(false);setValid10(false);setIndex10(null);
      setDate11(null);setValue11(null);setChanged11(false);setValid11(false);setIndex11(null);
      setDate12(null);setValue12(null);setChanged12(false);setValid12(false);setIndex12(null);
      setDate13(null);setValue13(null);setChanged13(false);setValid13(false);setIndex13(null);
      setDate14(null);setValue14(null);setChanged14(false);setValid14(false);setIndex14(null);
      setDate15(null);setValue15(null);setChanged15(false);setValid15(false);setIndex15(null);
  }

const changePrices = () => {
                          
                          console.log("Changing Prices:");
                          setShowPrices(false);
                          setSearchingData(false);
                          setDoneChangingData(false);
                          setChangingData(true);
                          const prices=[];
                          const indexes=[];
                          if (valid0){prices.push(Number(value0));indexes.push(index0);};
                          if (valid1){prices.push(Number(value1));indexes.push(index1);};
                          if (valid2){prices.push(Number(value2));indexes.push(index2);};
                          if (valid3){prices.push(Number(value3));indexes.push(index3);};
                          if (valid4){prices.push(Number(value4));indexes.push(index4);};
                          if (valid5){prices.push(Number(value5));indexes.push(index5);};
                          if (valid6){prices.push(Number(value6));indexes.push(index6);};
                          if (valid7){prices.push(Number(value7));indexes.push(index7);};
                          if (valid8){prices.push(Number(value8));indexes.push(index8);};
                          if (valid9){prices.push(Number(value9));indexes.push(index9);};
                          if (valid10){prices.push(Number(value10));indexes.push(index10);};
                          if (valid11){prices.push(Number(value11));indexes.push(index11);};
                          if (valid12){prices.push(Number(value12));indexes.push(index12);};
                          if (valid13){prices.push(Number(value13));indexes.push(index13);};
                          if (valid14){prices.push(Number(value14));indexes.push(index14);};
                          if (valid15){prices.push(Number(value15));indexes.push(index15);};

                          setAdminPrice(doneChangePrices,checkin,checkout,checkRoom, checkBreakfast, checkMeal, prices, indexes);
 };


const doneChangePrices = () => {
                          
                          console.log("Done Changing Prices:");
                          setChangingData(false);
                          setShowPrices(false);
                          setSearchingData(false);
                          setDoneChangingData(true);
                          //getAdminPrice(doneGetPrices,checkin,checkout);

 };

const setValues = (id,value) => {
                          
                        //  console.log("seting values:", id, value);
                          if (id==0){setChanged0(true);setValue0(value);}
                          if (id==1){setChanged1(true);setValue1(value);}
                          if (id==2){setChanged2(true);setValue2(value);}
                          if (id==3){setChanged3(true);setValue3(value);}
                          if (id==4){setChanged4(true);setValue4(value);}
                          if (id==5){setChanged5(true);setValue5(value);}
                          if (id==6){setChanged6(true);setValue6(value);}
                          if (id==7){setChanged7(true);setValue7(value);}
                          if (id==8){setChanged8(true);setValue8(value);}
                          if (id==9){setChanged9(true);setValue9(value);}
                          if (id==10){setChanged10(true);setValue10(value);}
                          if (id==11){setChanged11(true);setValue11(value);}
                          if (id==12){setChanged12(true);setValue12(value);}
                          if (id==13){setChanged13(true);setValue13(value);}
                          if (id==14){setChanged14(true);setValue14(value);}
                          if (id==15){setChanged15(true);setValue15(value);}
 };

 var getValues = (id) => {
                         let value;
                         if (id==0){value=value0};
                         if (id==1){value=value1};
                         if (id==2){value=value2};
                         if (id==3){value=value3};
                         if (id==4){value=value4};
                         if (id==5){value=value5};
                         if (id==6){value=value6};
                         if (id==7){value=value7};
                         if (id==8){value=value8};
                         if (id==9){value=value9};
                         if (id==10){value=value10};
                         if (id==11){value=value11};
                         if (id==12){value=value12};
                         if (id==13){value=value13};
                         if (id==14){value=value14};
                         if (id==15){value=value15};
                       //  console.log("get values:", id, value);
                         return value
 };

 
 const getPrices = () => {
                          if (checkin==null || checkout==null){
                              setErrorDates(true);
                          }else{
                              console.log("Searching Prices:");
                              setErrorDates(false);
                              setSearchingData(true);
                              setDoneChangingData(false);
                              setShowPrices(false);
                              resetValues();
                              getAdminPrice(doneGetPrices,checkin,checkout, checkRoom, checkBreakfast, checkMeal );
                          }
 };

 const doneGetPrices = (pricelistDB) => {
                          
                          console.log("Done Searching Prices:");
                          setSearchingData(false);
                          setDoneChangingData(false);
                          console.log(pricelistDB);
                          setShowPrices(true);
                          pricelistDB.forEach((item, index) => {
                            console.log( " pricelist: ", `${index} : ${item.date} ${item.price}`);
                            if (index==0){setDate0(item.date);setValue0(item.price);setChanged0(false);setValid0(true);setIndex0(item.index);}
                            if (index==1){setDate1(item.date);setValue1(item.price);setChanged1(false);setValid1(true);setIndex1(item.index);}
                            if (index==2){setDate2(item.date);setValue2(item.price);setChanged2(false);setValid2(true);setIndex2(item.index);}
                            if (index==3){setDate3(item.date);setValue3(item.price);setChanged3(false);setValid3(true);setIndex3(item.index);}
                            if (index==4){setDate4(item.date);setValue4(item.price);setChanged4(false);setValid4(true);setIndex4(item.index);}
                            if (index==5){setDate5(item.date);setValue5(item.price);setChanged5(false);setValid5(true);setIndex5(item.index);}
                            if (index==6){setDate6(item.date);setValue6(item.price);setChanged6(false);setValid6(true);setIndex6(item.index);}
                            if (index==7){setDate7(item.date);setValue7(item.price);setChanged7(false);setValid7(true);setIndex7(item.index);}
                            if (index==8){setDate8(item.date);setValue8(item.price);setChanged8(false);setValid8(true);setIndex8(item.index);}
                            if (index==9){setDate9(item.date);setValue9(item.price);setChanged9(false);setValid9(true);setIndex9(item.index);}
                            if (index==10){setDate10(item.date);setValue10(item.price);setChanged10(false);setValid10(true);setIndex10(item.index);}
                            if (index==11){setDate11(item.date);setValue11(item.price);setChanged11(false);setValid11(true);setIndex11(item.index);}
                            if (index==12){setDate12(item.date);setValue12(item.price);setChanged12(false);setValid12(true);setIndex12(item.index);}
                            if (index==13){setDate13(item.date);setValue13(item.price);setChanged13(false);setValid13(true);setIndex13(item.index);}
                            if (index==14){setDate14(item.date);setValue14(item.price);setChanged14(false);setValid14(true);setIndex14(item.index);}
                            if (index==15){setDate15(item.date);setValue15(item.price);setChanged15(false);setValid15(true);setIndex15(item.index);}
                          });
 };

const handleRoom = () => {
                          setCheckRoom(true);
                          setCheckBreakfast(false);
                          setCheckMeal(false);
 };

 const handleBreakfast = () => {
                          setCheckRoom(false);
                          setCheckBreakfast(true);
                          setCheckMeal(false);
 };

 const handleMeal = () => {
                          setCheckRoom(false);
                          setCheckBreakfast(false);
                          setCheckMeal(true);
 };


  return (
    <>
      <DefaultNavbar 
        brand= "Best Hotels Cheap"
        routes={routes} 
        transparent={false}
        light={false}
        sticky={false} 
        relative={false} 
        center={false} 
        pageref0={PageRef0}
        pageref1={PageRef1}
        pageref2={PageRef2}
        pageref3={PageRef3}
        pageref4={PageRef4}
        pageref5={PageRef5}
        landingpage="admin"
        isauthenticated={getAuth()}
        setDone={handleAuthentication}
      />
      
    {(getAuth())&&<>
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.1),
              rgba(gradients.dark.state, 0.1)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Precios Hotel Sancho III   
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>


      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

     <MKBox component="section" pt={3} pb={8}>
       <Container>

       {(changingData)&&
          <MKAlert justifyContent="center"  color="light" > <HourglassTopIcon/>&nbsp; Por favor, espere para cambiar los precios ...  </MKAlert>
        }

        {(doneChangingData)&&
          <MKAlert justifyContent="center"  color="success" > <CheckIcon/>&nbsp; Precios cambiados correctamente  </MKAlert>
        }

        {(searchingData)&&
          <MKAlert justifyContent="center"  color="light" > <HourglassTopIcon/>&nbsp; Por favor, espere obtener los precios ...  </MKAlert>
        }

        {(errorDates)&&
          <MKAlert justifyContent="center"  color="error" > <SentimentDissatisfiedIcon/>&nbsp; Por favor, selecione el rango de fechas  </MKAlert>
        }


        {(menuSearchDate)&&
          <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={12} md={3} lg={3}>
             <MKDatePicker  setcheckin={selectedCheckin} setcheckout={selectedCheckout}/>
          </Grid>

          <Grid item xs={12} md={3} lg={3} sx={{ textAlign: "left" }}>
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={checkRoom} onChange={handleRoom}/>} label="Habitacion Doble" />
                <FormControlLabel control={<Checkbox checked={checkBreakfast} onChange={handleBreakfast}/>} label="Desayuno" />
                <FormControlLabel control={<Checkbox checked={checkMeal} onChange={handleMeal}/>} label="Comida" />
            </FormGroup>
          </Grid>

           <Grid item xs={12} md={3} lg={3}>
             <MKButton variant="gradient" color="info" onClick={getPrices}>
                    <SearchIcon/>&nbsp;
                    Obtener
            </MKButton>
          </Grid>
        </Grid>
       }


        {(showPrices)&&
            <Grid container justifyContent="center" sx={{ textAlign: "center", mt:5}}>
          <Grid item xs={12} md={3} lg={3}>
           <TableContainer component={Paper}>
      <Table sx={{ minWidth: 30 }} aria-label="customized table">
       <TableHead>
          <TableRow>
            <TableCell>Fecha</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

        {(valid0)&& <StyledTableRow key={date0}>
              <StyledTableCell align="left" >  {date0} </StyledTableCell><StyledTableCell align="left">
                 {(changed0)?(<MKInput  success value={getValues(0)} onChange={(e)=> setValues(0,e.target.value)} />):
                 (<MKInput  value={getValues(0)} onChange={(e)=> setValues(0,e.target.value)} />)}
        </StyledTableCell></StyledTableRow>}

        {(valid1)&& <StyledTableRow key={date1}>
              <StyledTableCell align="left" >  {date1} </StyledTableCell><StyledTableCell align="left">
                 {(changed1)?(<MKInput  success value={getValues(1)} onChange={(e)=> setValues(1,e.target.value)} />):
                 (<MKInput  value={getValues(1)} onChange={(e)=> setValues(1,e.target.value)} />)}
        </StyledTableCell></StyledTableRow>}

        {(valid2)&& <StyledTableRow key={date2}>
              <StyledTableCell align="left" >  {date2} </StyledTableCell><StyledTableCell align="left">
                 {(changed2)?(<MKInput  success value={getValues(2)} onChange={(e)=> setValues(2,e.target.value)} />):
                 (<MKInput  value={getValues(2)} onChange={(e)=> setValues(2,e.target.value)} />)}
        </StyledTableCell></StyledTableRow>}

         {(valid3)&& <StyledTableRow key={date3}>
              <StyledTableCell align="left" >  {date3} </StyledTableCell><StyledTableCell align="left">
                 {(changed3)?(<MKInput  success value={getValues(3)} onChange={(e)=> setValues(3,e.target.value)} />):
                 (<MKInput  value={getValues(3)} onChange={(e)=> setValues(3,e.target.value)} />)}
        </StyledTableCell></StyledTableRow>}

        {(valid4)&& <StyledTableRow key={date4}>
              <StyledTableCell align="left" >  {date4} </StyledTableCell><StyledTableCell align="left">
                 {(changed4)?(<MKInput  success value={getValues(4)} onChange={(e)=> setValues(4,e.target.value)} />):
                 (<MKInput  value={getValues(4)} onChange={(e)=> setValues(4,e.target.value)} />)}
        </StyledTableCell></StyledTableRow>}

        {(valid5)&& <StyledTableRow key={date5}>
              <StyledTableCell align="left" >  {date5} </StyledTableCell><StyledTableCell align="left">
                 {(changed5)?(<MKInput  success value={getValues(5)} onChange={(e)=> setValues(5,e.target.value)} />):
                 (<MKInput  value={getValues(5)} onChange={(e)=> setValues(5,e.target.value)} />)}
        </StyledTableCell></StyledTableRow>}

        {(valid6)&& <StyledTableRow key={date6}>
              <StyledTableCell align="left" >  {date6} </StyledTableCell><StyledTableCell align="left">
                 {(changed6)?(<MKInput  success value={getValues(6)} onChange={(e)=> setValues(6,e.target.value)} />):
                 (<MKInput  value={getValues(6)} onChange={(e)=> setValues(6,e.target.value)} />)}
        </StyledTableCell></StyledTableRow>}

        {(valid7)&& <StyledTableRow key={date7}>
              <StyledTableCell align="left" >  {date7} </StyledTableCell><StyledTableCell align="left">
                 {(changed7)?(<MKInput  success value={getValues(7)} onChange={(e)=> setValues(7,e.target.value)} />):
                 (<MKInput  value={getValues(7)} onChange={(e)=> setValues(7,e.target.value)} />)}
        </StyledTableCell></StyledTableRow>}

        {(valid8)&& <StyledTableRow key={date8}>
              <StyledTableCell align="left" >  {date8} </StyledTableCell><StyledTableCell align="left">
                 {(changed8)?(<MKInput  success value={getValues(8)} onChange={(e)=> setValues(8,e.target.value)} />):
                 (<MKInput  value={getValues(8)} onChange={(e)=> setValues(8,e.target.value)} />)}
        </StyledTableCell></StyledTableRow>}

        {(valid9)&& <StyledTableRow key={date9}>
              <StyledTableCell align="left" >  {date9} </StyledTableCell><StyledTableCell align="left">
                 {(changed9)?(<MKInput  success value={getValues(9)} onChange={(e)=> setValues(9,e.target.value)} />):
                 (<MKInput  value={getValues(9)} onChange={(e)=> setValues(9,e.target.value)} />)}
        </StyledTableCell></StyledTableRow>}

        {(valid10)&& <StyledTableRow key={date10}>
              <StyledTableCell align="left" >  {date10} </StyledTableCell><StyledTableCell align="left">
                 {(changed10)?(<MKInput  success value={getValues(10)} onChange={(e)=> setValues(10,e.target.value)} />):
                 (<MKInput  value={getValues(10)} onChange={(e)=> setValues(10,e.target.value)} />)}
        </StyledTableCell></StyledTableRow>}

        {(valid11)&& <StyledTableRow key={date11}>
              <StyledTableCell align="left" >  {date11} </StyledTableCell><StyledTableCell align="left">
                 {(changed11)?(<MKInput  success value={getValues(11)} onChange={(e)=> setValues(11,e.target.value)} />):
                 (<MKInput  value={getValues(11)} onChange={(e)=> setValues(11,e.target.value)} />)}
        </StyledTableCell></StyledTableRow>}

        {(valid12)&& <StyledTableRow key={date12}>
              <StyledTableCell align="left" >  {date12} </StyledTableCell><StyledTableCell align="left">
                 {(changed12)?(<MKInput  success value={getValues(12)} onChange={(e)=> setValues(12,e.target.value)} />):
                 (<MKInput  value={getValues(12)} onChange={(e)=> setValues(12,e.target.value)} />)}
        </StyledTableCell></StyledTableRow>}

        {(valid13)&& <StyledTableRow key={date13}>
              <StyledTableCell align="left" >  {date13} </StyledTableCell><StyledTableCell align="left">
                 {(changed13)?(<MKInput  success value={getValues(13)} onChange={(e)=> setValues(13,e.target.value)} />):
                 (<MKInput  value={getValues(13)} onChange={(e)=> setValues(13,e.target.value)} />)}
        </StyledTableCell></StyledTableRow>}


        {(valid14)&& <StyledTableRow key={date14}>
              <StyledTableCell align="left" >  {date14} </StyledTableCell><StyledTableCell align="left">
                 {(changed14)?(<MKInput  success value={getValues(14)} onChange={(e)=> setValues(14,e.target.value)} />):
                 (<MKInput  value={getValues(14)} onChange={(e)=> setValues(14,e.target.value)} />)}
        </StyledTableCell></StyledTableRow>}

        {(valid15)&& <StyledTableRow key={date15}>
              <StyledTableCell align="left" >  {date15} </StyledTableCell><StyledTableCell align="left">
                 {(changed15)?(<MKInput  success value={getValues(15)} onChange={(e)=> setValues(15,e.target.value)} />):
                 (<MKInput  value={getValues(15)} onChange={(e)=> setValues(15,e.target.value)} />)}
        </StyledTableCell></StyledTableRow>}

          
        </TableBody>
      </Table>
    </TableContainer>
    <Grid container justifyContent="center" sx={{ textAlign: "center", mt:5 }}>
    <Grid item xs={12} md={3} lg={6} >
             <MKButton variant="gradient" color="info" onClick={changePrices}>
                    <SaveIcon/>&nbsp;
                    Guardar
            </MKButton>
          </Grid>

    </Grid>
        </Grid>
        </Grid>
        }
     
        
         </Container>
        </MKBox>
      </Card>
</>}

      <MKBox pt={6} px={1} mt={6}>
         <Footer company={footerRoutes.company} links={footerRoutes.links} socials={footerRoutes.socials} light={footerRoutes.light}  />
      </MKBox>
    </>
  );
}

export default Price;
