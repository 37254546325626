/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";


// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";

import Checkbox from '@mui/material/Checkbox';

// ICONS:
import SearchIcon from '@mui/icons-material/Search';
import BedIcon from '@mui/icons-material/Bed';
import PeopleIcon from '@mui/icons-material/People';
import DateRangeIcon from '@mui/icons-material/DateRange';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import EuroIcon from '@mui/icons-material/Euro';
import CheckIcon from '@mui/icons-material/Check';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmailIcon from '@mui/icons-material/Email';
import EventIcon from '@mui/icons-material/Event';
import PhoneIcon from '@mui/icons-material/Phone';
import MoreIcon from '@mui/icons-material/More';
import CampaignIcon from '@mui/icons-material/Campaign';
import DescriptionIcon from '@mui/icons-material/Description';
import NoteAltIcon from '@mui/icons-material/NoteAlt';


function BookingCardAdmin(props) {

  let [newCheckin, setNewCheckin]   = useState(null);
  let [newCheckout, setNewCheckout] = useState(null);
  let [newCost, setNewCost] = useState(null);
  let [newRequests, setNewRequests] = useState(null);
  let [newHotelBill, setNewHotelBill] = useState(null);
  let [newChecked, setNewChecked] = useState(false);

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

 // console.log("BookingCardAdmin:", props);
  
  const handleChecked = () => {
    //  console.log("click ");
      if (newChecked){
          setNewChecked(false);
          props.changeofihotel(false);
      }else{
          setNewChecked(true);
          props.changeofihotel(true);
      }
  }

  const changeCheckin = (value) => {
        setNewCheckin(value);
        props.changecheckin(value);
  }

  const changeCheckout = (value) => {
        setNewCheckout(value);
        props.changecheckout(value);
  }

  const changeCost = (value) => {
        setNewCost(value);
        props.changecost(value);
  }

  const changeRequests = (value) => {
        setNewRequests(value);
        props.changerequests(value);
  }

  const changeHotelBill = (value) => {
        setNewHotelBill(value);
        props.changehotelbill(value);
  }

  return (
    <MKBox
      variant= "gradient"
      bgColor={props.color}
      borderRadius="xl"
      shadow={props.color === "transparent" ? "none" : "md"}
      p={3}
    >

    <MKBox lineHeight={1}>
       <MKTypography
          display="block"
          variant= "h4"
          fontWeight="bold"
          color={(props.booking.canceled)?("error"):("success")}
          mb={0.5}
        >
         {(props.booking.canceled)?(
                  <u>Reserva Cancelada &nbsp; {props.booking.confirmationNumber} </u>
          ):(
                  <u>Reserva &nbsp; BESTHOTELS &nbsp; {props.booking.confirmationNumber} </u>
          )} 

        </MKTypography>

        <MKTypography
          variant= "h5"
          color='dark'
          my={4}

        >
            {props.booking.firstName} &nbsp; {props.booking.lastName}
        </MKTypography>
        <MKTypography
         variant="body2"
        color={props.color === "transparent" || props.color === "light" ? "text" : "white"}
        my={4}
        >

          <EventIcon variant="filled"  fontSize="medium"/> <b> &nbsp; Entrada</b> &nbsp; <MKInput type="text" label={props.booking.checkin} onChange={(e)=> changeCheckin(e.target.value)} />
        </MKTypography>
        <MKTypography
        variant="body2"
        color={props.color === "transparent" || props.color === "light" ? "text" : "white"}
        my={4}
        >
          <EventIcon variant="filled"  fontSize="medium"/> <b>  &nbsp; Salida</b>  &nbsp; <MKInput type="text" label={props.booking.checkout} onChange={(e)=> changeCheckout(e.target.value)} />
        </MKTypography>
     
      <MKTypography
        variant="body2"
        color={props.color === "transparent" || props.color === "light" ? "text" : "white"}
        my={4}
      >
        <BedIcon variant="filled"  fontSize="medium"/> &nbsp; {props.booking.room}
      </MKTypography>

      <MKTypography
        variant="body2"
        color={props.color === "transparent" || props.color === "light" ? "text" : "white"}
        my={4}
      >
        <PeopleIcon variant="filled"  fontSize="medium"/> &nbsp; {props.booking.people}
      </MKTypography>

       <MKTypography
        variant="body2"
        color={props.color === "transparent" || props.color === "light" ? "text" : "white"}
        my={4}
      >
        <RestaurantIcon variant="filled"  fontSize="medium"/> &nbsp; {props.booking.regime}
      </MKTypography>

       <MKTypography
        variant="body2"
        color={props.color === "transparent" || props.color === "light" ? "text" : "white"}
        my={4}
      >
        <EmailIcon variant="filled"  fontSize="medium"/> &nbsp; {props.booking.email}
      </MKTypography>

       <MKTypography
        variant="body2"
        color={props.color === "transparent" || props.color === "light" ? "text" : "white"}
        my={4}
      >
        <PhoneIcon variant="filled"  fontSize="medium"/> &nbsp; {props.booking.phone}
      </MKTypography>

      <MKTypography
        variant="body2"
        color={props.color === "transparent" || props.color === "light" ? "text" : "white"}
        my={4}
      >
        <EuroIcon variant="filled"  fontSize="medium"/> &nbsp;  <MKInput type="text" label={props.booking.cost} onChange={(e)=> changeCost(e.target.value)} />
      </MKTypography>

      <MKTypography
        variant="body2"
        color={props.color === "transparent" || props.color === "light" ? "text" : "white"}
        my={4}
      >
        <EuroIcon variant="filled"  fontSize="medium"/> Cost per Day &nbsp;  {props.bookingcostperday} 
      </MKTypography>


      {(props.booking.couponSavings>0)&&
      <MKTypography
        variant="body2"
        color={props.color === "transparent" || props.color === "light" ? "text" : "white"}
        my={4}
      >
        <MoreIcon variant="filled"  fontSize="medium"/>  &nbsp; Coupon &nbsp; {props.booking.coupon}
      </MKTypography>
      }


      <MKTypography
        variant="body2"
        color={props.color === "transparent" || props.color === "light" ? "text" : "white"}
        my={4}
      >
        <EventIcon  variant="filled"  fontSize="medium"/> &nbsp;  Dia Reservado &nbsp; {props.booking.dateBooking}
      </MKTypography>

      {(props.booking.canceled==true)&&
        <MKTypography
        variant="body2"
        color={props.color === "transparent" || props.color === "light" ? "text" : "white"}
        my={4}
      >
        <EventIcon  variant="filled"  fontSize="medium"/>  &nbsp; Dia Cancelado &nbsp; {props.booking.dateCanceled}
      </MKTypography>
      }

      {(props.booking.requests!=null)&&
        <MKTypography
        variant="body2"
        color={props.color === "transparent" || props.color === "light" ? "text" : "white"}
        my={4}
      >
      
        <NoteAltIcon variant="filled"  fontSize="medium"/> &nbsp;   {props.booking.requests} 
      </MKTypography>
      }

      <MKTypography
        variant="body2"
        color={props.color === "transparent" || props.color === "light" ? "text" : "white"}
        my={4}
      >
        <NoteAltIcon variant="filled"  fontSize="medium"/> &nbsp;  <MKInput type="text"  label='Nuevas Peticiones' multiline rows={5} onChange={(e)=> changeRequests(e.target.value)} />
      </MKTypography>
      
        <MKTypography
        variant="body2"
        color={props.color === "transparent" || props.color === "light" ? "text" : "white"}
        my={4}
        >
        <DescriptionIcon  variant="filled"  fontSize="medium"/>  &nbsp; Factura &nbsp; <MKInput type="text" label={props.booking.hotelBill} onChange={(e)=> changeHotelBill(e.target.value)} />
      </MKTypography>

    {(props.booking.transferOfiHotel==false)&&
       <MKTypography
        variant="body2"
        color="info"
        my={4}
        >
     <Checkbox
        size="large"
        onClick={handleChecked}
      /> 
      &nbsp; Click si esta pasado a OfiHotel
      </MKTypography>
    }
     
    </MKBox>
    
    </MKBox>
  );
}

export default BookingCardAdmin;
