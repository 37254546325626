/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useState } from "react";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKDatePicker from "components/MKDatePicker";
import MKAlert from "components/MKAlert";
import MKInput from "components/MKInput";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/admin";
import Footer from "examples/Footers/CenteredFooter";
import BookingCardAdmin from "examples/Cards/BookingCardAdmin";

import StickyHeadTable from "./sections/Table"

// Images
//import bgImage from "assets/images/shapes/waves-white.svg";
const bgImage= process.env.REACT_APP_PUBLIC_URL+"imgs/"+process.env.REACT_APP_HOTELSANCHO_HEADER;

// DATABASE LIBS
const { convertDate, getMonth, getDay, getAvailability, getPrices, changeAvailabilityRoom} = require('../../../libs/availability')
const {getPredictionAdmin, findBookingBillAdmin, findBookingAdmin,  cancelBookingbyConfirmationNumber, saveBookingAdmin} = require('../../../libs/bookings')
const {  getAuth, handleAuthentication } = require('../../../libs/authentication');


// ICONS:
import SearchIcon from '@mui/icons-material/Search';
import BedIcon from '@mui/icons-material/Bed';
import PeopleIcon from '@mui/icons-material/People';
import DateRangeIcon from '@mui/icons-material/DateRange';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import EuroIcon from '@mui/icons-material/Euro';
import CheckIcon from '@mui/icons-material/Check';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import HomeIcon from '@mui/icons-material/Home';
import SaveIcon from '@mui/icons-material/Save';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import CircleIcon from '@mui/icons-material/Circle';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

//Scrool to different sections of the page 
  const PageRef0 = null;
  const PageRef1 = null;
  const PageRef2 = null;
  const PageRef3 = null;
  const PageRef4 = null;
  const PageRef5 = null;



function Bills() {

  // console.log("Bills getAuth:", getAuth());

 let [isAuthenticated, setIsAuthenticated]= useState(getAuth());
 let [searchingBooking, setSearchingBooking] = useState(false);
 let [menuSearchBooking, setMenuSearchBooking] = useState(true);
 let [showBooking, setShowBooking] = useState(false);
 let [errorConfirmationNumber, setErrorConfirmationNumber]= useState(false);
 let [cancelingBooking, setCancelingBooking] = useState(false);
 let [savingBooking, setSavingBooking] = useState(false);
 let [doneSavingBooking, setDoneSavingBooking] = useState(false);
 let [showAskCancelBooking, setShowAskCancelBooking] = useState(false);
 let [showBookingList,setShowBookingList]=useState(false);
 let [errorDates,setErrorDates]=useState(false);

 let [bookingRoom, setBookingRoom] = useState(null);
 let [bookingYear, setBookingYear] = useState(null);
 let [bookingRoomName, setBookingRoomName] = useState(null);
 let [bookingCost, setBookingCost] = useState(null);
 let [bookingMeal, setBookingMeal] = useState(null);
 let [bookingNumber, setBookingNumber] = useState(null);
 let [bookingDate, setBookingDate] = useState(null);
 let [bookingEmail, setBookingEmail] = useState(null);
 let [bookingFirstName, setBookingFirstName] = useState(null);
 let [bookingLastName, setBookingLastName] = useState(null);
 let [bookingPhone, setBookingPhone] = useState(null);
 let [bookingCoupon, setBookingCoupon] = useState(null);
 let [bookingCouponSavings, setBookingCouponSavings] = useState(null);
 let [bookingCheckin, setBookingCheckin] = useState(null);
 let [bookingCheckout, setBookingCheckout] = useState(null);
 let [bookingName, setBookingName] = useState(null);
 let [bookingCanceled, setBookingCanceled] = useState(null);
 let [bookingDateCanceled, setBookingDateCanceled] = useState(null);
 let [bookingTransferOfiHotel, setBookingTransferOfiHotel] = useState(null);
 let [bookingRequests, setBookingRequests] = useState(null);
 let [bookingHotelBill, setBookingHotelBill] = useState(null);
 let [bookingPeople, setBookingPeople] = useState(null);
 let [validEmail, setValidEmail] = useState(null);
 let [errorBooking, setErrorBooking] = useState(null);

 let [checkin, setCheckin] = useState(null);
 let [checkout, setCheckout] = useState(null);
 let [listBookings, setListBookings] = useState([]);
 let [countListBookings, setCountListBookings] = useState(0);
 let [listBookingsComplete, setListBookingsComplete] = useState([]);
 let [bookingComplete, setBookingComplete] = useState([]);

 let [totalBill, setTotalBill] = useState(0);
 let [countBill, setCountBill] = useState(0);
 let [totalFee, setTotalFee] = useState(0);

const maxDateBooking=process.env.REACT_APP_MAXDATE;
const minDateBooking=process.env.REACT_APP_MINDATE;

let [showPrediction, setShowPrediction] = useState(false);

const fee=Number(process.env.REACT_APP_FEE);

const reset= () => {
                          setDoneSavingBooking(false);
                          setSearchingBooking(false);
                          setShowBooking(false);
                          setShowBookingList(false);
                          setShowAskCancelBooking(false);
                          setShowBookingList(false);
                          setMenuSearchBooking(true);
                          setShowPrediction(false);
                          setSearchingBooking(false);
};

const selectedCheckin = (date) => {
        console.log('selected Checkin:', date);
        setCheckin(convertDate(date));
}
const selectedCheckout = (date) => {
        setCheckout(convertDate(date));
}

  const addTableItem = (item) => {
    setListBookings(prevItems => [...prevItems, 
      item
    ]);
  }

  const addItem = (item) => {
    setBookingComplete(item);
  }

  const addListBookingComplete = (item) => {
    setListBookingsComplete(prevItems => [...prevItems, 
      item
    ]);
  }

// Data expected for the TABLE
function createData(icon, index, bookingNumber,bookingFirstName,bookingLastName,bookingCheckin,bookingCheckout,bookingRoomName, bookingMeal) {
  return { icon, index, bookingNumber, bookingFirstName,bookingLastName,bookingCheckin,bookingCheckout,bookingRoomName, bookingMeal};
}

const rows = [
  createData('0','123', 'Joe', 'Smith', '2-8-2022','2-8-2022', 'Doble dos camas', 'Desayuno', '202' ),
 
];

const refreshPage = ()=>{
     window.location.reload();
}

const changeOfihotel= (value) => {
            console.log('change Ofihotel', value);
            setBookingTransferOfiHotel(value);
}

const changeCheckin = (date) => {
            console.log('change checking', date);
            setBookingCheckin(date);
}

const changeCheckout = (date) => {
            console.log('change checking', date);
            setBookingCheckout(date);
}

const changeRequests = (value) => {
            console.log('change requests', value);
            setBookingRequests(value);
}

const changeCost = (value) => {
            console.log('change cost', value);
            setBookingCost(value);

}

const changeHotelBill = (value) => {
            console.log('change Hotel Bill', value);
            setBookingHotelBill(value);
}

const saveBooking = () => {
            console.log('Saving Booking ...');
            setSavingBooking(true);
            setShowBooking(false);
            var Data = {
                bookingYear:bookingYear,
                confirmationNumber:Number(bookingNumber),
                firstName: bookingFirstName,
                lastName: bookingLastName,
                checkin:bookingCheckin,
                checkout:bookingCheckout,
                people:bookingPeople,
                room:bookingRoom,
                regime:bookingMeal,
                phone:bookingPhone,
                email:bookingEmail,
                couponSavings:bookingCouponSavings,
                coupon:bookingCoupon,
                cost:bookingCost,
                transferOfiHotel:bookingTransferOfiHotel,
                canceled:bookingCanceled,
                dateCanceled:bookingDateCanceled,
                dateBooking:bookingDate,
                requests: bookingRequests,
                hotelBill:bookingHotelBill
            };

            saveBookingAdmin(doneSaveBooking, Data);
}

const doneSaveBooking = () => {
            console.log('Done Saving Booking ...');
            setSavingBooking(false);
            setDoneSavingBooking(true);
            setShowBookingList(false);
            setListBookingsComplete({});
            refreshPage();
}

const selectedBooking = (index) => {
                          console.log("Show booking:", index);
                          setShowBookingList(false);
                          setDoneSavingBooking(false);
                          setShowBooking(true);
                          setBookingNumber(index);
                          console.log("Selected Complete Booking: ", listBookingsComplete[index]);
                        
                          addItem(listBookingsComplete[index]);

                          setBookingNumber(listBookingsComplete[index].confirmationNumber);
                          setBookingDate(listBookingsComplete[index].dateBooking);
                          setBookingYear(listBookingsComplete[index].bookingYear);
                          setBookingCheckin(listBookingsComplete[index].checkin);
                          setBookingCheckout(listBookingsComplete[index].checkout);
                          setBookingLastName(listBookingsComplete[index].lastName);
                          setBookingFirstName(listBookingsComplete[index].firstName);
                          let value=listBookingsComplete[index].firstName + " " + listBookingsComplete[index].lastName;
                          setBookingName(value);
                          setBookingRoom(listBookingsComplete[index].room);
                          setBookingPeople(listBookingsComplete[index].people);
                          setBookingMeal(listBookingsComplete[index].regime);
                          setBookingPhone(listBookingsComplete[index].phone);
                          setBookingEmail(listBookingsComplete[index].email);
                          setBookingCost(listBookingsComplete[index].cost);
                          setBookingCoupon(listBookingsComplete[index].coupon);
                          setBookingCouponSavings(listBookingsComplete[index].couponSavings);
                          setBookingCanceled(listBookingsComplete[index].canceled);
                          setBookingDateCanceled(listBookingsComplete[index].dateCanceled);
                          setBookingTransferOfiHotel(listBookingsComplete[index].transferOfiHotel);
                          setBookingRequests(listBookingsComplete[index].requests);
                          setBookingHotelBill(listBookingsComplete[index].hotelBill);
};
 

const doneSearchingBookings = (listDB, count, total) => {
                          console.log('Done Searching Booking', count, total);
                          setDoneSavingBooking(false);
                          setSearchingBooking(false);
                          setShowBookingList(true);
                          setListBookingsComplete([]);
                          let n=0;
                          listDB.forEach((item, index) => {
                           // console.log( " adding booking list: ", `${index} : ${item.confirmationNumber} `);
                            if (item.canceled){
                            addTableItem(createData(
                                 <CircleIcon color="error"/>,
                                 n,
                                 item.confirmationNumber,
                                 item.firstName,
                                 item.lastName,
                                 item.checkin,
                                 item.checkout,
                                 item.room,
                                 item.regime
                                  ));
                            }else{
                              addTableItem(createData(
                                 <CircleIcon color="success"/>,
                                 n,
                                 item.confirmationNumber,
                                 item.firstName,
                                 item.lastName,
                                 item.checkin,
                                 item.checkout,
                                 item.room,
                                 item.regime
                                  ));
                            }

                            addListBookingComplete(item);
                            n=n+1;

                            });

                            console.log("Bills ", total,  Math.trunc(total));
                            setTotalBill(Math.trunc(total))
                            ;
                            setCountBill(count);
                            setCountListBookings(n);
                            let c=(total*fee)/100;
                            setTotalFee(Math.trunc(c))

};

const searchingBookings = () => {

                          if (checkin==null || checkout==null){
                              setErrorDates(true);
                          }else{
                                console.log("Searching bookings:", checkin,  checkout );
                                setListBookingsComplete([]);
                                setSearchingBooking(true);
                                setErrorDates(false);
                                setDoneSavingBooking(false);
                                setShowBookingList(false);
                                //const value='38296994';
                                
                                setListBookings([]);
                                setShowBookingList(false);
                                findBookingBillAdmin(doneSearchingBookings, checkin, checkout);
                              }
};

const doneGetPrediction = (count, total) => {
                            reset();
                            console.log("Bills ", total,  Math.trunc(total));
                            setTotalBill(Math.trunc(total));
                            setCountBill(count);
                            let c=(total*fee)/100;
                            setTotalFee(Math.trunc(c));
                            setShowPrediction(true);

};


const getPrediction = () => {

                                console.log("Searching bookings:", checkin,  checkout );
                                setListBookingsComplete([]);
                                setSearchingBooking(true);
                                setErrorDates(false);
                                setDoneSavingBooking(false);
                                setShowBookingList(false);
                                setListBookings([]);
                                setShowBookingList(false);
                                getPredictionAdmin(doneGetPrediction);
};



const backViewBooking= () => {
                          setDoneSavingBooking(false);
                          setSearchingBooking(false);
                          setShowBooking(false);
                          setShowBookingList(true);
                          setShowAskCancelBooking(false);
                          setShowBookingList(true);
                          setMenuSearchBooking(true);
};



  var t=new Date();  
  var m=t.getMonth()+1;
  var today=t.getFullYear()+"-"+m+"-"+t.getDate();

  return (
    <>
      <DefaultNavbar 
        brand= "Best Hotels Cheap"
        routes={routes} 
        transparent={false}
        light={false}
        sticky={false} 
        relative={false} 
        center={false} 
        pageref0={PageRef0}
        pageref1={PageRef1}
        pageref2={PageRef2}
        pageref3={PageRef3}
        pageref4={PageRef4}
        pageref5={PageRef5}
        landingpage="admin"
        isauthenticated={getAuth()}
        setDone={handleAuthentication}
      />
      
      {(getAuth())&&<>
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.1),
              rgba(gradients.dark.state, 0.1)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Listados de Facturas 
            </MKTypography>
             <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
                  
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>


      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

     <MKBox component="section" pt={3} pb={8}>
       <Container>

     
        {(savingBooking)&&
          <MKAlert justifyContent="center"  color="light" > <HourglassTopIcon variant="filled"  fontSize="medium"/>&nbsp; Por favor, espere a que se guarden los cambios ...  </MKAlert>
        }

        {(doneSavingBooking)&&
          <MKAlert justifyContent="center"  color="success" > <SentimentSatisfiedAltIcon color='light' variant="filled"  fontSize="medium"/>&nbsp; Reserva  guardada con exito!  </MKAlert>
        }

        {(searchingBooking)&&
          <MKAlert justifyContent="center"  color="light" > <HourglassTopIcon variant="filled"  fontSize="medium"/>&nbsp; Por favor, espere a que obtengan las reservas ...  </MKAlert>
        }

        {(showBookingList && countListBookings==0)&&
            <MKAlert justifyContent="center"  color="light" > <SentimentDissatisfiedIcon color='info' variant="filled"  fontSize="medium"/>&nbsp; No existen reservas  ...  </MKAlert>
        }

        {(errorDates)&&
          <MKAlert justifyContent="center"  color="error" > <SentimentDissatisfiedIcon/>&nbsp; Por favor, selecione el rango de fechas  </MKAlert>
        }

        {(menuSearchBooking)&&
           <MKBox component="section" pt={3} pb={8}>
           <Container>
       
          <Grid container justifyContent="center" sx={{ textAlign: "center", pt:5}}>
           <Grid item xs={12} md={3} lg={2}>
             <MKDatePicker  setcheckin={selectedCheckin} setcheckout={selectedCheckout}  mindate={minDateBooking} maxdate={maxDateBooking} />
            </Grid>
           <Grid item xs={12} md={3} lg={2}>
             <MKButton variant="gradient" color="info" onClick={searchingBookings}>
                    <SearchIcon/>&nbsp;
                    Buscar
            </MKButton>
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
             <MKButton variant="gradient" color="warning" onClick={getPrediction}>
                    <OnlinePredictionIcon  />&nbsp;
                    Prediccion
            </MKButton>
          </Grid>
        </Grid>
         </Container>
          </MKBox>
       }

        {(showPrediction==true)&&
         
           <MKBox component="section" pt={3} pb={8}>
           <Grid container justifyContent="center" sx={{ textAlign: "center", pt:5}}>
            <Grid item xs={12} md={3} lg={6} >
            <MKTypography
              variant="h5"
              color="info"
              >
              Total Facturado &nbsp; {totalBill} &nbsp; <EuroIcon variant="filled"  fontSize="small"/>
            </MKTypography>
            </Grid>
            <Grid item xs={12} md={3} lg={6} >
            <MKTypography
              variant="h5"
              color="success"
              >
              Total Comisionado &nbsp; {totalFee} &nbsp; <EuroIcon variant="filled"  fontSize="small"/>
            </MKTypography>
            </Grid>
            </Grid>
            <Grid container justifyContent="center" sx={{ textAlign: "center", pt:5}}>
            <MKTypography
              variant="h4"
              color="dark"
              >
              Numero de reservas {countBill}
            </MKTypography>
            </Grid>
             <Grid container justifyContent="center" sx={{ textAlign: "center" }} pt={3} >
            
            <Grid item xs={12} md={3} lg={2}  >
                <MKButton variant="gradient" color="info" onClick={reset}>
                    <ArrowBackIcon/>&nbsp;
                    Volver
                </MKButton>
            </Grid>
            </Grid>
             
            </MKBox>
        }



        {(showBookingList && countListBookings>0)&&
         
           <MKBox component="section" pt={3} pb={8}>
           <Grid container justifyContent="center" sx={{ textAlign: "center", pt:5}}>
            <Grid item xs={12} md={3} lg={6} >
            <MKTypography
              variant="h5"
              color="info"
              >
              Total Facturado &nbsp; {totalBill} &nbsp; <EuroIcon variant="filled"  fontSize="small"/>
            </MKTypography>
            </Grid>
            <Grid item xs={12} md={3} lg={6} >
            <MKTypography
              variant="h5"
              color="success"
              >
              Total Comisionado &nbsp; {totalFee} &nbsp; <EuroIcon variant="filled"  fontSize="small"/>
            </MKTypography>
            </Grid>
            </Grid>
            <Grid container justifyContent="center" sx={{ textAlign: "center", pt:5}}>
            <MKTypography
              variant="h4"
              color="dark"
              >
              Listados de Reservas 
            </MKTypography>
            </Grid>
            <Grid container justifyContent="center" sx={{ textAlign: "center", mt:5}}>
            <StickyHeadTable
              showbooking={selectedBooking}
              list={listBookings}
            />
            </Grid>
            </MKBox>
        }

         {(showBooking)&&
          <MKBox component="section" pt={3} pb={8}>
            <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
              <Grid item xs={12} md={3} lg={6} >
              <BookingCardAdmin 
                booking={bookingComplete}
                changecheckin={changeCheckin}
                changecheckout={changeCheckout}
                changerequests={changeRequests}
                changecost={changeCost}
                changehotelbill={changeHotelBill}
                changeofihotel={changeOfihotel}
                color="light"
              />
              </Grid>
            </Grid>

             <Grid container justifyContent="center" sx={{ textAlign: "center" }} pt={3} >
            
            <Grid item xs={12} md={3} lg={2}  >
                <MKButton variant="gradient" color="info" onClick={backViewBooking}>
                    <ArrowBackIcon/>&nbsp;
                    Volver
                </MKButton>
            </Grid>
             
                <Grid item xs={12} md={3} lg={2} >
                <MKButton variant="gradient" color="info" onClick={saveBooking}>
                    <SaveIcon/>&nbsp;
                     Guardar
                </MKButton>
              </Grid>
             </Grid>
          </MKBox>
         }

        </Container>
        </MKBox>
      </Card>

</>}

      <MKBox pt={6} px={1} mt={6}>
         <Footer company={footerRoutes.company} links={footerRoutes.links} socials={footerRoutes.socials} light={footerRoutes.light}  />
      </MKBox>
    </>
  );
}

export default Bills;
