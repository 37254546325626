/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import MKButton from "components/MKButton";

import BedIcon from '@mui/icons-material/Bed';
import PeopleIcon from '@mui/icons-material/People';
import BathtubIcon from '@mui/icons-material/Bathtub';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TwitterIcon from "@mui/icons-material/Twitter";


function RoomCard(props) {

const BookingOnlyRoom = () => {
    props.initbooking(props.room, props.name, props.priceRoom, process.env.REACT_APP_REGIME_HAB);
};

const BookingBreakfast = () => {
    props.initbooking(props.room, props.name, props.priceBreakfast, process.env.REACT_APP_REGIME_BREAKFAST);
};

const BookingHalfboard = () => {
    props.initbooking(props.room, props.name, props.priceHalfboard, process.env.REACT_APP_REGIME_HALFBOARD);
};

const BookingFullboard = () => {
    props.initbooking(props.room, props.name, props.priceFullboard, process.env.REACT_APP_REGIME_FULLBOARD);
};


  return (
    <Card sx={{ mt: 6}}  >
      <Grid container >
        <Grid item xs={12} md={6} lg={4} sx={{ mt: -6 }}>
          <MKBox width="100%" pt={2} pb={1} px={2} >
            <MKBox
              component="img"
              src={props.image}
              alt={props.name}
              width="50%"
              borderRadius="md"
              shadow="lg"
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} md={6} lg={8} sx={{ my: "auto", textAlign: "left" }} justifyContent="left" >
          <MKBox pt={{ xs: 0, lg: 0 }} pb={2.5} pr={0} pl={{ xs: 0, lg: 0 }} lineHeight={1} >
            <MKTypography variant="h5">{props.name}</MKTypography>
            
            <MKTypography variant="body2" color="text">
              <BedIcon variant="filled"  fontSize="medium"/>&nbsp;{props.bed} &nbsp; &nbsp; 
              <PeopleIcon variant="filled"  fontSize="medium"/>&nbsp;{props.people}  &nbsp; &nbsp; 
              <BathtubIcon variant="filled"  fontSize="medium"/> &nbsp; 1 bano privado
            </MKTypography>

            <MKTypography variant="body2" color="text">
              {props.description}
            </MKTypography>
          </MKBox>


    {(props.priceRoom!="0")&&
              <MKBox
                color="black"
                bgColor="light"
                variant="gradient"
                borderRadius="lg"
                shadow="lg"
                opacity={1}
                p={2}
                pt={3}
                mt={4} 
                mb={4}
                  >
            <Grid item xs={12} md={6} lg={8} sx={{ my: "auto", textAlign: "left" }} justifyContent="left" >
                  <MKTypography variant="body2" color="text">
                    <b>Solo Alojamiento</b>. Precio para {props.ndays} noches &nbsp;
                  </MKTypography>
                  <MKTypography variant="h5" color="dark">
                   {props.priceRoom} euros
                  </MKTypography>
                  <MKTypography variant="body2" sx={{ color: "#27AE60" , pb:1}}>
                    IVA incluido. Reserve ahora y page en el Hotel
                  </MKTypography>
            </Grid>
             <Grid item xs={12} md={6} lg={8} sx={{ my: "auto", textAlign: "left" }} justifyContent="left" >
              </Grid>
             <Grid item xs={12} md={6} lg={12} sx={{ my: "auto",mt: "-44pt", textAlign: "right" }}  >
              <MKButton onClick={BookingOnlyRoom} variant="gradient" color="info"   sx={{ my: "auto", textAlign: "right" }} >
                    <CheckCircleOutlineIcon/>&nbsp;
                    Reservar
              </MKButton>
              </Grid>
            </MKBox>
           }  


    {(props.priceBreakfast!="0")&&
              <MKBox
                color="black"
                bgColor="light"
                variant="gradient"
                borderRadius="lg"
                shadow="lg"
                opacity={1}
                p={2}
                pt={3}
                mt={4} 
                mb={4}
                  >
            <Grid item xs={12} md={6} lg={8} sx={{ my: "auto", textAlign: "left" }} justifyContent="left" >
                  <MKTypography variant="body2" color="text">
                    <b>Alojamiento y desayuno</b>. Precio para {props.ndays} noches &nbsp;
                  </MKTypography>
                  <MKTypography variant="h5" color="dark">
                   {props.priceBreakfast} euros
                  </MKTypography>
                  <MKTypography variant="body2" sx={{ color: "#27AE60" , pb:1}}>
                    IVA incluido. Reserve ahora y page en el Hotel
                  </MKTypography>
            </Grid>
             <Grid item xs={12} md={6} lg={8} sx={{ my: "auto", textAlign: "left" }} justifyContent="left" >
              </Grid>
             <Grid item xs={12} md={6} lg={12} sx={{ my: "auto",mt: "-44pt", textAlign: "right" }}  >
              <MKButton onClick={BookingBreakfast} variant="gradient" color="info"   sx={{ my: "auto", textAlign: "right" }} >
                    <CheckCircleOutlineIcon/>&nbsp;
                    Reservar
              </MKButton>
              </Grid>
            </MKBox>
           }  


    {(props.priceHalfboard!="0")&&
              <MKBox
                color="black"
                bgColor="light"
                variant="gradient"
                borderRadius="lg"
                shadow="lg"
                opacity={1}
                p={2}
                pt={3}
                mt={4} 
                mb={4}
                  >
            <Grid item xs={12} md={6} lg={8} sx={{ my: "auto", textAlign: "left" }} justifyContent="left" >
                  <MKTypography variant="body2" color="text">
                    <b>Media Pension</b>. Precio para {props.ndays} noches &nbsp;
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    <b> {process.env.REACT_APP_MSG_PENSION} </b> 
                  </MKTypography>
                  <MKTypography variant="h5" color="dark">
                   {props.priceHalfboard} euros
                  </MKTypography>
                  <MKTypography variant="body2" sx={{ color: "#27AE60" , pb:1}}>
                    IVA incluido. Reserve ahora y page en el Hotel
                  </MKTypography>
            </Grid>
             <Grid item xs={12} md={6} lg={8} sx={{ my: "auto", textAlign: "left" }} justifyContent="left" >
              </Grid>
             <Grid item xs={12} md={6} lg={12} sx={{ my: "auto",mt: "-44pt", textAlign: "right" }}  >
              <MKButton onClick={BookingHalfboard} variant="gradient" color="info"   sx={{ my: "auto", textAlign: "right" }} >
                    <CheckCircleOutlineIcon/>&nbsp;
                    Reservar
              </MKButton>
              </Grid>
            </MKBox>
           }  


    {(props.priceFullboard!="0")&&
              <MKBox
                color="black"
                bgColor="light"
                variant="gradient"
                borderRadius="lg"
                shadow="lg"
                opacity={1}
                p={2}
                pt={3}
                mt={4} 
                mb={4}
                  >
            <Grid item xs={12} md={6} lg={8} sx={{  my: "auto", textAlign: "left" }} justifyContent="left" >
                  <MKTypography variant="body2" color="text">
                    <b>Pension Completa</b>. Precio para {props.ndays} noches &nbsp;
                  </MKTypography>
                  <MKTypography variant="h5" color="dark">
                   {props.priceFullboard} euros
                  </MKTypography>
                  <MKTypography variant="body2" sx={{ color: "#27AE60" , pb:1}}>
                    IVA incluido. Reserve ahora y page en el Hotel
                  </MKTypography>
            </Grid>
             <Grid item xs={12} md={6} lg={8} sx={{ my: "auto", textAlign: "left" }} justifyContent="left" >
              </Grid>
             <Grid item xs={12} md={6} lg={12} sx={{ my: "auto",mt: "-44pt", textAlign: "right" }}  >
              <MKButton onClick={BookingFullboard} variant="gradient" color="info"   sx={{ my: "auto", textAlign: "right" }} >
                    <CheckCircleOutlineIcon/>&nbsp;
                    Reservar
              </MKButton>
              </Grid>
            </MKBox>
           }  


        </Grid>
      </Grid>
    </Card>
  );
}

// Typechecking props for the HorizontalTeamCard


export default RoomCard;
