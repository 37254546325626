/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// ETH
import { useState, useEffect} from 'react';
import { useRef} from 'react';

//AUTHO
import { useAuth0 } from '@auth0/auth0-react';

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/main";

import Footer from "examples/Footers/CenteredFooter";


// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
const bgImage= process.env.REACT_APP_PUBLIC_URL+"imgs/"+process.env.REACT_APP_MAIN_BACKGROUND;


import Icon from "@mui/material/Icon";



function Presentation() {
  
 const [backgroundDark, setbackgroundDark] = useState(true)
  

// Scrool to different sections of the page 
  const PageRef0 = useRef(null);
  const PageRef1 = useRef(null);
  const PageRef2 = useRef(null);
  const PageRef3 = useRef(null);
  const PageRef4 = useRef(null);
  const PageRef5 = useRef(null);
 
 // console.log(" footer company: ", footerRoutes.company);
 const  {user, isAuthenticated }= useAuth0();

  return (
    <>
    
      <DefaultNavbar 
        brand= "Best Hotels Cheap"
        routes={routes} 
        transparent={true}
        light={true}
        sticky={false} 
        relative={false} 
        center={false} 
        pageref0={PageRef0}
        pageref1={PageRef1}
        pageref2={PageRef2}
        pageref3={PageRef3}
        pageref4={PageRef4}
        pageref5={PageRef5}
        landingpage="presentation"
        action={{
          type: "internal",
          route: "/viewbooking",
          label: "Reservas",
          color: "info",
        }}
      />
     
      <MKBox 
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >

      <Container>
          <Grid container item xs={12} lg={7}   mx="auto" >
            <MKTypography
              variant="h1"
              color="white"
              fontWeight='bold'
              verticalAlign='middle'
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["4xl"],

                },
              })}
            >
             
              Booking Hotel Services 
              
            </MKTypography>
            <MKTypography
              variant="h3"
              color="blue"
              fontWeight='bold'
              verticalAlign='middle'
              align='center'
              mt={1}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
             
             
            </MKTypography>
          </Grid>
        </Container>
        
      </MKBox>
      {/*
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
 
     <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
            <Grid item xs={12} md={3} lg={3}>
                <MKTypography
                variant="h5"
                my={4}
                >
                 

               </MKTypography>
          </Grid>
          </Grid>

      </Card >
      */}

      <MKBox pt={6} px={1} mt={6}   ref={PageRef4}>
        <Footer company={footerRoutes.company} links={footerRoutes.links} socials={footerRoutes.socials} light={footerRoutes.light}  />

      </MKBox>
      
    </>
  );

}

export default Presentation;
