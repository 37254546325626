
// Upload in the database the  url of the image in comparativeHotels.json

var AWS = require('aws-sdk');

var configuration={
    region: 'us-east-1',
    secretAccessKey: 'RfpzwZM3mXnAQbfGPEzeHKFgVyLAM8pQqGhd2kdI',
    accessKeyId: 'AKIA363ORU3S2T6VXL2R'
};

AWS.config.update(configuration);

const docClient = new AWS.DynamoDB.DocumentClient();

/*
const setDone = (url) => {

    console.log("url:", url);

}
*/

const getImageDB = (tableName, setDone,name) => {
    var params = {
        TableName: tableName,
        KeyConditionExpression: 'imgName = :n',
        ExpressionAttributeValues: {
            ':n':name
        }
    };
    let url='';

    docClient.query(params, function (err, data) {
        if (!err) {
            console.log(data);
            data.Items.forEach(function(img) {
                if (img.imgName==name) {
                    setDone(img.url);
                }
            })
        }
        else{
            console.log('Error',err)
        }
    })
 };

export const getImage = async  (setDone, name) => {
    await getImageDB('Images',setDone, name);
}

/////////////////////////////////////
///// MAIN ///////
/////////////////////////////////////

//getImage('comparativeHotel');


