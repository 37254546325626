import { useAuth0 } from '@auth0/auth0-react';
import { useState } from "react";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKDatePicker from "components/MKDatePicker";
import MKAlert from "components/MKAlert";
import MKInput from "components/MKInput";

// ICONS:
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';


export const LogInButton= (props) => {

	//console.log("LogInButton ", props);
	
	 const handleClick = async () => {
       // console.log('LogInButton click...' );
        props.setDone('login');
	}

	return (

			!props.isAuthenticated && (
					 <MKButton variant="gradient" color="info" onClick={()=>handleClick()}>
                    		<LoginIcon variant="filled"  fontSize="medium" />&nbsp;
                    	Sign In
            		</MKButton>

				)
		)
}
