
import {init} from 'emailjs-com';
import emailjs from "emailjs-com";
init(process.env.REACT_APP_EMAILJS_SECRET);


const serviceId  = process.env.REACT_APP_EMAILJS_SERVICEID;
const userId     = process.env.REACT_APP_EMAILJS_USERID;
const templateId = process.env.REACT_APP_EMAILJS_CONFIRMATION;


 const sendConfirmation = async (subject, bookingName, bookingLastName, bookingEmail, checkin, checkout, bookingRoom, bookingMeal, bookingCost, bookingNumber, bookingRestaurant, bookingRequests) => {
  try {
    const response = await emailjs.send(
      serviceId,
      templateId,
      { subject, bookingName, bookingLastName, bookingEmail, checkin, checkout, bookingRoom, bookingMeal, bookingCost, bookingNumber, bookingRestaurant, bookingRequests },
      userId
    );

    if (response.status === 200) {
      console.log("Successfully sent Confirmation message.");
    }
  } catch (error) {
    console.error("Failed to send email. Error: ", error);
  }
};


export default sendConfirmation ;