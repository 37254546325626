
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKDatePicker from "components/MKDatePicker";
import MKAlert from "components/MKAlert";
import MKInput from "components/MKInput";

// ICONS:
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';


export const LogOutButton = (props) => {

	//console.log("LogOutButton ", props);

	 const handleClick = async () => {
     //   console.log('LogOutButton...');
        props.setDone('logout');
	}

	return (
			props.isAuthenticated && (
					 <MKButton variant="gradient" color="info" onClick={()=>handleClick()}>
                    		<LogoutIcon variant="filled"  fontSize="medium" />&nbsp;
                    	Sign Out
            		</MKButton>

				)
		)
}