/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const columns = [
  { id: 'icon',             label: 'Icon', minWidth: 19, align: 'left' },
  { id: 'index',            label: 'Indice', minWidth: 19, align: 'left', format: (value) => value.toFixed(0) },
  { id: 'bookingNumber',    label: 'Confirmacion', minWidth: 20, align: 'left', format: (value) => value.toFixed(0) },
  { id: 'bookingFirstName', label: 'Nombre', minWidth: 20, align: 'left' },
  { id: 'bookingLastName',  label: 'Apellidos',minWidth: 20, align: 'left'},
  { id: 'bookingCheckin',   label: 'Entrada',minWidth: 20, align: 'left'},
  { id: 'bookingCheckout',  label: 'Salida',minWidth: 20, align: 'left'},
  { id: 'bookingRoomName',  label: 'Hab',minWidth: 20, align: 'left'},
  { id: 'bookingMeal',      label: 'Regimen',minWidth: 20, align: 'left'}
  ];

const rows_per_page=process.env.REACT_APP_ROWS_PER_PAGE;
const rows_per_page2X=2*rows_per_page;
const rows_per_page4X=4*rows_per_page;
const table_size=process.env.REACT_APP_TABLE_WIDTH;


function StickyHeadTable(props) {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rows_per_page);

  const selectBooking = (event, name) => {
    props.showbooking(name);
  };

 //console.log("Child List received:", props.list);
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

// Change background in TableRow:  style={{backgroundColor:'#FFEBEE'}}
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: table_size }}>
        <Table stickyHeader aria-label="sticky table">
          <TableBody>
            {props.list
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow  hover role="checkbox" tabIndex={-1} key={row.bookingNumber} onClick={(event) => selectBooking(event, row.index)}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell color="success"  key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[rows_per_page2X, rows_per_page2X, rows_per_page4X]}
        component="div"
        count={props.list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}


export default StickyHeadTable;
