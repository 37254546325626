/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

import { Auth0Provider } from '@auth0/auth0-react';
const domain= process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

import * as AWS from 'aws-sdk'
import { ConfigurationOptions } from 'aws-sdk'

var configuration={
    region: process.env.REACT_APP_AWS_REGION,
    secretAccessKey: process.env.REACT_APP_AWS_KEY,
    accessKeyId: process.env.REACT_APP_AWS_SECRET
};


AWS.config.update(configuration)

ReactDOM.render(
  <BrowserRouter>
   <>
    <App />
  </>
  </BrowserRouter>,
  document.getElementById("root")
);
