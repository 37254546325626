
//LOGIN
import { sha256 } from 'crypto-hash';
import { useState } from "react";

export const checkCredentials= async (email,password, setDone) => {
        let check_password, check_email, result;

        let credentials=email+password;

        if (email==null || password==null){
            result=false;
            setDone(false);
        }else{
                await sha256(credentials).then(hash => {

                //   SETUP NEW CREDENTIALS: 

                 //  console.log(" hash credentials Calculate:", hash);

                    if (hash==process.env.REACT_APP_CREDENTIALS_ADMIN){
                         sessionStorage.setItem("isAuthenticated", true);
                         setDone(true);
                         result=true;
                    }else{
                         sessionStorage.setItem("isAuthenticated", false);
                         result=false;
                         setDone(false);
                    };
                });
        }

        return result;
}

export const setAuth= (value) => {
        sessionStorage.setItem("isAuthenticated", value);
}

export const getAuth= () => {
        let result;
        if (sessionStorage.getItem("isAuthenticated")=='true'){
            result=true;
        }else{
            result=false;
        }
        return result;
}

export const getAuthError= () => {
        let result;
        if (sessionStorage.getItem("AuthenticatedShowError")=='true'){
            result=true;
        }else{
            result=false;
        }
        return result;
}

export const getAuthLogin= () => {
        let result;
        if (sessionStorage.getItem("AuthenticatedShowLogin")=='true'){
            result=true;
        }else{
            result=false;
        }
        return result;
}

export const  handleAuthentication = (action) => {
                    //  console.log(" handleAuthentication: ", action);
                      if (action=='login'){
                               sessionStorage.setItem("AuthenticatedShowLogin", true); 
                               sessionStorage.setItem("isAuthenticated", false);
                               window.location.href = process.env.REACT_APP_PUBLIC_URL+'admin/hotelsancho';
                      }
                      if (action=='logout'){
                               sessionStorage.setItem("AuthenticatedShowError", false);
                               sessionStorage.setItem("AuthenticatedShowLogin", false);
                               sessionStorage.setItem("isAuthenticated", false);
                               window.location.href = process.env.REACT_APP_PUBLIC_URL+'admin/hotelsancho';
                      }
                      if (action=='valid'){
                          sessionStorage.setItem("isAuthenticated", true);
                          sessionStorage.setItem("AuthenticatedShowError", false);
                          sessionStorage.setItem("AuthenticatedShowLogin", false);
                          window.location.href = process.env.REACT_APP_PUBLIC_URL+'admin/hotelsancho';
                      }
                      if (action=='error'){
                          sessionStorage.setItem("isAuthenticated", false);
                          sessionStorage.setItem("AuthenticatedShowError", true);
                          sessionStorage.setItem("AuthenticatedShowLogin", true);
                          window.location.href = process.env.REACT_APP_PUBLIC_URL+'admin/hotelsancho';
                      }
};


