/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/


// @mui material components
import Icon from "@mui/material/Icon";
import TwitterIcon from '@mui/icons-material/Twitter';
import Price        from "layouts/pages/landing-pages/price";
import Availability from "layouts/pages/landing-pages/availability";
import ListBookings from "layouts/pages/landing-pages/listbookings";
import Bills        from "layouts/pages/landing-pages/bills";
import Comparative  from "layouts/pages/landing-pages/comparative";
import Restaurant   from "layouts/pages/landing-pages/restaurant";


const twitter=process.env.REACT_APP_TWITTER;


// Sections
// If there is no route or link then it will be use page ref in Presentation
// pagerefindex:"0" is used for Link and href and should not be assigned in the Code
const routes = [
 {
    name: "Comparativa",
    icon: <Icon></Icon>,
    href:"",
    route:"/admin/hotelsancho/comparative",
    component:<Comparative/>,
    pagerefindex:"1",
    showpage:"admin"
  },
  {
    name: "Facturas",
    icon: <Icon></Icon>,
    href:"",
    route:"/admin/hotelsancho/bills",
    component:<Bills/>,
    pagerefindex:"1",
    showpage:"admin"
  },
   {
    name: "Restaurant",
    icon: <Icon></Icon>,
    href:"",
    route:"/admin/hotelsancho/restaurant",
    component:<Restaurant/>,
    pagerefindex:"1",
    showpage:"admin"
  },
  {
    name: "Disponibilidad",
    icon: <Icon></Icon>,
    href:"",
    route:"/admin/hotelsancho/availability",
    component:<Availability/>,
    pagerefindex:"1",
    showpage:"admin"
  },
   {
    name: "Precios",
    icon: <Icon></Icon>,
    href:"",
    route:"/admin/hotelsancho/price",
    pagerefindex:"1",
    component:<Price/>,
    showpage:"admin"
  },
  {
    name: "Reservas",
    icon: <Icon></Icon>,
    href:"",
    route:"/admin/hotelsancho/listbookings",
    pagerefindex:"1",
    component:<ListBookings/>,
    showpage:"admin"
  },
  {
    name: "",
    icon: <TwitterIcon></TwitterIcon>,
    href:twitter,
    route:"",
    pagerefindex:"0",
    showpage:"presentation"
  }
];

export default routes;
