/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";


// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// ICONS:
import SearchIcon from '@mui/icons-material/Search';
import BedIcon from '@mui/icons-material/Bed';
import PeopleIcon from '@mui/icons-material/People';
import DateRangeIcon from '@mui/icons-material/DateRange';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import EuroIcon from '@mui/icons-material/Euro';
import CheckIcon from '@mui/icons-material/Check';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmailIcon from '@mui/icons-material/Email';
import EventIcon from '@mui/icons-material/Event';
import NoteAltIcon from '@mui/icons-material/NoteAlt';


function BookingCard({ color, title, checkin, checkout, name, room, meal, email, cost, number, people, requests}) {


  return (
    <MKBox
      variant= "gradient"
      bgColor={color}
      borderRadius="xl"
      shadow={color === "transparent" ? "none" : "md"}
      p={3}
    >
      <MKBox lineHeight={1}>
       <MKTypography
          display="block"
          variant= "h4"
          fontWeight="bold"
          color={color === "transparent" || color === "light" ? "dark" : "white"}
          mb={0.5}
        >
          <u>{title} &nbsp; {number} </u>
        </MKTypography>
        <MKTypography
          display="block"
          variant= "h4"
          fontWeight="bold"
          color={color === "transparent" || color === "light" ? "dark" : "white"}
          mt={5}
        >
          {name}
        </MKTypography>
        <MKTypography
         variant="body2"
        color={color === "transparent" || color === "light" ? "text" : "white"}
        my={4}
        >
          <EventIcon variant="filled"  fontSize="medium"/> &nbsp; <b>Dia de entrada</b> &nbsp; &nbsp; {checkin}
        </MKTypography>
        <MKTypography
        variant="body2"
        color={color === "transparent" || color === "light" ? "text" : "white"}
        my={4}
        >
          <EventIcon variant="filled"  fontSize="medium"/> &nbsp; <b>Dia de salida</b> &nbsp; &nbsp; {checkout}
        </MKTypography>
     
      <MKTypography
        variant="body2"
        color={color === "transparent" || color === "light" ? "text" : "white"}
        my={4}
      >
        <BedIcon variant="filled"  fontSize="medium"/> &nbsp; {room}
      </MKTypography>

       <MKTypography
        variant="body2"
        color={color === "transparent" || color === "light" ? "text" : "white"}
        my={4}
      >
        <PeopleIcon variant="filled"  fontSize="medium"/> &nbsp; {people}
      </MKTypography>


       <MKTypography
        variant="body2"
        color={color === "transparent" || color === "light" ? "text" : "white"}
        my={4}
      >
        <RestaurantIcon variant="filled"  fontSize="medium"/> &nbsp; {meal}
      </MKTypography>

       <MKTypography
        variant="body2"
        color={color === "transparent" || color === "light" ? "text" : "white"}
        my={4}
      >
        <EmailIcon variant="filled"  fontSize="medium"/> &nbsp; {email}
      </MKTypography>

      <MKTypography
        variant="body2"
        color={color === "transparent" || color === "light" ? "text" : "white"}
        my={4}
      >
        <EuroIcon variant="filled"  fontSize="medium"/> &nbsp; {cost}
      </MKTypography>

       {(requests!=null)&&
        <MKTypography
        variant="body2"
        color={color === "transparent" || color === "light" ? "text" : "white"}
        my={4}
      >
        <NoteAltIcon variant="filled"  fontSize="medium"/> &nbsp; {requests}
      </MKTypography>
      }

    </MKBox>
    </MKBox>
  );
}

// Setting default values for the props of DefaultReviewCard
BookingCard.defaultProps = {
  color: "transparent",
};

// Typechecking props for the DefaultReviewCard
BookingCard.propTypes = {
  color: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  title: PropTypes.string.isRequired,
  checkin: PropTypes.string.isRequired,
  checkout: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  room: PropTypes.string.isRequired,
  meal: PropTypes.string.isRequired,
  email:PropTypes.string.isRequired,
  cost: PropTypes.number.isRequired, 
  number: PropTypes.number.isRequired
};

export default BookingCard;
