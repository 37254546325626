import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import InputAdornment from "@mui/material/InputAdornment";

var AWS = require('aws-sdk');
var configuration={
    region: 'us-east-1',
    secretAccessKey: 'RfpzwZM3mXnAQbfGPEzeHKFgVyLAM8pQqGhd2kdI',
    accessKeyId: 'AKIA363ORU3S2T6VXL2R'
};
AWS.config.update(configuration);
const docClient = new AWS.DynamoDB.DocumentClient();

function getIndex(month, day) {
    let d;
    if (day<10){
               d=0+day 
        }else{
               d=day
        };
    const index=month*100+d;
  return index;   
};

const getPromotionCodeRange = async  (start_day, start_month, end_day, end_month, code) => {
    console.log('Get PromotionCode...');
    let check=await getPromotionCode('promotion',start_day, start_month, end_day, end_month,code);
    console.log("promotion valid:", check);
}

const getPromotionCode = (tableName, start_day, start_month, end_day, end_month, code) => {
    var params = {
        TableName: tableName
    };
    docClient.scan(params, function (err, data) {
        if (!err) {
            //console.log(data);
            const start=getIndex(start_month,start_day);
            const end=getIndex(end_month,end_day);
            var active=true; //Someday is not getting the code active then no promo
            var discount=0;
            data.Items.forEach(function(element, index, array) {
                 const day=getIndex(element.month,element.day);
                 
                 if ((day>=start) && (day<=end)){

                    if ((element.code1==code) && (element.active1)){
                        discount=element.discount1;
                    }else{
                        if ((element.code2==code)&& (element.active2)){
                            discount=element.discount2;
                        }else{
                            active=false;
                            discount=0;
                        }
                    };
                    console.log(element.month, element.day, element.code1, element.active1, element.code2, element.active2);  
                 };
            });
            console.log("Promo Discount:", active, discount);
        }
        else{
            console.log('Error',err)
        }
    })
 };


function PromotionCode(props) {
  
  const [code, setCode] = useState(null);
  const [valid, setValid] = useState(null);
  const [discount, setDiscount] = useState(null);


  //props.setcode('')
  const setPromotion = (value) => {

                            // Check valid code
                            setCode(value);
                            props.setcode(value);
                            
                            
                           // let check=getPromotionCodeRange(7,10,8,10,code)
                            //console.log("promotion valid:", check);

                            var params = {
                              TableName: 'promotion'
                            };

    docClient.scan(params, function (err, data) {
        if (!err) {
            //console.log(data);
            const start=getIndex(8,1);
            const end=getIndex(8,2);
            var active=true; //Someday is not getting the code active then no promo
            var discount=0;
            data.Items.forEach(function(element, index, array) {
                 const day=getIndex(element.month,element.day);
                 
                 if ((day>=start) && (day<=end)){

                    if ((element.code1==value) && (element.active1)){
                        discount=element.discount1;
                    }else{
                        if ((element.code2==value)&& (element.active2)){
                            discount=element.discount2;
                        }else{
                            active=false;
                            discount=0;
                        }
                    };
                    console.log(element.month, element.day, element.code1, element.active1, element.code2, element.active2);  
                 };
            });
            console.log("Promo Discount:", active, discount);
            setValid(active);
            setDiscount(discount);
            props.setcodediscount(discount);
            props.setcodevalid(active);
        }
        else{
            console.log('Error',err)
        }
    })
            };



  return (
    
        <Grid container >
          <Grid item xs={12} lg={12} textAlign="center">
            <MKInput
              variant="standard"
              placeholder="Tiene algun codigo promocional?"
              fullWidth
              label={(valid)?"codigo valido":(valid==false)?"codigo no valido":"Promocion"}
              InputLabelProps={{ shrink: true }}
              onChange={(e)=> setPromotion(e.target.value)}
              InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <CardGiftcardIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            />
          </Grid>
        </Grid>
     
  );
}

export default PromotionCode;
