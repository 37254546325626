// Basic Functions Availability Table

// AWS: https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/dynamodb-example-document-client.html 

var AWS = require('aws-sdk');

//console.log(process.env.REACT_APP_AWS_REGION);

var configuration={
    region: process.env.REACT_APP_AWS_REGION,
    secretAccessKey: process.env.REACT_APP_AWS_KEY,
    accessKeyId: process.env.REACT_APP_AWS_SECRET
};

AWS.config.update(configuration);

const docClient = new AWS.DynamoDB.DocumentClient();

// **********************************************
// CALCULATIONS
// **********************************************

const enero=31;
const febrero=28;
const marzo=31;
const abril=3;
const mayo=31;
const junio=30;
const julio=31;
const agosto=31;
const septiembre=30;
const octubre=31;
const noviembre=30;
const diciembre=31;
const month_ndays=[0,enero,febrero,marzo,abril,mayo,junio,julio,agosto,septiembre,octubre,noviembre,diciembre];

const month_name=[0,'enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre'];


// **********************************************
// PRICE
// **********************************************
// Price Calculation:
// Single            = priceRoom - 20 
// SingleHD          = priceRoom - 20 + priceBreakfast
// SinglePM          = priceRoom - 20 + priceBreakfast + priceMeal
// SinglePC          = priceRoom - 20 + priceBreakfast + 2*priceMeal
// Double            = priceRoom
// DoubleHD          = priceRoom + 2 * priceBreakfast
// DoublePM          = priceRoom + 2 * priceBreakfast + 2 * priceMeal
// DoublePC          = priceRoom + 2 * priceBreakfast + 4 * priceMeal
// TripleChildren    = priceRoom + 30  
// TripleChildrenHD  = priceRoom + 30 + 2.5 * priceBreakfast 
// TripleChildrenPM  = priceRoom + 30 + 2.5 * priceBreakfast + 2.5 * priceMeal
// TripleChildrenPC  = priceRoom + 30 + 2.5 * priceBreakfast + 5 * priceMeal
// TripleAdult       = priceRoom + 30 
// TripleAdultHD     = priceRoom + 30 + 3 * priceBreakfast 
// TripleAdultPM     = priceRoom + 30 + 3 * priceBreakfast + 3 * priceMeal
// TripleAdultPC     = priceRoom + 30 + 3 * priceBreakfast + 6 * priceMeal
// Quatruple         = priceRoom + 60 +
// QuatrupleHD       = priceRoom + 60 + 3 * priceBreakfast
// QuatruplePM       = priceRoom + 60 + 3 * priceBreakfast + 3 * priceMeal
// QuatruplePC       = priceRoom + 60 + 3 * priceBreakfast + 6 * priceMeal



export  function getPrices(room, priceroom, priceBreakfast, priceMeal) {
    // TODO: Check for Errors values !=null or undefined 
    let price_onlyroom;
    let price_breakfast;
    let price_halfboard;
    let price_fullboard;
    let error=false;

    if (room=='singleOneBed'){
        price_onlyroom=priceroom - 20;
        price_breakfast=price_onlyroom+priceBreakfast;
        price_halfboard=price_onlyroom+priceBreakfast + priceMeal;
        price_fullboard=price_onlyroom+priceBreakfast + 2 * priceMeal;
    } 
    if (room=='singleTwoBed'){
        price_onlyroom=priceroom - 20;
        price_breakfast=price_onlyroom+priceBreakfast;
        price_halfboard=price_onlyroom+priceBreakfast + priceMeal;
        price_fullboard=price_onlyroom+priceBreakfast + 2 * priceMeal;
    } 
    if (room=='doubleOneBed'){
        price_onlyroom=priceroom;
        price_breakfast=price_onlyroom+ 2 * priceBreakfast;
        price_halfboard=price_onlyroom+ 2 * priceBreakfast + 2 * priceMeal;
        price_fullboard=price_onlyroom+ 2 * priceBreakfast + 4 * priceMeal;
    } 
    if (room=='doubleTwoBed'){
        price_onlyroom=priceroom;
        price_breakfast=price_onlyroom+ 2 * priceBreakfast;
        price_halfboard=price_onlyroom+ 2 * priceBreakfast + 2 * priceMeal;
        price_fullboard=price_onlyroom+ 2 * priceBreakfast + 4 * priceMeal;
    } 
    if (room=='tripleChildren'){
        price_onlyroom=priceroom + 30;
        price_breakfast=price_onlyroom+ 2.5 * priceBreakfast;
        price_halfboard=price_onlyroom+ 2.5 * priceBreakfast + 2.5 * priceMeal;
        price_fullboard=price_onlyroom+ 2.5 * priceBreakfast + 5 * priceMeal;
    } 
    if (room=='tripleAdult'){
        price_onlyroom=priceroom + 30;
        price_breakfast=price_onlyroom+ 3 * priceBreakfast;
        price_halfboard=price_onlyroom+ 3 * priceBreakfast + 3 * priceMeal;
        price_fullboard=price_onlyroom+ 3 * priceBreakfast + 6 * priceMeal;
    } 
    if (room=='quadruple'){
        price_onlyroom=priceroom + 50;
        price_breakfast=price_onlyroom+ 3 * priceBreakfast;
        price_halfboard=price_onlyroom+ 3 * priceBreakfast + 3 * priceMeal;
        price_fullboard=price_onlyroom+ 3 * priceBreakfast + 6 * priceMeal;
    } 
  return [error, price_onlyroom, price_breakfast, price_halfboard, price_fullboard];   
};

// **********************************************
// DAYS
// **********************************************

export  function getValidDay(month, day) {
    let valid;
    if ((Number(day)>0) && (Number(day)<=Number(month_ndays[month]))){
        valid=true;
    }else{
        valid=false;
    }
  return valid;   
};

export function getLastDay(month, day) {
    let lastmonth,lastday;
    
    if (day=="1"){
        if (month=="1"){
            lastmonth="12";
            lastday=month_ndays[lastmonth]
        }else{
            lastmonth=Number(month)-1;
            lastday=month_ndays[lastmonth]
        }
    }else{
        lastday=Number(day)-1;
        lastmonth=month;
    }
   //console.log("getLastDay: ", month, day, lastmonth, lastday );
  return [lastmonth.toString(),lastday.toString()];   
};

export  function getIndex(month, day) {
  return Number(month)*100+Number(day);   
};

export  function getIndexDay(index) {
    let value=0;
    if (index>100 && index <200 ){value=index-100;}
    if (index>200 && index <300 ){value=index-200;}
    if (index>300 && index <400 ){value=index-300;}
    if (index>400 && index <500 ){value=index-400;}
    if (index>500 && index <600 ){value=index-500;}
    if (index>600 && index <700 ){value=index-600;}
    if (index>700 && index <800 ){value=index-700;}
    if (index>800 && index <900 ){value=index-800;}
    if (index>900 && index <1000 ){value=index-900;}
    if (index>1000 && index <1100 ){value=index-1000;}
    if (index>1100 && index <1200 ){value=index-1100;}
    if (index>1200 && index <1300 ){value=index-1200;}
    return value;   
};
export  function getIndexMonth(index) {
    let value=0;
    if (index>100 && index <200 ){value=1;}
    if (index>200 && index <300 ){value=2;}
    if (index>300 && index <400 ){value=3;}
    if (index>400 && index <500 ){value=4;}
    if (index>500 && index <600 ){value=5;}
    if (index>600 && index <700 ){value=6;}
    if (index>700 && index <800 ){value=7;}
    if (index>800 && index <900 ){value=8;}
    if (index>900 && index <1000 ){value=9;}
    if (index>1000 && index <1100 ){value=10;}
    if (index>1100 && index <1200 ){value=11;}
    if (index>1200 && index <1300 ){value=12;}
    return value;   
};

export  const convertDate = (date) => {
    //var date=new Date();  
   // var m=date.getMonth()+1;
   // var today=date.getFullYear()+"-"+m+"-"+date.getDate()

    let text=date.split("-");
    let year=text[0];
    let month=text[1];
    let day=text[2];
    
    return (day+"-"+month+"-"+year)
}


export  function getDay(date) {

    let text=date.split("-");
    let year=text[2];
    let month=text[1];
    let day=text[0];
  return day;   
};

export  function getMonth(date) {

    let text=date.split("-");
    let year=text[2];
    let month=text[1];
    let day=text[0];
  return month;   
};


// **********************************************
// DATABASE 
// **********************************************

const getRestaurantDB = (setDone, start_day, start_month, end_day, end_month, checkBreakfast, checkMeal) => {
    let lastmonth,lastday;
    var params = {
        TableName: 'availability'
    };
    docClient.scan(params, function (err, data) {
        let ndays=0;
        let found=true;
        if (!err) {
            //console.log(data);

            const start= getIndex(start_month,start_day);
            [lastmonth,lastday]=getLastDay(end_month,end_day);
            console.log("getRestaurantDB : ", lastmonth,  lastday );
            const end  = getIndex(lastmonth,lastday);
            
           let list=[];
            //to order the results:
            for (let i = start; i <= end; i++) {
                    let dayindex=getIndexDay(i);
                    let monthindex=getIndexMonth(i);
                    data.Items.forEach(function(element, index, array) {
                         const day=getIndex(element.month,element.day);
                       //  console.log("getAdminPriceDB: ", day, element.day, element.month);
                         if (i==day && getValidDay(element.month,element.day)){
                            const dayelement=dayindex + " " + month_name[monthindex];
                            let pr=0;
                            if (checkBreakfast){pr=element.restaurantBreakfast};
                            if (checkMeal){pr=element.restaurantMeal};
                            list.push({'date':dayelement, 'value':pr, 'index':day});
                            ndays=ndays+1;
                         };
                    });
            };//for

            //console.log("Child availability:",sr, sb, sh, sf, str, stb, sth, stf, dr, db, dh, df, dtr, dtb, dth, dtf, tr, tb, th, tf, tar, tab, tah, taf, qr, qb, qh, qf, ndays);
            setDone(list);
        }
        else{
            console.log('Error',err)
        }
    })
 };



 const getAdminPriceDB = (setDone,start_day, start_month, end_day, end_month, checkRoom, checkBreakfast, checkMeal) => {
    var params = {
        TableName: 'availability'
    };  let ndays=0;
    let lastmonth,lastday;
    docClient.scan(params, function (err, data) {
        if (!err) {
            //console.log(data);
            const start= getIndex(start_month,start_day);
            [lastmonth,lastday]=getLastDay(end_month,end_day);
            const end  = getIndex(lastmonth,lastday);
           // console.log("Child searching availablity dates", "checkin", start_month,start_day, "last: ", lastmonth,  lastday );

            let listprices=[];
            //to order the results:
            for (let i = start; i <= end; i++) {
                    data.Items.forEach(function(element, index, array) {
                         const day=getIndex(element.month,element.day);
                       //  console.log("getAdminPriceDB: ", day, element.day, element.month);
                         if (i==day && getValidDay(element.month,element.day)){
                            const breakfast=element.priceBreakfast;
                            const meal=element.priceMeal;
                           // console.log(element.month, element.day, " nroom: ", element.doubleOneBedCount, " priceroom: ", element.doubleOneBed, " Breakfast: ", breakfast, " Meal: ", meal);
                            const dayelement=element.day + " " + month_name[element.month];
                            let pr=0;
                            if (checkRoom){pr=element.doubleOneBed};
                            if (checkBreakfast){pr=breakfast};
                            if (checkMeal){pr=meal};
                            listprices.push({'date':dayelement, 'price':pr, 'index':day});
                            ndays=ndays+1;
                         };
                    });
            };//for

            //console.log("Child availability:",sr, sb, sh, sf, str, stb, sth, stf, dr, db, dh, df, dtr, dtb, dth, dtf, tr, tb, th, tf, tar, tab, tah, taf, qr, qb, qh, qf, ndays);
            setDone(listprices);
        }
        else{
            console.log('Error',err)
        }
    })
 };

 const getAdminAvailabilityDB = (setDone,start_day, start_month, end_day, end_month, singleOneBed, singleTwoBed, doubleOneBed, doubleTwoBed, tripleChildren, tripleAdult, quadruple, breakfast, halfboard, fullboard ) => {
    var params = {
        TableName: 'availability'
    };  
    let ndays=0;
    let lastmonth,lastday;
    docClient.scan(params, function (err, data) {
        if (!err) {
            //console.log(data);
            const start= getIndex(start_month,start_day);
            [lastmonth,lastday]=getLastDay(end_month,end_day);
            const end  = getIndex(lastmonth,lastday);
            let list=[];
             //to order the results:
            for (let i = start; i <= end; i++) {
                    data.Items.forEach(function(element, index, array) {
                         const day=getIndex(element.month,element.day);
                         if (i==day && getValidDay(element.month,element.day)){
                            const dayelement=element.day + " " + month_name[element.month];
                            //console.log("getAdminAvailabilityDB:", dayelement,  element.doubleOneBedCount)
                            let count=0;
                            if (singleOneBed){count=element.singleOneBedCount};
                            if (singleTwoBed){count=element.singleTwoBedCount};
                            if (doubleOneBed){count=element.doubleOneBedCount};
                            if (doubleTwoBed){count=element.doubleTwoBedCount};
                            if (tripleChildren){count=element.tripleChildrenCount};
                            if (tripleAdult){count=element.tripleAdultCount};
                            if (quadruple){count=element.quadrupleCount};
                            if (breakfast && element.restaurantBreakfast>0){count=element.restaurantBreakfast};
                            if (halfboard && element.restaurantMeal>0){count=element.restaurantMeal};
                            if (fullboard && element.restaurantMeal>0){count=element.restaurantMeal};

                            list.push({'date':dayelement, 'value':count, 'index':day});
                            ndays=ndays+1;
                         };
                    });
            };//for

            setDone(list);
        }
        else{
            console.log('Error',err)
        }
    })
 };

 // PRICES ROOM + BREAKFAST or MEAL only  shows when  there is 
 // restaurant availability element.restaurantMeal > 0 !! 
 // it will show room halfboard or breakfast when price is > 0

 export  const getAvail = (setDone, start_day, start_month, end_day, end_month) => {
    let lastmonth,lastday;
    var params = {
        TableName: 'availability'
    };

    docClient.scan(params, function (err, data) {
       
        let ndays=0;
        let sr=0;
        let sb=0;
        let sh=0;
        let sf=0;
        let str=0;
        let stb=0;
        let sth=0;
        let stf=0;
        let dr=0;
        let db=0;
        let dh=0;
        let df=0;
        let dtr=0;
        let dtb=0;
        let dth=0;
        let dtf=0;
        let tr=0;
        let tb=0;
        let th=0;
        let tf=0;
        let tar=0;
        let tab=0;
        let tah=0;
        let taf=0;
        let qr=0;
        let qb=0;
        let qh=0;
        let qf=0;
        let found_s=1;
        let found_st=1;
        let found_d=1;
        let found_dt=1;
        let found_t=1;
        let found_ta=1;
        let found_q=1;
        let found_restaurantMeal=1;
        let found_restaurantBreakfast=1;
        let enable_fullboard=process.env.REACT_APP_REGIME_FULLBOARD_ENABLE=='true'?1:0;

        if (!err) {
            //console.log(data);

            const start= getIndex(start_month,start_day);
            [lastmonth,lastday]=getLastDay(end_month,end_day);
            console.log("getAvail: ", lastmonth,  lastday );
            const end  = getIndex(lastmonth,lastday);
            
            
            data.Items.forEach(function(element, index, array) {

                 const day=getIndex(element.month,element.day);
                 
                 if (getValidDay(element.month,element.day) && (day>=start) && (day<=end)){

                    ndays=ndays+1;

                  //  console.log("getAvail: ",  element);

                  //  console.log("getAvail: ", element.month, element.day, ' getAvail ndays: ', ndays, " nroom: ", element.doubleOneBed," priceroom: ", element.priceRoom );
             
                 //   console.log("Child database:", element);

                    if (element.restaurantMeal<=0){
                        found_restaurantMeal=0;
                    }

                    if (element.restaurantBreakfast<=0){
                        found_restaurantBreakfast=0;
                    }

                    if (element.singleOneBedCount<=0){
                        found_s=0;
                    }
                    sr=found_s*(sr+element.singleOneBed)
                    sb=found_s*(sb+element.singleOneBedBreakfast)*found_restaurantBreakfast
                    sh=found_s*(sh+element.singleOneBedHalfboard)*found_restaurantMeal
                    sf=found_s*(sf+element.singleOneBedFullboard)*found_restaurantMeal*enable_fullboard;

                    if (element.singleTwoBedCount<=0){
                        found_st=0;
                    }
                    str=found_st*(str+element.singleOneBed)
                    stb=found_st*(stb+element.singleOneBedBreakfast)*found_restaurantBreakfast
                    sth=found_st*(sth+element.singleOneBedHalfboard)*found_restaurantMeal
                    stf=found_st*(stf+element.singleOneBedFullboard)*found_restaurantMeal*enable_fullboard;

                    if (element.doubleOneBedCount<=0){
                        found_d=0;
                    }
                    dr=found_d*(dr+element.doubleOneBed)
                    db=found_d*(db+element.doubleOneBedBreakfast)*found_restaurantBreakfast
                    dh=found_d*(dh+element.doubleOneBedHalfboard)*found_restaurantMeal
                    df=found_d*(df+element.doubleOneBedFullboard)*found_restaurantMeal*enable_fullboard;

                    console.log("getAvail: ", element.day, " DoubleOneBed Count: ", element.doubleOneBedCount," DoubleOneBed: ", element.doubleOneBed , "  total:", dr, "  restaurant:", found_restaurantMeal, "price halfboard: ", dh);

                    if (element.doubleTwoBedCount<=0){
                        found_dt=0;
                    }
                    dtr=found_dt*(dtr+element.doubleOneBed)
                    dtb=found_dt*(dtb+element.doubleOneBedBreakfast)*found_restaurantBreakfast
                    dth=found_dt*(dth+element.doubleOneBedHalfboard)*found_restaurantMeal
                    dtf=found_dt*(dtf+element.doubleOneBedFullboard)*found_restaurantMeal*enable_fullboard;

                    if (element.tripleChildrenCount<=0){
                        found_t=0;
                    }
                    tr=found_t*(tr+element.tripleChildren)
                    tb=found_t*(tb+element.tripleChildrenBreakfast)*found_restaurantBreakfast
                    th=found_t*(th+element.tripleChildrenHalfboard)*found_restaurantMeal
                    tf=found_t*(tf+element.tripleChildrenFullboard)*found_restaurantMeal*enable_fullboard;

                    if (element.tripleAdultCount<=0){
                        found_ta=0;
                    }
                    tar=found_ta*(tar+element.tripleAdult)
                    tab=found_ta*(tab+element.tripleAdultBreakfast)*found_restaurantBreakfast
                    tah=found_ta*(tah+element.tripleAdultHalfboard)*found_restaurantMeal
                    taf=found_ta*(taf+element.tripleAdultFullboard)*found_restaurantMeal*enable_fullboard;

                    if (element.quadrupleCount<=0){
                        found_q=0;
                    }
                    qr=found_q*(qr+element.quadruple)
                    qb=found_q*(qb+element.quadrupleBreakfast)*found_restaurantBreakfast
                    qh=found_q*(qh+element.quadrupleHalfboard)*found_restaurantMeal
                    qf=found_q*(qf+element.quadrupleFullboard)*found_restaurantMeal*enable_fullboard;

                 };
            });

            //console.log("Child availability:",sr, sb, sh, sf, str, stb, sth, stf, dr, db, dh, df, dtr, dtb, dth, dtf, tr, tb, th, tf, tar, tab, tah, taf, qr, qb, qh, qf, ndays);
            setDone(sr, sb, sh, sf, str, stb, sth, stf, dr, db, dh, df, dtr, dtb, dth, dtf, tr, tb, th, tf, tar, tab, tah, taf, qr, qb, qh, qf, ndays);
        
        }
        else{

            console.log('Error',err)
        }
    })
 };


  const setAdminPriceDB = (setDone, start_day, start_month, end_day, end_month, checkRoom, checkBreakfast, checkMeal, prices, indexes) => {
    let lastmonth,lastday;
    var params = {
        TableName: 'availability'
    };
    let nday=0;

    docClient.scan(params, function (err, data) {
       
        if (!err) {
            //console.log(data);
            const start= getIndex(start_month,start_day);
            [lastmonth,lastday]=getLastDay(end_month,end_day);
            const end  = getIndex(lastmonth,lastday);
           // console.log("Child searching availablity dates", "checkin", start_month,start_day, "last: ", lastmonth,  lastday );

            data.Items.forEach(function(element, index, array) {
                 const day=getIndex(element.month,element.day);

                 let value;
                 indexes.forEach(function(element2, index2, array2){
                    if (element2==day){
                        console.log("setAdminPriceDB ", element2, index2, prices[index2])
                        value=prices[index2];
                    }
                 });

               //  console.log(day, " Child valid day", element.month,element.day, getValidDay(element.month,element.day));
                 
                 if (getValidDay(element.month,element.day) && (day>=start) && (day<=end)){
                   // console.log(element);
                    console.log(index, "setAdminPriceDB: ", element.day, element.month, " price: ",  value);
                    
                    let priceRoom, priceBreakfast, priceMeal;
                    if (checkRoom){
                        priceRoom=Number(value);
                    }else{
                        priceRoom=Number(element.doubleOneBed);
                    };
                    if (checkBreakfast){
                        priceBreakfast=Number(value);
                    }else{                     
                        priceBreakfast=Number(element.priceBreakfast);
                    };
                    if (checkMeal){
                        priceMeal=Number(value)
                    }else{                 
                        priceMeal=Number(element.priceMeal);
                    };

                  //  console.log("Room: ", priceRoom, " Breakfast: ", priceBreakfast, " Meal: ", priceMeal );

                      
                      let sr, sb, sh, sf;
                      let dr, db, dh, df;
                      let tr, tb, th, tf;
                      let tar, tab, tah, taf;
                      let qr, qb, qh, qf;
                      let error1, error2, error3, error4, error5;

                       [error1, sr, sb, sh, sf]      = getPrices('singleOneBed', priceRoom, priceBreakfast, priceMeal);
                       [error2, dr, db, dh, df]      = getPrices('doubleOneBed', priceRoom, priceBreakfast, priceMeal);
                       [error3, tr, tb, th, tf]      = getPrices('tripleChildren', priceRoom, priceBreakfast, priceMeal);
                       [error4, tar, tab, tah, taf]  = getPrices('tripleAdult', priceRoom, priceBreakfast, priceMeal);
                       [error5, qr, qb, qh, qf]      = getPrices('quadruple', priceRoom, priceBreakfast, priceMeal);
                    
                    if (error1==false && error2==false && error3==false && error4==false && error5==false ){
                        const Data = {
                                availabilityDate:element.availabilityDate,
                                month: element.month,
                                day: element.day,
                                singleOneBedCount:element.singleOneBedCount,
                                singleTwoBedCount:element.singleTwoBedCount,
                                doubleOneBedCount:element.doubleOneBedCount,
                                doubleTwoBedCount:element.doubleTwoBedCount,
                                tripleChildrenCount:element.tripleChildrenCount,
                                tripleAdultCount:element.tripleAdultCount,
                                quadrupleCount:element.quadrupleCount,
                                singleOneBed:sr,
                                singleOneBedBreakfast:sb,
                                singleOneBedHalfboard:sh,
                                singleOneBedFullboard:sf,
                                doubleOneBed:dr,
                                doubleOneBedBreakfast:db,
                                doubleOneBedHalfboard:dh,
                                doubleOneBedFullboard:df,
                                tripleChildren:tr,
                                tripleChildrenBreakfast:tb,
                                tripleChildrenHalfboard:th,
                                tripleChildrenFullboard:tf,
                                tripleAdult:tar,
                                tripleAdultBreakfast:tab,
                                tripleAdultHalfboard:tah,
                                tripleAdultFullboard:taf,
                                quadruple:qr,
                                quadrupleBreakfast:qb,
                                quadrupleHalfboard:qh,
                                quadrupleFullboard:qf,
                                priceBreakfast:priceBreakfast,
                                priceMeal:priceMeal,
                                restaurantMeal:element.restaurantMeal,
                                restaurantBreakfast:element.restaurantBreakfast
                             };
                            console.log(Data);

                    var params = {
                                TableName: 'availability',
                                Item: Data
                    }
                            
                    docClient.put(params, function (err, data) {
                                    if (err) {
                                        console.log('Error', err)
                                    } 
                    })

                    nday=nday+1;
                  };
                    
                 };
            });
            setDone();
        }
        else{
            console.log('Error',err)
        }
    })
 };


export  const setAdminAvailabilityDB = (setDone, start_day, start_month, end_day, end_month, singleOneBed, singleTwoBed, doubleOneBed, doubleTwoBed, tripleChildren, tripleAdult, quadruple, breakfast, halfboard, fullboard, list, indexes) => {
    
    var params = {
        TableName: 'availability'
    };

    let lastmonth,lastday;
    let nday=0;
    let sr, sb, sh, sf, singleOneBedCount, singleTwoBedCount ;
    let dr, db, dh, df, doubleOneBedCount, doubleTwoBedCount ;
    let tr, tb, th, tf, tripleChildrenCount, tripleAdultCount ;
    let tar, tab, tah, taf;
    let qr, qb, qh, qf, quadrupleCount;
    let error1, error2, error3, error4, error5;
    let restaurantMeal;
    let restaurantBreakfast;

    console.log("Changing availability:", list );

    docClient.scan(params, function (err, data) {
       
        if (!err) {
            //console.log(data);
            const start= getIndex(start_month,start_day);
            [lastmonth,lastday]=getLastDay(end_month,end_day);
            const end  = getIndex(lastmonth,lastday);
           // console.log("Child searching availablity dates", "checkin", start_month,start_day, "last: ", lastmonth,  lastday );

            data.Items.forEach(function(element, index, array) {
                 const day=getIndex(element.month,element.day);

                 let value;
                 indexes.forEach(function(element2, index2, array2){
                    if (element2==day){
                        console.log("setAdminAvailabilityDB values ", element2, index2, list[index2])
                        value=list[index2];
                    }
                 });

               //  console.log(day, " Child valid day", element.month,element.day, getValidDay(element.month,element.day));
                 
                 if (getValidDay(element.month,element.day) && (day>=start) && (day<=end)){
                   // console.log(element);
                   // console.log(index, "Child changing price: ", element.day, element.month, prices[nday]);
                    
                    if (singleOneBed){
                        singleOneBedCount=value
                    }else{
                        singleOneBedCount=element.singleOneBedCount;
                    };
                    if (singleTwoBed){
                        singleTwoBedCount=value
                    }else{
                        singleTwoBedCount=element.singleTwoBedCount;
                    };
                    if (doubleOneBed){
                        doubleOneBedCount=value
                    }else{
                        doubleOneBedCount=element.doubleOneBedCount;
                    };
                    if (doubleTwoBed){
                        doubleTwoBedCount=value
                    }else{
                        doubleTwoBedCount=element.doubleTwoBedCount;
                    };
                    if (tripleChildren){
                        tripleChildrenCount=value
                    }else{
                        tripleChildrenCount=element.tripleChildrenCount;
                    };
                    if (tripleAdult){
                        tripleAdultCount=value
                    }else{
                        tripleAdultCount=element.tripleAdultCount;
                    };
                    if (quadruple){
                        quadrupleCount=value
                    }else{
                        quadrupleCount=element.quadrupleCount;
                    };

                    restaurantBreakfast=element.restaurantBreakfast;
                    restaurantMeal=element.restaurantMeal;

                    if (breakfast){
                        if (value<=0){
                            restaurantBreakfast=0;
                        }else{
                            restaurantBreakfast=value;
                        }
                    };

                    if (halfboard){
                        if (value<=0){
                            restaurantMeal=0;
                            console.log("valueee1:",  value,restaurantMeal);
                        }else{
                            restaurantMeal=value;
                            console.log("valueee2:",  value);
                        }
                    }

                    //TODO:
                    if (fullboard){
                        if (value<=0){
                            restaurantMeal=0;
                        }else{
                            restaurantMeal=value;
                        }
                    }

                    console.log("setAdminAvailabilityDB  Meal: ", element.restaurantMeal, halfboard, " new:", value, restaurantMeal );

                    let priceRoom=element.doubleOneBed;
                    let priceBreakfast=element.priceBreakfast;
                    let priceMeal=element.priceMeal;
                    [error1, sr, sb, sh, sf]      = getPrices('singleOneBed', priceRoom, priceBreakfast, priceMeal);
                    [error2, dr, db, dh, df]      = getPrices('doubleOneBed', priceRoom, priceBreakfast, priceMeal);
                    [error3, tr, tb, th, tf]      = getPrices('tripleChildren', priceRoom, priceBreakfast, priceMeal);
                    [error4, tar, tab, tah, taf]  = getPrices('tripleAdult', priceRoom, priceBreakfast, priceMeal);
                    [error5, qr, qb, qh, qf]      = getPrices('quadruple', priceRoom, priceBreakfast, priceMeal);

                        const Data = {
                                availabilityDate:element.availabilityDate,
                                month: element.month,
                                day: element.day,
                                singleOneBedCount:singleOneBedCount,
                                singleTwoBedCount:singleTwoBedCount,
                                doubleOneBedCount:doubleOneBedCount,
                                doubleTwoBedCount:doubleTwoBedCount,
                                tripleChildrenCount:tripleChildrenCount,
                                tripleAdultCount:tripleAdultCount,
                                quadrupleCount:quadrupleCount,
                                singleOneBed:sr,
                                singleOneBedBreakfast:sb,
                                singleOneBedHalfboard:sh,
                                singleOneBedFullboard:sf,
                                doubleOneBed:dr,
                                doubleOneBedBreakfast:db,
                                doubleOneBedHalfboard:dh,
                                doubleOneBedFullboard:df,
                                tripleChildren:tr,
                                tripleChildrenBreakfast:tb,
                                tripleChildrenHalfboard:th,
                                tripleChildrenFullboard:tf,
                                tripleAdult:tar,
                                tripleAdultBreakfast:tab,
                                tripleAdultHalfboard:tah,
                                tripleAdultFullboard:taf,
                                quadruple:qr,
                                quadrupleBreakfast:qb,
                                quadrupleHalfboard:qh,
                                quadrupleFullboard:qf,
                                priceBreakfast:priceBreakfast,
                                priceMeal:priceMeal,
                                restaurantMeal: restaurantMeal,
                                restaurantBreakfast: restaurantBreakfast,
                            }
                     console.log("setAdminAvailabilityDB  Data to change: ", Data);
                     var params = {
                                TableName: 'availability',
                                Item: Data
                    }
                            
                    docClient.put(params, function (err, data) {
                                    if (err) {
                                        console.log('Error', err)
                                    } 
                    })
                     nday=nday+1;
                  };
                    
            });
            setDone();
        }
        else{
            console.log('Error',err)
        }
    })
 };


 export const stopSellAdminDB = (setDone, start_day, start_month, end_day, end_month) => {
    
    var params = {
        TableName: 'availability'
    };

    let lastmonth,lastday;
    let nday=0;
    let sr, sb, sh, sf, singleOneBedCount, singleTwoBedCount ;
    let dr, db, dh, df, doubleOneBedCount, doubleTwoBedCount ;
    let tr, tb, th, tf, tripleChildrenCount, tripleAdultCount ;
    let tar, tab, tah, taf;
    let qr, qb, qh, qf, quadrupleCount;
    let error1, error2, error3, error4, error5;

    docClient.scan(params, function (err, data) {
       
        if (!err) {

            data.Items.forEach(function(element, index, array) {

                 const day=getIndex(element.month,element.day);
                 const start= getIndex(start_month,start_day);
                 [lastmonth,lastday]=getLastDay(end_month,end_day);
                 const end  = getIndex(lastmonth,lastday);

               //  console.log(day, " Child valid day", element.month,element.day, getValidDay(element.month,element.day));
                 
                 if (getValidDay(element.month,element.day) && (day>=start) && (day<=end)){
                   // console.log(element);
                   // console.log(index, "Child changing price: ", element.day, element.month, prices[nday]);
                    
                    let priceRoom=element.doubleOneBed;
                    let priceBreakfast=element.priceBreakfast;
                    let priceMeal=element.priceMeal;
                    let restaurantMeal=element.restaurantMeal;
                    let restaurantBreakfast=element.restaurantBreakfast;
                    [error1, sr, sb, sh, sf]      = getPrices('singleOneBed', priceRoom, priceBreakfast, priceMeal);
                    [error2, dr, db, dh, df]      = getPrices('doubleOneBed', priceRoom, priceBreakfast, priceMeal);
                    [error3, tr, tb, th, tf]      = getPrices('tripleChildren', priceRoom, priceBreakfast, priceMeal);
                    [error4, tar, tab, tah, taf]  = getPrices('tripleAdult', priceRoom, priceBreakfast, priceMeal);
                    [error5, qr, qb, qh, qf]      = getPrices('quadruple', priceRoom, priceBreakfast, priceMeal);

                        const Data = {
                                availabilityDate:element.availabilityDate,
                                month: element.month,
                                day: element.day,
                                singleOneBedCount:0,
                                singleTwoBedCount:0,
                                doubleOneBedCount:0,
                                doubleTwoBedCount:0,
                                tripleChildrenCount:0,
                                tripleAdultCount:0,
                                quadrupleCount:0,
                                singleOneBed:sr,
                                singleOneBedBreakfast:sb,
                                singleOneBedHalfboard:sh,
                                singleOneBedFullboard:sf,
                                doubleOneBed:dr,
                                doubleOneBedBreakfast:db,
                                doubleOneBedHalfboard:dh,
                                doubleOneBedFullboard:df,
                                tripleChildren:tr,
                                tripleChildrenBreakfast:tb,
                                tripleChildrenHalfboard:th,
                                tripleChildrenFullboard:tf,
                                tripleAdult:tar,
                                tripleAdultBreakfast:tab,
                                tripleAdultHalfboard:tah,
                                tripleAdultFullboard:taf,
                                quadruple:qr,
                                quadrupleBreakfast:qb,
                                quadrupleHalfboard:qh,
                                quadrupleFullboard:qf,
                                priceBreakfast:priceBreakfast,
                                priceMeal:priceMeal,
                                restaurantMeal: restaurantMeal,
                                restaurantBreakfast: restaurantBreakfast,
                            }
                     console.log("setAdminAvailabilityDB  Data to change: ", Data);
                     var params = {
                                TableName: 'availability',
                                Item: Data
                    }
                            
                    docClient.put(params, function (err, data) {
                                    if (err) {
                                        console.log('Error', err)
                                    } 
                    })
                     nday=nday+1;
                  };
                    
            });
            setDone();
        }
        else{
            console.log('Error',err)
        }
    })
 };

  export  const decrementAvailabilityRoomDB = (setDone, start_day, start_month, end_day, end_month, room, meal) => {
    var params = {
        TableName: 'availability'
    };
    let ndays=0;
    let values=[];
    let index=[];
    let lastmonth,lastday;

    docClient.scan(params, function (err, data) {
       
        if (!err) {
            //console.log(data);
            const start= getIndex(start_month,start_day);
            [lastmonth,lastday]=getLastDay(end_month,end_day);
            const end  = getIndex(lastmonth,lastday);
            let send_email=false;
           // console.log("Child searching availablity dates", "checkin", start_month,start_day, "last: ", lastmonth,  lastday );

            data.Items.forEach(function(element, index, array) {
                 const day=getIndex(element.month,element.day);
                 
               //  console.log(day, " Child valid day", element.month,element.day, getValidDay(element.month,element.day));
                 
                 if (getValidDay(element.month,element.day) && (day>=start) && (day<=end)){
                    ndays=ndays+1;
                    let s1,s2,d1,d2,tc,ta,q;
                    s1=element.singleOneBedCount;
                    s2=element.singleTwoBedCount;
                    d1=element.doubleOneBedCount;
                    d2=element.doubleTwoBedCount;
                    tc= element.tripleChildrenCount;
                    ta=element.tripleAdultCount;
                    q= element.quadrupleCount;


                    let restaurantMeal=element.restaurantMeal;
                    if (meal==process.env.REACT_APP_REGIME_HALFBOARD){
                        if (restaurantMeal>0){
                            restaurantMeal=restaurantMeal-1;
                        }else{
                            restaurantMeal=0;
                        }
                    }

                    console.log('decrementAvailabilityRoomDB:', meal, process.env.REACT_APP_REGIME_HALFBOARD, restaurantMeal);


                    // NOTE HalfBoard also decrement Breakfast availability:
                     let restaurantBreakfast=element.restaurantBreakfast;
                    if (meal==process.env.REACT_APP_REGIME_BREAKFAST || meal==process.env.REACT_APP_REGIME_HALFBOARD){
                        if (restaurantBreakfast>0){
                            restaurantBreakfast=restaurantBreakfast-1;
                        }else{
                            restaurantBreakfast=0;
                        }
                    }
                   

                    if ((room=='singleOneBed') && (element.singleOneBedCount>0)){
                        s1=Number(element.singleOneBedCount)-1;
                        if (s1==0){
                                send_email=true;
                        }
                    }
                    if ((room=='singleTwoBed') && (element.singleTwoBedCount>0)){
                        s2=Number(element.singleTwoBedCount)-1;
                        if (s2==0){
                                send_email=true;
                        }
                    }
                    if ((room=='doubleOneBed') && (element.doubleOneBedCount>0)){
                        d1=Number(element.doubleOneBedCount)-1;
                        console.log("Modifying availability double:", element.day, element.month, d1);
                        if (d1==0){
                                send_email=true;
                        }
                    }
                    if ((room=='doubleTwoBed') && (element.doubleTwoBedCount>0)){
                        d2=Number(element.doubleTwoBedCount)-1;
                        if (d2==0){
                                send_email=true;
                        }
                    }
                    if ((room=='tripleChildren') && (element.tripleChildrenCount>0)){
                        tc=Number(element.tripleChildrenCount)-1;
                        if (tc==0){
                                send_email=true;
                        }
                    }
                    if ((room=='tripleAdult') && (element.tripleAdultCount>0)){
                        ta=Number(element.tripleAdultCount)-1;
                        if (ta==0){
                                send_email=true;
                        }
                    }
                    if ((room=='quadruple') && (element.quadrupleCount>0)){
                        q=Number(element.quadrupleCount)-1;
                        if (q==0){
                                send_email=true;
                        }
                    }

                    const Data = {
                        availabilityDate:element.availabilityDate,
                        month: element.month,
                        day: element.day,
                        singleOneBedCount:s1,
                        singleTwoBedCount:s2,
                        doubleOneBedCount:d1,
                        doubleTwoBedCount:d2,
                        tripleChildrenCount:tc,
                        tripleAdultCount:ta,
                        quadrupleCount:q,
                        singleOneBed:element.singleOneBed,
                        singleOneBedBreakfast:element.singleOneBedBreakfast,
                        singleOneBedHalfboard:element.singleOneBedHalfboard,
                        singleOneBedFullboard:element.singleOneBedFullboard,
                        doubleOneBed:element.doubleOneBed,
                        doubleOneBedBreakfast:element.doubleOneBedBreakfast,
                        doubleOneBedHalfboard:element.doubleOneBedHalfboard,
                        doubleOneBedFullboard:element.doubleOneBedFullboard,
                        tripleChildren:element.tripleChildren,
                        tripleChildrenBreakfast:element.tripleChildrenBreakfast,
                        tripleChildrenHalfboard:element.tripleChildrenHalfboard,
                        tripleChildrenFullboard:element.tripleChildrenFullboard,
                        tripleAdult:element.tripleAdult,
                        tripleAdultBreakfast:element.tripleAdultBreakfast,
                        tripleAdultHalfboard:element.tripleAdultHalfboard,
                        tripleAdultFullboard:element.tripleAdultFullboard,
                        quadruple:element.quadruple,
                        quadrupleBreakfast:element.quadrupleBreakfast,
                        quadrupleHalfboard:element.quadrupleHalfboard,
                        quadrupleFullboard:element.quadrupleFullboard,
                        priceBreakfast: element.priceBreakfast,
                        priceMeal: element.priceMeal,
                        restaurantMeal: restaurantMeal,
                        restaurantBreakfast: restaurantBreakfast
                     };
                     var params = {
                                TableName: 'availability',
                                Item: Data
                    }

                    console.log("decrementAvailabilityRoomDB: ", Data);
                            
                    docClient.put(params, function (err, data) {
                                    if (err) {
                                        console.log('Error', err)
                                    } 
                    })
                 };
            });
            setDone(send_email);
        }
        else{
            console.log('Error',err)
        }
    })
 };

 export  const incrementAvailabilityRoomDB = (setDone,start_day, start_month, end_day, end_month, room, meal) => {
    var params = {
        TableName: 'availability'
    };
    let ndays=0;
    let values=[];
    let index=[];
    let lastmonth,lastday;

    docClient.scan(params, function (err, data) {
       
        if (!err) {
            //console.log(data);
            const start= getIndex(start_month,start_day);
            [lastmonth,lastday]=getLastDay(end_month,end_day);
            const end  = getIndex(lastmonth,lastday);
           // console.log("Child searching availablity dates", "checkin", start_month,start_day, "last: ", lastmonth,  lastday );

            data.Items.forEach(function(element, index, array) {
                 const day=getIndex(element.month,element.day);
               //  console.log(day, " Child valid day", element.month,element.day, getValidDay(element.month,element.day));
                 
                 if (getValidDay(element.month,element.day) && (day>=start) && (day<=end)){
                    ndays=ndays+1;
                    let s1,s2,d1,d2,tc,ta,q;
                    s1=element.singleOneBedCount;
                    s2=element.singleTwoBedCount;
                    d1=element.doubleOneBedCount;
                    d2=element.doubleTwoBedCount;
                    tc= element.tripleChildrenCount;
                    ta=element.tripleAdultCount;
                    q= element.quadrupleCount;


                    let restaurantMeal=element.restaurantMeal;
                    if (meal==process.env.REGIME_HALFBOARD){
                        restaurantMeal=restaurantMeal+1;
                    }

                    // NOTE HalfBoard also increament Breakfast availability:
                    let restaurantBreakfast=element.restaurantBreakfast;
                    if (meal==process.env.REGIME_BREAKFAST || meal==process.env.REGIME_HALFBOARD ){
                        restaurantBreakfast=restaurantBreakfast+1;
                    }

                    if ((room=='singleOneBed') && (element.singleOneBedCount>=0)){
                        s1=Number(element.singleOneBedCount)+1;
                    }
                    if ((room=='singleTwoBed') && (element.singleTwoBedCount>=0)){
                        s2=Number(element.singleTwoBedCount)+1;
                    }
                    if ((room=='doubleOneBed') && (element.doubleOneBedCount>=0)){
                        d1=Number(element.doubleOneBedCount)+1;
                        console.log("Modifying availability double:", element.day, element.month, d1);
                    }
                    if ((room=='doubleTwoBed') && (element.doubleTwoBedCount>=0)){
                        d2=Number(element.doubleTwoBedCount)+1;
                    }
                    if ((room=='tripleChildren') && (element.tripleChildrenCount>=0)){
                        t=Number(element.tripleChildrenCount)+1;
                    }
                    if ((room=='tripleAdult') && (element.tripleAdultCount>=0)){
                        tc=Number(element.tripleAdultCount)+1;
                    }
                    if ((room=='quadruple') && (element.quadrupleCount>=0)){
                        q=Number(element.quadrupleCount)+1;
                    }

                    const Data = {
                        availabilityDate:element.availabilityDate,
                        month: element.month,
                        day: element.day,
                        singleOneBedCount:s1,
                        singleTwoBedCount:s2,
                        doubleOneBedCount:d1,
                        doubleTwoBedCount:d2,
                        tripleChildrenCount:tc,
                        tripleAdultCount:ta,
                        quadrupleCount:q,
                        singleOneBed:element.singleOneBed,
                        singleOneBedBreakfast:element.singleOneBedBreakfast,
                        singleOneBedHalfboard:element.singleOneBedHalfboard,
                        singleOneBedFullboard:element.singleOneBedFullboard,
                        doubleOneBed:element.doubleOneBed,
                        doubleOneBedBreakfast:element.doubleOneBedBreakfast,
                        doubleOneBedHalfboard:element.doubleOneBedHalfboard,
                        doubleOneBedFullboard:element.doubleOneBedFullboard,
                        tripleChildren:element.tripleChildren,
                        tripleChildrenBreakfast:element.tripleChildrenBreakfast,
                        tripleChildrenHalfboard:element.tripleChildrenHalfboard,
                        tripleChildrenFullboard:element.tripleChildrenFullboard,
                        tripleAdult:element.tripleAdult,
                        tripleAdultBreakfast:element.tripleAdultBreakfast,
                        tripleAdultHalfboard:element.tripleAdultHalfboard,
                        tripleAdultFullboard:element.tripleAdultFullboard,
                        quadruple:element.quadruple,
                        quadrupleBreakfast:element.quadrupleBreakfast,
                        quadrupleHalfboard:element.quadrupleHalfboard,
                        quadrupleFullboard:element.quadrupleFullboard,
                        priceBreakfast: element.priceBreakfast,
                        priceMeal: element.priceMeal,
                        restaurantMeal: restaurantMeal,
                        restaurantBreakfast: restaurantBreakfast
                     };
                    var params = {
                                TableName: 'availability',
                                Item: Data
                    }
                            
                    docClient.put(params, function (err, data) {
                                    if (err) {
                                        console.log('Error', err)
                                    } 
                    })

                 };
            });
            setDone();
        }
        else{
            console.log('Error',err)
        }
    })
 };


 export  const getAdminPrice= async  (setDone, start_date, end_date, checkRoom, checkBreakfast, checkMeal) => {
    let text=start_date.split("-");
    let start_day=text[0];
    let start_month=text[1];
    text=end_date.split("-");
    let end_day=text[0];;
    let end_month=text[1];
   // console.log("Child day:",end_date, end_month, end_day);
    await getAdminPriceDB(setDone, start_day, start_month, end_day, end_month, checkRoom, checkBreakfast, checkMeal);
 }

 export  const getAdminAvailability= async  (setDone, start_date, end_date, singleOneBed, singleTwoBed, doubleOneBed, doubleTwoBed, tripleChildren, tripleAdult, quadruple, breakfast, halfboard, fullboard) => {
    let text=start_date.split("-");
    let start_day=text[0];
    let start_month=text[1];
    text=end_date.split("-");
    let end_day=text[0];;
    let end_month=text[1];
    //console.log("getAdminAvailability: ",start_date, end_date);
    await getAdminAvailabilityDB(setDone, start_day, start_month, end_day, end_month, singleOneBed, singleTwoBed, doubleOneBed, doubleTwoBed, tripleChildren, tripleAdult, quadruple, breakfast, halfboard, fullboard);
 }

 export  const stopSellAdmin= async  (setDone, start_date, end_date) => {
    let text=start_date.split("-");
    let start_day=text[0];
    let start_month=text[1];
    text=end_date.split("-");
    let end_day=text[0];;
    let end_month=text[1];
   // console.log("Child day:",end_date, end_month, end_day);
    await stopSellAdminDB(setDone, start_day, start_month, end_day, end_month);
 }

 export  const setAdminAvailability= async  (setDone, start_date, end_date, singleOneBed, singleTwoBed, doubleOneBed, doubleTwoBed, tripleChildren, tripleAdult, quadruple, breakfast, halfboard, fullboard, list, indexes) => {
    let text=start_date.split("-");
    let start_day=text[0];
    let start_month=text[1];
    text=end_date.split("-");
    let end_day=text[0];;
    let end_month=text[1];
   // console.log("Child day:",end_date, end_month, end_day);
    await setAdminAvailabilityDB(setDone, start_day, start_month, end_day, end_month, singleOneBed, singleTwoBed, doubleOneBed, doubleTwoBed, tripleChildren, tripleAdult, quadruple, breakfast, halfboard, fullboard, list, indexes);
 }

export  const setAdminPrice= async  (setDone, start_date, end_date, checkRoom, checkBreakfast, checkMeal, prices, indexes) => {
    let text=start_date.split("-");
    let start_day=text[0];
    let start_month=text[1];
    text=end_date.split("-");
    let end_day=text[0];;
    let end_month=text[1];
   // console.log("Child day:",end_date, end_month, end_day);
   // console.log(" Child prices: ", prices)
    await setAdminPriceDB(setDone, start_day, start_month, end_day, end_month, checkRoom, checkBreakfast, checkMeal, prices, indexes);
 }

export  const getAvailability = async  (setDone, checkin, checkout) => {
   // 2022-5-12
    let text=checkin.split("-");
    let start_day=text[0];
    let start_month=text[1];
    text=checkout.split("-");
    let end_day=text[0];;
    let end_month=text[1];
    //console.log("Child day:",checkin, checkout, start_day, start_month, end_day, end_month);
    await getAvail(setDone, start_day, start_month, end_day, end_month);
}

export  const decrementAvailabilityRoom = async  (setDone, checkin, checkout, room, meal) => {
   // 2022-5-12
    let text=checkin.split("-");
    let start_day=text[0];
    let start_month=text[1];
    text=checkout.split("-");
    let end_day=text[0];;
    let end_month=text[1];
   // console.log("Child day:",end_date, end_month, end_day);
    await decrementAvailabilityRoomDB(setDone,start_day, start_month, end_day, end_month, room, meal);
}


export  const incrementAvailabilityRoom = async  (setDone, checkin, checkout, room, meal) => {
   // 2022-5-12
    let text=checkin.split("-");
    let start_day=text[0];
    let start_month=text[1];
    text=checkout.split("-");
    let end_day=text[0];;
    let end_month=text[1];
   // console.log("Child day:",end_date, end_month, end_day);
    await incrementAvailabilityRoomDB(setDone,start_day, start_month, end_day, end_month, room, meal);
}


// Not used this function
export  const getRestaurant = async  (setDone, checkin, checkout, checkBreakfast, checkMeal) => {
   // 2022-5-12
    let text=checkin.split("-");
    let start_day=text[0];
    let start_month=text[1];
    text=checkout.split("-");
    let end_day=text[0];;
    let end_month=text[1];
    //console.log("Child day:",checkin, checkout, start_day, start_month, end_day, end_month);
    await getRestaurantDB(setDone, start_day, start_month, end_day, end_month, checkBreakfast, checkMeal );
}

// **********************************************

  